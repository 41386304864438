

import React, {useEffect, useRef, useState } from "react";
import "../styles/componentStyles/Dropdown.css";
import { ChevronDown, ChevronUp } from "react-feather";

const Dropdown = ({
  options,
  onSelect,
  drpdownWidth,
  drpdownHeight,
  placeHolder,
  leftIcon,
  value,
  editable,
  validationError,
  initialValue,
  opentoggle,
  lablekey,
setOpenDropdownCheck=()=>{},
  Id="",
  searchBar= false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(initialValue);
  const dropdownRef = useRef(null);
  const menuRef = useRef(null);

  const toggleDropdown = (e) => {
  
      setIsOpen(!isOpen);
      setOpenDropdownCheck(isOpen);
    
   
    // console.log("toggleDropdown",e);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
    // console.log("handleOptionSelect =>")
  };

  useEffect(()=>{

    setFilteredOptions(options)

  },[])

  useEffect(() => {
    setSelectedOption(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        // console.log("handleClickOutside inside if event",event.target)
        // console.log("handleClickOutside inside if target",dropdownRef.current)
      }else{
        // console.log("handleClickOutside outside if event",event.target)
        // console.log("handleClickOutside outside if target",dropdownRef.current)
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // useEffect(() => {
  //   if (menuRef.current) {
  //     const menuPosition = menuRef.current.getBoundingClientRect().bottom;
  //     const windowHeight = window.innerHeight;
  //     const isNearBottom = windowHeight - menuPosition < 50; // Adjust threshold as needed

  //     if (isNearBottom) {
  //       menuRef.current.style.top = 'auto';
  //       menuRef.current.style.bottom = '100%';
  //     } else {
  //       menuRef.current.style.top = '3.3rem';
  //       menuRef.current.style.bottom = 'auto';
  //     }
  //   }
  // }, [isOpen]);

  const [searchValue,setSearchValue] = useState("");
  const [filteredOptions,setFilteredOptions] = useState(null);

  function handleSearchDesignation (event){
      event.preventDefault();
    let searchItem =event.target.value.toLowerCase();
    
    // if (searchItem === ' ') {
    //   event.preventDefault(); // Prevent spacebar from scrolling the page
    // }

    setSearchValue(searchItem);
    // console.log("options",options)
   let filteredValues = options.filter((item)=> item.toLowerCase().includes(searchItem) );
   setFilteredOptions(filteredValues);

  }

  // Handle spacebar only when it's pressed outside of input
  const handleKeyDown = (event) => {
    // console.log("event.target",event.target.tagName);
    if (event.key === ' ' ) {
      event.preventDefault(); // Prevent spacebar from scrolling the page if not in an input
      let space = searchValue + " ";
      setSearchValue(space);

      let filteredValues = options.filter((item)=> item.toLowerCase().includes(space) );
      setFilteredOptions(filteredValues);

    }
  };

  console.log("validationError",validationError);

  return (
    <div style={{position:"relative"}}>
      <button
        className={`${editable ? validationError != "" && validationError != undefined ?"warninginputfield cmpt-dropdown": "cmpt-dropdown" : "cmpt-disable-state" }`}
        type="button"
        onClick={(e) => {
          editable && toggleDropdown(e);
        }}
        ref={dropdownRef}
        id={Id}
      >
        {leftIcon && leftIcon}
        <div
          style={{
            width: "87%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span
            style={{
              color: value
                ? editable
                  ? "black"
                  : "#4a4343"
                : selectedOption
                ? "black"
                : "#c4c4c4",
            }}
          >
            {value ? value : selectedOption ? selectedOption : placeHolder}
          </span>
          <span>
            {editable ? (
              isOpen ? (
                <ChevronUp style={{ animation: "none" }} />
              ) : (
                <ChevronDown style={{ animation: "none" }} />
              )
            ) : (
              ""
            )}
          </span>
        </div>

        {isOpen && (
          <ul className="cmpt-dropdown-menu" ref={menuRef}>
            { searchBar && 
              <div onClick={(e)=> e.stopPropagation()}>
              <input 
              placeholder="Search Designation" 
            
              type="text" 
              value={searchValue}
              onChange={(e)=> handleSearchDesignation(e)}
              onFocus={(e)=> { e.stopPropagation() }}
              onKeyDown={(e)=> handleKeyDown(e)}
              style={{
                  width:"100%",
                  padding:"10px",
                  borderRadius:"5px",
                  border:"1px solid lightgrey"
              }} ></input>
              
              </div>
            }
            {filteredOptions != null && filteredOptions?.length > 0 ?
              filteredOptions.map((option, index) => (
                <li
                  key={index}
                  id={"id_" + option}
                  onClick={() => handleOptionSelect(option)}
                >
                  {option}
                </li>
              ))
              :
              <li style={{marginTop:"5px"}}>No matches found</li>
            }

          </ul>
        )}
      </button>
      {validationError && <p className="validation-error">{validationError}</p>}
    </div>
  );
};

export default Dropdown;