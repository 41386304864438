import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
  Navigate,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./App.css";
import "./styles/custom.css";
// import YourComponent from './utlis/main'; './utlis/main.js'
import Home from "./screens/Home";
import Loans from "./screens/Loans";
import Footer from "./components/Footer";
import Investors from "./screens/Investors";
import Articles from "./screens/Articles";
import Careers from "./screens/Careers";
import ContactUs from "./screens/ContactUs";
import Faq from "./screens/Faq";
import BlogOne from "./screens/articles-blog/BlogOne";
import BlogTwo from "./screens/articles-blog/BlogTwo";
import BlogThree from "./screens/articles-blog/BlogThree";
import BlogFour from "./screens/articles-blog/BlogFour";
import BlogFive from "./screens/articles-blog/BlogFive";
import BlogSix from "./screens/articles-blog/BlogSix";
import UserInfo from "./screens/dynamic/UserInfo";
import LoanDetailsScreen from "./screens/dynamic/LoanDetailsScreen";
import LoanHistoryScreen from "./screens/dynamic/LoanHistoryScreen";
import WorkInfo from "./screens/dynamic/WorkInfo";
import Aadhardetails from "./screens/dynamic/Aadhardetails";
import CheckEligibility from "./screens/dynamic/CheckEligibility";
import MyKaasuHome from "./screens/dynamic/MyKaasuHome";
import Login from "./screens/dynamic/Login";
import Loanapply from "./screens/dynamic/Loanapply";
import { GlobalValues, } from "./components/GlobalValues";
import MyAccount from "./screens/dynamic/MyAccount";
import Profile from "./screens/dynamic/Profile";
import BankInformation from "./screens/dynamic/BankInformation";
import Pandetails from "./screens/dynamic/Pandetails";
import LoanCalculatorapp from "./screens/dynamic/LoanCalculator";
import DeactivateAccount from "./screens/dynamic/DeactivateAccount";
// import { isMobile } from "react-device-detect";
import Tickets from "./screens/dynamic/Tickets";
import Breadcrumbs from "./components/Breadcrumps";
import Settings from "./screens/dynamic/Settings";
import Error404 from "./components/Error404";
import TermsAndConditions from "./screens/TermsAndConditions";
import Privacy from "./screens/Privacy";
import LoansBusiness from "./screens/LoansBusiness";
import CustomHeader from "./components/customHeader";

import SuccessMessage from "./screens/dynamic/SuccessMessage";
import FailedMessage from "./screens/dynamic/FailedMessage";


const DynamicLayout = ({dynamicflag}) => (
  <>
    <CustomHeader />
    {/* <main className="main-outlet"> */}
    {dynamicflag && <Breadcrumbs />}
    <Outlet />
    {/* </main> */}
    <Footer />
  </>
);
const router = (handlelogout, initialScreen) => {
  return <Routes>
    <Route path='*' element={< Error404 />} />

    <Route path="/login" element={<Login />} />
    <Route path="/header" element={<CustomHeader />} />


    <Route element={<DynamicLayout dynamicflag={true} />}>
      <Route path="/" element={<Navigate to={initialScreen} />} />
      <Route path="/checkeligibility" element={<CheckEligibility />} />
      <Route path="/loanDetails" element={<LoanDetailsScreen />} />
      <Route path="/history" element={<LoanHistoryScreen />} />
      <Route path="/dashboard" element={<MyKaasuHome />} />
      <Route
        path="/profile"
        element={<Profile handlelogout={handlelogout} />}
      />
      <Route path="/bank" element={<BankInformation />} />
      <Route path="/aadhaar" element={<Aadhardetails />} />
      <Route path="/pan" element={<Pandetails />} />

      <Route path="/user" element={<UserInfo />} />
      <Route path="/work" element={<WorkInfo />} />
      <Route path="/account" element={<MyAccount />} />
      <Route path="/grievance" element={<DeactivateAccount />} />1
      <Route path="/tickets" element={<Tickets />} />
      <Route path="/settings" element={<Settings />} />


    </Route>

    <Route element={<DynamicLayout dynamicflag={false} />}>
      <Route path="/home" element={<Home />} />
      <Route path="/calculator" element={<LoanCalculatorapp />} />
      {/* <Route path="/404" element={<Error404 />} /> */}
      <Route path="/personal-loans" element={<Loans />} />
      <Route path="/business-loans" element={<LoansBusiness />} />
      <Route path="/terms" element={<TermsAndConditions />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/apply" element={<Loanapply />} />
      <Route path="/investors" element={<Investors />} />
      <Route path="/articles" element={<Articles />} />
      <Route path="/careers" element={<Careers />} />
      <Route path="/contactus" element={<ContactUs />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/article1" element={<BlogOne />} />
      <Route path="/article2" element={<BlogTwo />} />
      <Route path="/article3" element={<BlogThree />} />
      <Route path="/article4" element={<BlogFour />} />
      <Route path="/article5" element={<BlogFive />} />
      <Route path="/article6" element={<BlogSix />} />
      {localStorage.getItem("message") === "true" &&
        <>
          <Route path="/success" element={<SuccessMessage />} />
          <Route path="/failure" element={<FailedMessage />} />
        </>
      }
    </Route>
    {/* Add more routes here if needed */}
  </Routes>
}
function App() {
  const [initialScreen, setInitialScreen] = useState("");

  // const { loginauth } = useContext(MyContext);








  useEffect(() => {
    loginCheck();
  }, []);

  const loginCheck = () => {
    const eligible = localStorage.getItem("eligibleApproved");
    const accId = localStorage.getItem("accId");
    const apiKey = localStorage.getItem("apiKey");

    if (accId !== null && apiKey !== null && eligible !== null) {

      if (eligible === "yes") {
        setInitialScreen("/dashboard");
      } else {
        setInitialScreen("/checkeligibility");
      }


    } else {
      setInitialScreen("/home");

    }
  };

  const handlelogout = () => {
    loginCheck();
  };











  return (
    <GlobalValues>
      <Router>
        {router(handlelogout, initialScreen)}
      </Router>
    </GlobalValues>
  );
}

export default App;