import React, { useEffect, useState } from "react";
import "../styles/componentStyles/Modal.css"; // Import the CSS file
import { XCircle } from "react-feather";

const Modal = ({
  isOpen,
  onClose,
  children,
  modalTitle,
  subTitleRequired = false,
  modalSubTitle="",
  submitButtonPlaceHolder="save",
  onSave=()=>{},
  modalref,
  modalwidth = "40%",
  modalHeight, 
  onhide = true,
  savebtn = false,
  closebtn=false,
  footerContent,
  scrolldetection,
  hasScrolled,
  handlescrolldetect,
  Id="",
  bacKdropId="backdropModal",
  savebtneditable=true,
  footerbtn=true
}) => {

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  useEffect(() => {
    if (scrolldetection) {
      const handleScroll = () => {
        if (modalref.current) {
          const scrollTop = modalref.current.scrollTop;
          if (scrollTop > 600 && !hasScrolled) {
            handlescrolldetect();
          }
        }
      };

      const modalElement = modalref.current;
      if (modalElement) {
        modalElement.addEventListener('scroll', handleScroll);
      }

      // Clean up the event listener on unmount
      return () => {
        if (modalElement) {
          modalElement.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [hasScrolled]); 

  return (
    <div
      className={`custom-modal-overlay ${isOpen ? "custom-open" : ""}`}
      onClick={ ()=>{onhide && onClose()}}
      id={bacKdropId}
    >
      <div
        style={{ width: modalwidth, height: modalHeight }}
        className="custom-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0px 20px",
          }}
        >
          <div>
            <h2>{modalTitle}</h2>
            {subTitleRequired && (
              <span style={{ fontSize: "16px", color: "#969696" }}>
                {modalSubTitle}
              </span>
            )}
          </div>

          {closebtn && (
            <button
              style={{ border: "none", backgroundColor: "transparent" }}
              onClick={onClose}
            >
              <XCircle style={{ color: "red" }} />
            </button>
          )}
        </div>
        <div className="custom-modal-main-content" id={Id} ref={modalref} style={{position:"relative"}}>
          {children}
        </div>

        {footerContent && (
          <div className="custom-modal-custom-footer">{footerContent}</div>
        )}
        {savebtn && (
          <div className="custom-modal-footer">
           { footerbtn && <button className="custom-modal-close-modal" onClick={onClose}>
              Close
            </button>}
            {submitButtonPlaceHolder && (
              <button
                className={savebtneditable?"custom-modal-save-modal":"custom-modal-save-disabled"}
                onClick={(e) => {
                  e.preventDefault();
                  onSave();
                }}
                disabled={!savebtneditable}
              >
                {submitButtonPlaceHolder}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
