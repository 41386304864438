import React from 'react'

function Paymentoptions({ icon, label, selected, onClick}) {
  return (
    <div
    style={{
      padding: "0.9rem 2rem",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      cursor:"pointer"
    }}
    onClick={onClick}
  >
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "2.2rem",
        filter:"brightness(0) saturate(100%) invert(48%) sepia(8%) saturate(17%) hue-rotate(5deg) brightness(93%) contrast(83%)"
      }}
    >
      <img
        src={icon}
        alt="netbanking img"
        width={label==="UPI"?"25px":"20px"}
        height={label==="UPI"?"17px":"20px"}
        style={{filter: "grayscale(100%)"}}
      />
      <span>{label}</span>
    </div>
    <div>
    <div
    style={{
      width: '17px',
      height: '17px',
      borderRadius: '50%',
      border: `2px solid ${selected ? '#e32f45' : '#ccc'}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    {selected && (
      <div
        style={{
          width: '9px',
          height: '9px',
          borderRadius: '50%',
          backgroundColor: '#e32f45',
         
        }}
      />
    )}
  </div>
    </div>
  </div>
  )
}

export default Paymentoptions