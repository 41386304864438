import React, { useEffect, useState, useRef, useContext } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import '../../styles/screenStyles/Login.css';
import { ArrowLeft, CheckLg, } from 'react-bootstrap-icons'
import Alert from '../../components/Alert';
import { MyContext } from '../../components/GlobalValues';
import { Edit3, MapPin } from 'react-feather';
import Metrixfunction from '../../components/MetrixFunction';



export default function Login() {
    const navigate = useNavigate()
    const { Url, windowWidth, metrixserver } = useContext(MyContext);
    const [termsCheck, setTermsCheck] = useState(false)
    const [mobileNo, setMobileNo] = useState('')
    const [mobileNoError, setMobileNoError] = useState('');
    const [timer, setTimer] = useState(120);
    const [resendOtp, setResendOtp] = useState(false);
    const [otpTriggered, setOtpTriggered] = useState(false)
    const [otpInput, setOtpInput] = useState('')
    const [otpError, setOtpError] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    // const [loading, setLoading] = useState(false);
    const intervalRef = useRef(null);
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const texts = [
        { 'Discover the power of instant funds!': 'Get up to ₹1 lacs in just minutes' },
        { 'Achieve your dreams effortlessly!': 'Flexible repayment options from 6 to 60 months' },
        { 'Simplify your journey with us!': 'Enjoy a fully digital and secure application process' }
    ];
    const images = ["https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/gif/moneyBag.gif", "https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/gif/gift.gif", "https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/gif/moneyCoin.gif"];
    const [title, setTitle] = useState(Object.keys(texts[currentTextIndex])[0]);
    const [description, setDescription] = useState(Object.values(texts[currentTextIndex])[0]);
    const [image, setImage] = useState(images[currentTextIndex]);
    const [mobileNoFocused, setMobileNoFocused] = useState(false)
    const [pincodeFocused, setPincodeFocused] = useState(false)
    const [otpFocused, setOtpFocused] = useState(false)
    const [pincode, setPincode] = useState('')
    const [isRegistered, setIsRegistered] = useState("")
    const [pincodeCheck, setPincodeCheck] = useState(false)
    // const [isPincodeValid, setIsPincodeValid] = useState(null)
    const [isPincodeValid, setIsPincodeValid] = useState(false)
    const [eligible, setEligible] = useState(true)
    // const [eligible, setEligible] = useState(null)
    const mobileInputRef = useRef(null);
    const pincodeInputRef = useRef(null);
    const [initialCheck, setInitialCheck] = useState(false)
    const [initialCheckPincode, setInitialCheckPincode] = useState(false)
    const [responseotp,setresponseotp]=useState("")

    const location = useLocation()

    function removeSlash(port1) {
        let p1 = port1.split("/").join("");

        let np = p1;
        return np;
    }

    const [alert, setAlert] = useState({
        show: false,
        message: '',
        type: '',
        duration: 3000, // Default duration
    });

    function generateEpochWithRandom() {
        // Get current epoch time in seconds
        let epochTime = Math.floor(Date.now() / 1000);

        // Generate random last three digits
        let randomDigits = Math.floor(Math.random() * 1000);

        // Combine epoch time and random digits
        let epochWithRandom = epochTime * 1000 + randomDigits;

        return epochWithRandom;
    }

    const [imagesLoaded, setImagesLoaded] = useState(0);
    const totalImages = useRef(0);

    useEffect(() => {
        // Count the total number of images

        if (localStorage.getItem("loginId") === null) {
            let LoginId = generateEpochWithRandom()
            localStorage.setItem("loginId", LoginId)
        }

        const imageElements = document.querySelectorAll('img');
        totalImages.current = imageElements.length;


        // Add load event listeners to all images
        if (imageElements.length > 0) {
            imageElements.forEach(img => {
                if (img.complete) {
                    handleImageLoad();
                } else {
                    img.addEventListener('load', handleImageLoad);
                    img.addEventListener('error', handleImageLoad); // Handle error case
                }
            });
        }
        else {
            Metrixfunction(metrixserver, "static", "PAGE_ONLOAD");

        }

        // Cleanup function
        return () => {
            imageElements.forEach(img => {
                img.removeEventListener('load', handleImageLoad);
                img.removeEventListener('error', handleImageLoad);
            });
        };
    }, []);

    useEffect(() => {
        if (imagesLoaded === totalImages.current && totalImages.current > 0) {
            Metrixfunction(metrixserver, "static", "PAGE_ONLOAD");

        }
    }, [imagesLoaded]);

    const handleImageLoad = () => {
        setImagesLoaded(prev => prev + 1);
    };

    useEffect(() => {
        const eligible = localStorage.getItem('eligibleApproved');
        const accId = localStorage.getItem('accId')
        const apiKey = localStorage.getItem('apiKey')
        const isRegistered = localStorage.getItem('xyz')

        if (accId !== null && apiKey !== null && eligible !== null) {
            if (eligible === "yes") {
                navigate("/dashboard");
            } else {
                navigate("/checkeligibility");
            }
        }
        // else {
        //     if (isRegistered === true) {
        //         setEligible(true)
        //     } else if (isRegistered == null) {
        //         setEligible(true)
        //     } else {
        //         setEligible(false)
        //     }
        // }
    }, []);


    useEffect(() => {
        setTitleAndDescription(currentTextIndex);
    }, [currentTextIndex]);

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
        }, 2000);

        return () => clearInterval(intervalRef.current);
    }, []);

    useEffect(() => {
        if (resendOtp) {
            const intervalId = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer === 0) {
                        clearInterval(intervalId);
                        setResendOtp(false);
                        return 0;
                    }
                    return prevTimer - 1;
                });
            }, 1000);

            return () => clearInterval(intervalId);
        }
    }, [resendOtp]);

    useEffect(() => {
        if (mobileNoFocused) {
            mobileInputRef.current.focus();
        } else if (pincodeFocused) {
            pincodeInputRef.current.focus();
        }
    }, [mobileNoFocused, pincodeFocused]);

    useEffect(() => {
        if (mobileNo.length === 10) {
            setInitialCheck(true);
            isPhoneRegistered();
        }
    }, [mobileNo]);

    const showAlert = (message, type, duration = 3000) => {
        setAlert({
            show: true,
            message,
            type,
            duration,
        });

        // Automatically close the alert after the duration
        setTimeout(() => {
            setAlert((prevAlert) => ({
                ...prevAlert,
                show: false,
            }));
        }, duration);
    };

    const setTitleAndDescription = (index) => {
        setTitle(Object.keys(texts[index])[0]);
        setDescription(Object.values(texts[index])[0]);
        setImage(images[index]);
    };

    const resendOTP = async () => {
        setOtpInput("")
        setResendOtp(true);
        if (timer == 0) {
            await handleSendOtp();
            setTimer(120);
        }
    };

    const handleMobileNo = (mobile) => {
        const numericInput = mobile.replace(/[^0-9]/g, '');
        setMobileNo(numericInput);
        setMobileNoError('')
        if (numericInput.length === 10) {
            setMobileNoError('');
        }
    };

    const handleOtp = (otp) => {
        const numericInput = otp.replace(/[^0-9]/g, '');
        setOtpInput(numericInput);
        if (otp.length == 6) {
            setOtpError('');
        }
    };

    const handlePincode = (pincode) => {
        const numericInput = pincode.replace(/[^0-9]/g, '');
        setPincode(numericInput);
        setMobileNoError('')
        if (numericInput.length === 6) {
            setPincodeFocused(false)
            setInitialCheckPincode(true)
            validatePincode(numericInput);
        }
    };

    const handleSendOtp = async () => {
        if (!termsCheck) {
            setMobileNoError('Please accept terms and conditions');
        } else if (!pincodeCheck && isRegistered == "no") {
            setMobileNoError('Please enter valid pincode');
        } else if (mobileNo.length < 10) {
            setMobileNoError('Mobile number must be 10 digits');
        }
        // else if (!isPincodeValid && isRegistered == "no") {
        else if (!isPincodeValid && isRegistered == "no" && pincodeCheck) {
            // localStorage.setItem("xyz", false);
            // window.location.reload();
            setEligible(false)
        }
        else if (isRegistered == "yes" || (pincodeCheck && isRegistered == 'no')) {
            setIsLoading(true)
            setMobileNoError('');
            try {

                await fetch(`${Url}/business/generateOTP?phone=${mobileNo}&t=${Date.now()}`, {
                    method: "GET",
                    headers: { "Content-Type": "application/json" },
                })
                    .then((res) => res.json())
                    .then((resJson) => {
                        if (resJson.OTP == 'ok') {
                            // navigate('/mykaasuHome')
                            setTimer(120);
                            showAlert('OTP sent successfully', 'success')
                            setOtpTriggered(true);
                            setResendOtp(true);
                            setresponseotp(resJson.token)
                        }
                        setIsLoading(false)
                    })
            } catch (error) {
                console.log("Error :", error)
                setMobileNoError('Sorry we are unable to process your request');
                setIsLoading(false)
            }
        }
    }

    const verifyOtp = async () => {
        if (otpInput.length < 6) {
            setOtpError('Invalid OTP length');
        } else {
            setOtpError('');
            try {
                setIsLoading(true)

                await fetch(`${Url}/business/loginAccountOTP`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        phone: mobileNo,
                        token: otpInput
                    }),
                })
                    .then((res) => res.json())
                    .then((resJson) => {
                        if (resJson.Error) {
                            setOtpError("Incorrect OTP, please try again");
                            setIsLoading(false)
                        } else {
                            // setAlert({ message: 'Login Successful', type: 'success' });
                            // setLoading(true)
                            localStorage.setItem('accId', resJson.accId);
                            localStorage.setItem('apiKey', resJson.apiKey);
                            localStorage.setItem('mobileNo', mobileNo);
                            const allYes = Object.values(resJson.screenEligible).every(value => value === "yes");


                            if (allYes) {
                                localStorage.setItem("eligibleApproved", "yes");
                                navigate('/dashboard');
                            } else {
                                navigate('/checkeligibility');
                                localStorage.setItem("eligibleApproved", "no")

                            }
                        }
                        setIsLoading(false)
                    })
                    .finally(() => {
                        Metrixfunction(metrixserver, "loginAccountOTP", "UPDATE");

                    })

            } catch (error) {
                console.log("Error :", error)
                setOtpError('Sorry we unable to verify OTP, please try later');
                setIsLoading(false)

            }
        }
    }

    const isPhoneRegistered = async () => {
        try {
            Metrixfunction(metrixserver, "isPhoneRegistered", "UPDATE");
            await fetch(`${Url}/business/isPhoneRegistered`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    phone: mobileNo,
                }),
            })
                .then((res) => res.json())
                .then((resJson) => {
                    if (resJson.userRegistered) {
                        setIsRegistered(resJson.userRegistered)
                        setMobileNoFocused(false)
                        setInitialCheck(false)
                    }
                    else{
                        setMobileNoError('Enter a valid phone number');
                        setMobileNo("")
                        setInitialCheck(false)
                    }
                })
        } catch (error) {
            console.log("Error :", error)
            setMobileNo("")
            setInitialCheck(false)
            showAlert('Something went wrong,please try again', 'danger')
        }
    }

    const validatePincode = async (pincode) => {

        if (pincode?.length === 6) {
            try {

                const response = await fetch(
                    `${Url}/business/isPincodeValid?pincode=${pincode}&&phone=${mobileNo}`
                );
                const resJson = await response.json();
                if (resJson.Error) {
                    setPincodeCheck(true);
                    setIsPincodeValid(false);
                    setMobileNoError("")
                } else {
                    setPincodeCheck(true);
                    setIsPincodeValid(true);
                    setMobileNoError("")
                }
                setInitialCheckPincode(false)
            } catch (error) {
                console.log("Error:", error);
                showAlert('Unable to validate Pincode', 'danger')
            }
        }
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const handleBackPress = () => {
        setIsRegistered("")
        setPincode("");
        setTermsCheck(false);
        setMobileNo('');
        setMobileNoError('');
        setOtpError("");
        setOtpTriggered(false);
        setTimer(0);
        setOtpInput('')
        setPincodeCheck(false);
        setResendOtp(false);
    }

    const handleMobileNoEdit = () => {
        setIsRegistered(false);
        setPincode('');
        setPincodeCheck(false);
        setMobileNo('');
        setMobileNoFocused(true)
    }

    const handlePincodeEdit = () => {
        setPincodeCheck(false);
        setPincode('');
        setPincodeFocused(true)
        setMobileNoFocused(false)
    }

    return (
      <div style={{position:"relative"}}>
      {<p className='otpabsolute'>{responseotp}</p>}


      <div className="nbfcSignIn-main">
      
        <div className="background-image-login"></div>
        <div className="signin-left">
          <div className="signin-logo" onClick={() => navigate("/home")}>
            <img
              src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/logo.png"
              alt=""
            />
            <p>MyKaasu</p>
          </div>
          <div className="signin-content">
            <p className="signin-title">{title}</p>
            <p className="signin-discription">{description}</p>
          </div>
          <div className="signin-content-change">
            {texts.map((text, index) => (
              <span
                key={index}
                className={
                  index === currentTextIndex
                    ? "signin-content-active"
                    : "signin-content-inactive"
                }
              ></span>
            ))}
          </div>
          {windowWidth < 500 && (
            <div className="signin-gif">
              <img src={image} />
            </div>
          )}
        </div>

        <div className="signin-right">
          <div className="signin-value-contIner">
            {!eligible ? (
              <div className="signin-value-main">
                <img
                  className="signin-value-noservice-image"
                  src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/noService.png"
                  alt=""
                />
                <p className="signin-value-noservice-heading">Oops!</p>
                <p className="signin-value-noservice-discription">
                  We regret to inform you that we do not provide service here .
                </p>
              </div>
            ) : otpTriggered ? (
              <form
                className="signin-value-main"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <p className="signin-value-heading ">
                  <ArrowLeft
                    style={{ animation: "none", cursor: "pointer" }}
                    onClick={handleBackPress}
                  />{" "}
                  Verify Mobile Number
                </p>
                <p>OTP sent on {"XXXXXX" + mobileNo.slice(6, 10)}</p>
                <div className="signin-otp-input">
                  <button
                    className="resend-otp-button"
                    onClick={resendOTP}
                    disabled={resendOtp}
                  >
                    Resend OTP{" "}
                    {resendOtp ? `after ${formatTime(timer)} seconds` : ""}
                  </button>
                  <div
                    className={`mb-3 signin-value-input-container ${
                      otpFocused ? "focused" : ""
                    }`}
                  >
                    <input
                      className="signin-value-input"
                      id="signin-value-input1"
                      onFocus={() => setOtpFocused(true)}
                      onBlur={() => setOtpFocused(false)}
                      pattern="[0-9]*"
                      maxLength={6}
                      type="tel"
                      value={otpInput}
                      onChange={(e) => handleOtp(e.target.value)}
                      placeholder="Enter OTP"
                      autoComplete={"off"}
                    />
                  </div>
                  {otpError && (
                    <span className="signin-error-text">{otpError}</span>
                  )}

                  <button
                    onClick={verifyOtp}
                    disabled={isLoading}
                    className={
                      otpInput.length == 6
                        ? "signin-value-button"
                        : "signin-disabled"
                    }
                    id={"signin-value-button2"}
                  >
                    {isLoading ? (
                      <div className="spinner-border" role="status" />
                    ) : (
                      "Verify OTP"
                    )}
                  </button>
                </div>
              </form>
            ) : (
              <form
                className="signin-value-main"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <p className="signin-value-heading">Verify Mobile Number</p>
                <p>An OTP will sent for verification</p>

                                <div className={`signin-value-input-container ${mobileNoFocused ? 'focused' : ''}`}>
                                    <img src='https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/flag.png' alt='indianflag'/>
                                    <input id='mobile' className='signin-value-input' ref={mobileInputRef} disabled={isRegistered == 'no' || initialCheck} maxLength={10} pattern="[0-9]*" onFocus={() => setMobileNoFocused(true)} onBlur={() => setMobileNoFocused(false)} title="Please enter numbers only" type='tel' value={mobileNo} placeholder='Enter Mobile Number' onChange={(e) => handleMobileNo(e.target.value)} />
                                    {isRegistered == 'no' && <Edit3 onClick={handleMobileNoEdit} style={{ animation: 'none', cursor: 'pointer', width: "20px", height: "20px" }} />}
                                </div>

                                {isRegistered == 'no' &&
                                    <div className={`signin-value-input-container mt-3 ${pincodeFocused ? 'focused' : ''}`}>
                                        <MapPin style={{ animation: 'none', width: "20px", height: "20px" }} />
                                        <input id='pincode' className='signin-value-input' ref={pincodeInputRef} disabled={pincodeCheck || initialCheckPincode} maxLength={6} pattern="[0-9]*" onFocus={() => setPincodeFocused(true)} onBlur={() => setPincodeFocused(false)} title="Enter Your Pincode" type='tel' value={pincode} placeholder='Enter Your Work Pincode' onChange={(e) => handlePincode(e.target.value)} />
                                        {pincodeCheck && <Edit3 onClick={handlePincodeEdit} style={{ animation: 'none', cursor: 'pointer', width: "20px", height: "20px" }} />}
                                    </div>
                                }

                <div className="signin-value-terms">
                  <span
                    className={`signin-value-check${termsCheck ? "ed" : ""}`}
                    id={"signin-value-check1"}
                    onClick={() => {
                      setTermsCheck(!termsCheck);
                      setMobileNoError("");
                    }}
                  >
                    {termsCheck && (
                      <CheckLg style={{ animation: "none" }} color="white" />
                    )}
                  </span>
                  <p>
                    By continuing, you agree to our{" "}
                    <a
                      onClick={() => window.open("/privacy", "_blank")}
                      className="signin-link-text"
                    >
                      privacy policies
                    </a>{" "}
                    and{" "}
                    <a
                      onClick={() => window.open("/terms", "_blank")}
                      className="signin-link-text"
                    >
                     Terms
                    </a>
                  </p>
                </div>

                {mobileNoError && (
                  <span className="signin-error-text">{mobileNoError}</span>
                )}
                <button
                  disabled={isLoading}
                  className={
                    mobileNo.length == 10 &&
                    termsCheck &&
                    (isRegistered == "yes" ||
                      (pincodeCheck && isRegistered == "no"))
                      ? "signin-value-button"
                      : "signin-disabled"
                  }
                  id={"signin-value-button1"}
                  onClick={handleSendOtp}
                >
                  {isLoading ? (
                    <div className="spinner-border " role="status" />
                  ) : (
                    "Get Started"
                  )}
                </button>
              </form>
            )}

            {eligible && (
              <div className="signin-value-footer">
                <img src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/cisaCertified.png" />
                <img src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/nbfcCertified.png" />
              </div>
            )}
          </div>
        </div>

        {alert.show && (
          <Alert
            message={alert.message}
            type={alert.type}
            show={alert.show}
            duration={alert.duration}
            onClose={() => setAlert({ ...alert, show: false })}
          />
        )}
      </div>
      </div>
    );
}