import React, { useEffect, useState } from "react";
import "../../styles/screenStyles/Aadhardetails.css";
import InputField from "../../components/InputField";
import { Check, CreditCard, Edit, Home, Phone, User, XCircle } from "react-feather";
import { Calendar2Check, Person } from "react-bootstrap-icons";
import FileUpload from "../../components/FileUpload";
import Alert from "../../components/Alert";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { MyContext } from "../../components/GlobalValues";
import Loading from "../../components/Loading";
import Metrixfunction from "../../components/MetrixFunction";
import AssetDisplay from "../../components/AssetDisplay";
import UpdateLoader from "../../components/updateLoader";

function Pandetails({ component, componenttype, componentdata }) {
  const [editable, seteditable] = useState(false);
  const [loadingscreen,setloading]=useState(true)

  const [verifybtn,setverifybtn]=useState(false)


  const accountId = localStorage.getItem("accId");

  function removeSlash(port1) {
    let p1 = port1.split("/").join("");

    let np = p1;
    return np;
  }


  const {
    Url,
    pandata,
    setpandata,
    userPanFile,
    setuserPanFile,
    panverify,
    setpanverify,
    getFileNameFromUrl,
    metrixserver

  } = useContext(MyContext);



  const [validationErrors, setValidationErrors] = useState({
    panno: "",
  });

  const [updateLoad,setupdateLoad]=useState(false)

  const [showalert, setshowalert] = useState({
    show: false,
    msg: "",
    color: "",
  });

  const navigate=useNavigate()
  const alertmessage = (msg, color) => {
    setshowalert({ ...showalert, show: true, msg: msg, type: color });

    setTimeout(() => {
      setshowalert({ ...showalert, show: false, msg: "", type: "" });
    }, 3000);
  };

  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;


  const handleinputchange = (text) => {
    let upperCaseString = text.toUpperCase();

     

    setpandata({ ...pandata, panno: upperCaseString });
    if ( panRegex.test(upperCaseString)) {
     
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        panno: "",
      }));
    } else{
      let errorMessage = "Enter the correct PAN number";

      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        panno: errorMessage,
      }));
    }
  };

  const updatepan = async (event) => {

    event.preventDefault()
    if(userPanFile.length>0)
      {
    let query = {
      accId: localStorage.getItem("accId"),
      apiKey: localStorage.getItem("apiKey"),
      panNum: pandata.panno,
      panImg: "https://mykaasu.com",
    };
     
    try {
      setverifybtn(true)
      setupdateLoad(true)
      await fetch(`${Url}/business/updatePancard`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(query),
      })
        .then((res) => res.json())
        .then((resJson) => {
           
          if (resJson.Error) {
            alertmessage(resJson.Error, "danger");
            setpanverify(false)
          } else {
            alertmessage("PAN Update successful", "success");
            setpanverify(true)
            seteditable(false)
            setpandata({...pandata,panName:resJson.Success.panName})
          }
          setverifybtn(false)
          setupdateLoad(false)
        })
        .finally(()=>{
           Metrixfunction(metrixserver, "updatePancard", "UPDATE");
           
        })
    } catch (error) {
      console.log("Error :", error);
      setverifybtn(false)
      setupdateLoad(false)
      // setMobileNoError('Invalid Mobile No');
    }
  }
  else{
    alertmessage("Please upload the PAN Image before verify","danger")
  }
  };

  // const getpandetails = async () => {
  //   let query = {
  //     accId: localStorage.getItem("accId"),
  //     apiKey: localStorage.getItem("apiKey"),
  //     type: "kyc",
  //   };

  //    

  //   try {
  //     await fetch(`${Url}/business/getDetails`, {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify(query),
  //     })
  //       .then((res) => res.json())
  //       .then((resJson) => {
  //          
  //         if(resJson.Success.panNum.length>0)
  //           {
  //            setpandata({...pandata,panno:resJson.Success.panNum})
  //           }
  //       });
  //   } catch (error) {
  //     //console.log("Error :", error);
  //   }
  // };

  const getaccountdetails = async () => {
    let query = {
      accId: localStorage.getItem("accId"),
      apiKey: localStorage.getItem("apiKey"),
      type: "kyc",
    };

     

    try {
      Metrixfunction(metrixserver, "getDetails", "ONLOAD");

      await fetch(`${Url}/business/getDetails`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(query),
      })
        .then((res) => res.json())
        .then((resJson) => {
           
          if (resJson.Success) {
            setpandata({ panno: resJson.Success.panNum,panName:resJson.Success.panName,panImg:resJson.Success.panImgUrl });
            if(resJson?.Success?.panName?.length>0)
            {
              setpanverify(true)
              seteditable(true)
            }
            else{
              setpanverify(false)
              seteditable(false)

            }
            // if(resJson.Success.loanStatus!=="CLOSED" && resJson.Success.loanStatus!=="REJECTED"  && resJson.Success.loanStatus!=="INITIATED" && pandetails.loanStatus!=="")
            //   {
            //     setloanprogress(false)
            //   }
            
            setloading(false)
          } 
          else{
            navigate("/dashboard")
          }
        });
    } catch (error) {
      console.log("Error :", error);
      // setMobileNoError('Invalid Mobile No');
    }
  };
  useEffect(() => {
     
     
    // if (!component) {
    //   getaccountdetails()

    // } else {
      if (( component && componenttype === "loanpreview")) {
        seteditable(true);
        setloading(false)
      }
      else{
        getaccountdetails()
      }
    //   else{
    //     seteditable(true)
    //   }
      // setloading(false)

    // }
  }, []);

  const handleEditUploadImage = (uploadType)=>{

    switch (uploadType) {
      case "PanCard":
        setpandata({ ...pandata, panImg: "" });
        setuserPanFile([]);
        break;
      
        default:
        break;
    }
    
  }




  return (
    loadingscreen ? <Loading /> :
     (
       <>
       {updateLoad && <UpdateLoader/>}
     <div className="aadhardetails-container mb-5">
      <form
        className="aadhar-form-wrapper mt-0"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="d-flex justify-content-between align-items-center ">
          <div className="d-flex gap-4">
            <h4> PAN Details </h4>{" "}
            {/* {!(component && componenttype === "loanpreview") &&
              panverify &&
              (
                <Edit
                  style={{ cursor: "pointer" }}
                  id="panEdit"
                  onClick={() => {
                    setpanverify(false);
                    setpandata({
                      ...pandata,
                      panImg: "",
                      panName: "",
                    });
                  }}
                />
              )} */}
          </div>
          {panverify && (
            <div className="d-flex align-items-center">
              <Check
                style={{ animation: "none" }}
                className="aadhar-verify"
                size={20}
              />{" "}
              <span className="aadhar-verify">Verified</span>
            </div>
          )}
        </div>
        {/* <hr /> */}

        <div className="inner-aadhar-wrapper row mb-5">
          <div className="col-md-6">
            <label>PAN Number</label>
            <div className="mt-2">
              <InputField
                Id="PAN-Number"
                placeholder="Ex: ABCDE1234F"
                maxLength={10}
                value={pandata.panno}
                onChangeText={handleinputchange}
                editable={!editable && !panverify}
                pattern={"^[a-zA-Z]{5}d{4}[a-zA-Z]$"}
                validationError={validationErrors.panno}
                leftIcon={<CreditCard style={{ animation: "none" }} />}
              />
            </div>
          </div>
       
           {pandata.panName.length>0 && <div className="col-md-6">
              <label>PAN Name</label>
              <div className="mt-2">
                <InputField
                  Id="PAN-Name"
                  placeholder="Ex: ABCDE1234F"
                  maxLength={10}
                  value={pandata.panName}
                  editable={false}
                  pattern={"^[a-zA-Z]{5}d{4}[a-zA-Z]$"}
                  leftIcon={<User style={{ animation: "none" }} />}
                />
              </div>
            </div>}
        
        </div>
        {/* {!(componenttype === "stepper" || componenttype === "preview") && (
          <div className=" row mt-2 ">
            {userPanFile.length > 0 || pandata.panImg != "" ? (
              
              <div className="col">
              <AssetDisplay
                workinfo={pandata.panImg}
                fileUploadSate={userPanFile}
                handleEditUploadImage={handleEditUploadImage}
                fileType={"PanCard"}
                editable={!panverify}
                labelName={"PAN Image"}
              />
              </div>
            ) : (
              <div className="col-md-6">
                <label>Upload your PAN Image </label>
                <FileUpload
                  fileType="application/pdf,image/jpeg"
                  limitCount={1}
                  fileName={`${accountId}-userpan_`}
                  fileId={"Panimage"}
                  files={userPanFile}
                  setFiles={setuserPanFile}
                  labelId={"panUpload"}
                  // files={(e)=> handleUploadFiles(e,"utilityBillType")}
                />
              </div>
            )}
          </div>
        )} */}

        {(!panverify && ((component && componenttype === "loanapply" ) || !component)) && (
          <div className="pan-button-wrapper" >
            {" "}
            <button id="pan-verify-btn" className="pan-updatebtn" onClick={updatepan} disabled={verifybtn}>
              {component && componenttype === "loanapply" ? "Verify" : "Update"}
            </button>
          </div>
        )}
      </form>

      {showalert.show && (
        <Alert
          show={true}
          message={showalert.msg}
          type={showalert.type}
          duration={3000}
          onClose={() => {
             
            setshowalert({ show: false, message: "", type: "" });
          }}
        />
      )}
    </div>
    </>
    )
  );
}

export default Pandetails;
