import React, { useState, useEffect, useContext, useRef } from "react";
import "../../styles/screenStyles/ChatBot.css";
import { Send, Shuffle, X } from "react-feather";
import { MyContext } from "../../components/GlobalValues";
import {
  Ban,
  Bank,
  CardHeading,
  CurrencyRupee,
  DoorOpen,
  Files,
  Person,
  PersonBoundingBox,
  PersonSlash,
  PersonVcard,
  PiggyBank,
  QuestionCircle,
  ThreeDots,
  Wallet2,
} from "react-bootstrap-icons";
import Alert from "../../components/Alert";
import Metrixfunction from "../../components/MetrixFunction";
import { useLocation } from "react-router-dom";


function ChatBot() {
  const location = useLocation();

  const ticketOptions = [
    {
      title: "Loan Amount Not Received",
      icon: <PiggyBank className="chat-options-icon" />,
    },
    {
      title: "KYC",
      icon: <PersonBoundingBox className="chat-options-icon" />,
    },
    {
      title: "Change Bank Account",
      icon: <Shuffle className="chat-options-icon" />,
    },
    {
      title: "NOC",
      icon: <Ban className="chat-options-icon" />,
    },
    {
      title: "Unable To Create Loan",
      icon: <QuestionCircle className="chat-options-icon" />,
    },
    {
      title: "Charges",
      icon: <CurrencyRupee className="chat-options-icon" />,
    },
    {
      title: "Preclosure",
      icon: <PersonSlash className="chat-options-icon" />,
    },
    {
      title: "Loan Agreement",
      icon: <Files className="chat-options-icon" />,
    },
    {
      title: "Login Issues",
      icon: <DoorOpen className="chat-options-icon" />,
    },
    {
      title: "Payment Related",
      icon: <Wallet2 className="chat-options-icon" />,
    },
    {
      title: "Other",
      icon: <ThreeDots className="chat-options-icon" />,
    },
  ];

  const [showFirstMessage, setShowFirstMessage] = useState(false);

  const [validMessages, setValidMessages] = useState(false);
  const [chatError, setChatError] = useState("");
  const [disableCreateTicket, setDisableCreateTicket] = useState(false);
  const inputRef = useRef(null);
  const lastTicketId = localStorage.getItem("ticketId");

  const [alert, setAlert] = useState({
    show: false,
    message: "",
    type: "",
    duration: 3000,
  });

  const {
    openChat, setOpenChat,
    chatMessage, setChatMessage,
    ticketId,
    ticketCreated, setTicketCreated,
    newQuestion, setNewQuestion,
    createNewTicket, setCreateNewTicket,
    ticketStatus,
    Url,
    metrixserver,
    setchatflag,
    dummyLoading,
    chaflag,
    refIsCleared, setRefIsCleared,
    supportResponse, setSupportResponse


  } = useContext(MyContext);

  const showAlert = (message, type, duration = 3000) => {
    setAlert({
      show: true,
      message,
      type,
      duration,
    });

    // Automatically close the alert after the duration
    setTimeout(() => {
      setAlert((prevAlert) => ({
        ...prevAlert,
        show: false,
      }));
    }, duration);
  };

  useEffect(() => {
    const validTicketId = (id) => id !== null && id !== undefined && id !== "";

    console.log("status of the tickets:", lastTicketId, openChat, ticketCreated, location.pathname);
    if ((validTicketId(lastTicketId) || validTicketId(ticketId)) && !ticketCreated && location.pathname != "/dashboard") {
      console.log("lastTicketId inside the useEffect of ChatBot: ", lastTicketId);
      getAllComments();
    }
  }, [openChat, ticketId]);

  const chatBodyRef = useRef(null);

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [openChat, ticketStatus, chatMessage, refIsCleared]);

  useEffect(() => {
    if (openChat && !createNewTicket) {
      setTimeout(() => {
        setShowFirstMessage(true);
      }, 1000);
    } else {
      setShowFirstMessage(true);
    }
  }, [ticketCreated, openChat, createNewTicket]);

  useEffect(() => {
    if (!dummyLoading && inputRef.current) {
      inputRef.current.focus();
    }
  }, [dummyLoading]);


  const askQuestion = async () => {
    const accId = localStorage.getItem("accId");
    const apiKey = localStorage.getItem("apiKey");
    const regexMessage = /^(?!null$|undefined$)\s*(\S\s*){2,}$/;
    setValidMessages(false);

    if (regexMessage.test(newQuestion) && accId && apiKey && lastTicketId) {

      // setDummyLoading(true);
      try {
        await fetch(`${Url}/business/addTicketComment`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            accId: accId,
            apiKey: apiKey,
            comment: newQuestion,
            commentBy: "user",
            ticketId: lastTicketId,
          }),
        })
          .then((res) => res.json())
          .then(async (resJson) => {
            if (resJson.Success) {
              // setMessageLoading(false);
              // setDummyLoading(false);
              const question = { comment: newQuestion, commentTime: new Date(), commentBy: 'user', commenterId: localStorage.getItem("accId") };

              await setChatMessage((prevMessages) => [...prevMessages, question]);
              setNewQuestion("");
              setRefIsCleared(true);
              setSupportResponse(true);
              // console.log("chat message length:::::", chatMessage.length);
              setchatflag(true)

            }
          })
          .finally(() => {
            Metrixfunction(metrixserver, "addTicketComment", "UPDATE");
          });
      } catch (error) {
        console.log("Error :", error);
      }
    } else if (!ticketCreated && (localStorage.getItem("ticketId") == null || localStorage.getItem("ticketId") == undefined || localStorage.getItem("ticketId") == "")) {

      setChatError("Please select a ticket option");
      setValidMessages(true);
    } else {
      setValidMessages(true);
      setChatError("Minimum 2 characters");
      // showAlert('Please select a ticket','danger')
    }
  };


  const createTicket = async (ticket) => {
    setDisableCreateTicket(true);
    setValidMessages(false);
    const accId = localStorage.getItem("accId");
    const apiKey = localStorage.getItem("apiKey");

    if (ticket && accId && apiKey) {
      try {
        await fetch(`${Url}/business/createTicket`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            accId: accId,
            apiKey: apiKey,
            createdBy: "user",
            comment: ticket,
            commentBy: "user",
            actionTo: "support",
            assignedTo: "EXTERNAL",
          }),
        })
          .then((res) => res.json())
          .then((resJson) => {
            if (resJson.Success) {
              setTicketCreated(true);
              // setSelectedTicketOption(ticket)
              setchatflag(true)
              const question = { comment: ticket, commentTime: new Date(), commentBy: 'user', commenterId: localStorage.getItem("accId") };

              setChatMessage((prevMessages) => [...prevMessages, question]);
              setNewQuestion("");
              localStorage.setItem("ticketId", resJson.Success);
              setCreateNewTicket(true);
              setShowFirstMessage(false);
              showAlert("Ticket created Successfully", "success");

            }
          })
          .finally(() => {
            Metrixfunction(metrixserver, "createTicket", "UPDATE");
          });
      } catch (error) {
        console.log("Error :", error);
      }
    } else {
      showAlert("Fist log-in with your account", "danger");
    }
  };

  const getAllComments = async () => {
    const accId = localStorage.getItem("accId");
    const apiKey = localStorage.getItem("apiKey");
    const lastTicketId = localStorage.getItem("ticketId");

    const validTicketId = (id) => id !== null && id !== undefined && id !== "";

    if (validTicketId(lastTicketId) && accId && apiKey) {
      try {
        await fetch(`${Url}/business/getTicketById`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            accId: accId,
            apiKey: apiKey,
            ticketId: ticketId || lastTicketId,
          }),
        })
          .then((res) => res.json())
          .then((resJson) => {
            if (resJson.Success) {
              setChatMessage(resJson.Success[0].comments);
              setCreateNewTicket(true);
            }
          })
          .finally(() => {
            Metrixfunction(metrixserver, "getTicketById", "ONLOAD");
          });
      } catch (error) {
        console.log("Error :", error);
      }
    } else {
    }
  };

  function formatTimeFromTimestamp(date) {
    var timeString = new Date(date).toLocaleTimeString(undefined, {
      timeZone: "Asia/Kolkata",
      hour: "2-digit",
      minute: "2-digit",
    });

    return timeString;
  }

  const createNewTicketHome = () => {
    localStorage.setItem("ticketId", "");
    setchatflag(false)
    setDisableCreateTicket(false);
    setCreateNewTicket(false);
    setNewQuestion("");
    setChatMessage([]);
    setTicketCreated(false);
    setShowFirstMessage(false);
    setValidMessages(false);
  };

  const handleBotClose = () => {
    setNewQuestion("");
    setOpenChat(!openChat);
    setValidMessages(false);
    setSupportResponse(false);
  };

  const handleBotOpen = () => {
    setOpenChat(true);
    setchatflag(true)
  }

  // function getCurrentTime() {
  //   // Create a new Date object
  //   var currentDate = new Date();

  //   // Get the current hour and minute
  //   var hours = currentDate.getHours();
  //   var minutes = currentDate.getMinutes();

  //   // Determine AM or PM
  //   var meridiem = hours >= 12 ? "PM" : "AM";

  //   // Convert to 12-hour format
  //   hours = hours % 12;
  //   hours = hours ? hours : 12; // Handle midnight

  //   // Add leading zeros to minutes if necessary
  //   minutes = minutes < 10 ? "0" + minutes : minutes;

  //   // Construct and return the current time in the desired format
  //   var currentTime = hours + ":" + minutes + " " + meridiem;
  //   return currentTime;
  // }

  const handleInputChange = (event) => {
    const value = event.target.value;
    setNewQuestion(value);
    setValidMessages(false);
  };

  return (
    <>
      {openChat ? (
        <div className="chatbot-container">
          <div className="chatbot-header">
            <X
              id="chatbot-close"
              onClick={handleBotClose}
              style={{ cursor: "pointer", alignSelf: "flex-end" }}
            />
            <div className="chatbot-title-wrapper">
              <p className="chatbot-title">MyKaasu Chatbot</p>
              {createNewTicket && ticketStatus !== "CLOSED" && (
                <button
                  id="createNewTicketBtn"
                  className="chatbot-title-btn"
                  onClick={createNewTicketHome}
                >
                  New Ticket
                </button>
              )}
              {ticketStatus === "CLOSED" && (
                <p className="chatbot-title-ticket-closed">CLOSED</p>
              )}
            </div>
          </div>

          <div className="chatbot-body" ref={chatBodyRef}>
            {!ticketCreated && chatMessage?.length === 0 && (
              <>
                {/* {showFirstMessage ?
                                    <div className="chat-message-left">
                                        Hi, I am MyKaasu AI Chatbot.
                                    </div> :
                                    <div className="chat-message-left">
                                        <div className="chat-message-loading">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                    </div>
                                } */}

                {
                  // !showFirstMessage ? null :
                  // showSecondMessage ?
                  showFirstMessage ? (
                    <div className="chat-message-left">
                      <p className="chat-message-left-title">
                        Select an option to create ticket and get help from
                        MyKaasu Support :
                      </p>
                      <div className="chat-message-left-wrapper">
                        {ticketOptions.map((mess, ind) => (
                          <button
                            key={ind}
                            id={mess.title}
                            className="chat-message-options"
                            disabled={disableCreateTicket}
                            onClick={() => {
                              createTicket(mess.title);
                            }}
                          >
                              {mess.icon}
                              {mess.title}
                          </button>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="chat-message-left">
                      <div className="chat-message-loading">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  )
                }
              </>
            )}


            {chatMessage?.length > 0 && (
              <>
                {chatMessage.map((mess, ind) => (
                  <div key={ind} className={mess.commentBy === "user" ? "chat-wrapper-right" : "chat-wrapper-left"}>
                    <div className="chat-message-status-wrapper">
                      <p className="chat-message-time">
                        {formatTimeFromTimestamp(mess.commentTime)}
                      </p>
                      <p className="chat-message-time">
                        {mess.commentBy === "user" ? "YOU" : "SUPPORT"}
                      </p>
                    </div>
                    <div className={mess.commentBy === "user" ? "chat-message-right" : "chat-message-left"}>
                      {mess.comment}
                    </div>
                  </div>
                ))}
              </>
            )}

            {refIsCleared && supportResponse && (
              <div className="chat-message-left">
                <div className="chat-message-loading">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            )}

            {!refIsCleared && !chaflag && lastTicketId && ticketStatus !== "CLOSED" && supportResponse &&
              <div className={"chat-wrapper-left"}>
                <div className="chat-message-status-wrapper">
                  <p className="chat-message-time">
                    {formatTimeFromTimestamp(new Date())}
                  </p>
                  <p className="chat-message-time">
                    SUPPORT
                  </p>
                </div>
                <div className={"chat-message-left"}>
                  We will get back to you shortly
                </div>
              </div>
            }


          </div>

          <form onSubmit={(e) => { e.preventDefault(); }} className="chatbot-footer">
            {refIsCleared && supportResponse &&
              <p className="chatbot-waiting-response">Waiting for support to respond...</p>
            }
            <div className="chatbot-input-wrapper">
              <input ref={inputRef} className="chatbot-input" autoComplete="off" disabled={ticketStatus === "CLOSED" || dummyLoading} onChange={handleInputChange}
                title="Minimum 2 characters" value={newQuestion} placeholder={ticketStatus === "CLOSED" ? "Ticket Closed" : "Type your message..."} id="chatbot-input" />
              {validMessages && (
                <span className="chatbot-input-error">{chatError}</span>
              )}
              <button disabled={ticketStatus === "CLOSED"} style={{ opacity: ticketStatus === "CLOSED" ? 0.3 : 1 }} className="send-message-icon" onClick={askQuestion} id="send-message">
                <Send />
              </button>
            </div>
          </form>

        </div>
      ) : (

        <button id="chatbot" className="chatbot-icon" onClick={handleBotOpen}>
          <img src={'https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/robo1.png'} height="70%" width="70%" alt="/" />
        </button>
      )}
      {alert.show && (
        <Alert
          message={alert.message}
          type={alert.type}
          show={alert.show}
          duration={alert.duration}
          onClose={() => setAlert({ ...alert, show: false })}
        />
      )}
    </>
  );
}

export default ChatBot;
