import React, { useContext, useEffect, useRef, useState } from "react";
import "../../styles/screenStyles/LoanDetailsScreen.css";
import { Accordion } from "react-bootstrap";
import { Check, ChevronsRight, Download, Info, UserCheck } from "react-feather";
import ProgressBar from "../../components/ProgressBar";
import { X } from "react-feather";
import Slider from "../../components/Slider";
import { useLocation, useNavigate } from "react-router-dom";
import Alert from "../../components/Alert";
import Loading from "../../components/Loading";
import InputField from "../../components/InputField";
import {
  Bank,
  Bank2,
  CurrencyRupee,
  InfoCircle,
  Translate,
} from "react-bootstrap-icons";
import { MyContext } from "../../components/GlobalValues";
import Metrixfunction from "../../components/MetrixFunction";
import Modal from "../../components/Modal";
import Dropdown from "../../components/DropDown";
import UpdateLoader from "../../components/updateLoader";
import CardsRadio from "../../components/CardsRadio";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import Paymentoptions from "../../components/Paymentoptions";

function LoanDetailsScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const { Url, metrixserver, paymentUrl } = useContext(MyContext);

  // const { loanId } = location.state || {};
  const [activeTab, setActiveTab] = useState(0);
  const [loanStatusCard, setLoanStatusCard] = useState("");
  const [userName, setUserName] = useState("");
  const [loanAmountStore, setLoanAmountStore] = useState("");
  const [paidPrincipleAmount, setPaidPrincipleAmount] = useState("");
  const [unPaidPrincipleAmount, setUnPaidPrincipleAmount] = useState("");
  const [unpaidChargesValue, setUnpaidChargesValue] = useState("");
  const [EMIDaily, setEMIDaily] = useState("");
  const [eligibleInterestStore, setEligibleInterestStore] = useState(0);
  const [loanStartDate, setLoanStartDate] = useState("");
  const [loanEndDate, setLoanEndDate] = useState("");
  const [paymentList, setPaymentList] = useState([]);
  const [loadId, setLoadId] = useState("");
  const [interestAmount, setInterestAmount] = useState("");
  const [settlementAmountStore, setSettlementAmountStore] = useState(0);
  const [bankIFSC, setBankIFSC] = useState("");
  const [bankAccount, setBankAccount] = useState("");
  const [remarksNotes, setRemarksNotes] = useState("");
  const [upcomingPayments, setUpcomingPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showSettlementAmount, setShowSettlementAmount] = useState(false);
  const [paymentPartialAmount, setPaymentPartialAmount] = useState();
  const [paymentLateAmount, setPaymentLateAmount] = useState(38730);

  const [duesChargesArrayList, setDuesChargesArrayList] = useState([]);
  const [dueCharges, setDueCharges] = useState("");
  const [duesPaid, setDuesPaid] = useState("");
  const [nocLinkData, setNocLinkData] = useState("");
  const [agreementLinkData, setAgreementLinkData] = useState("");
  const [settlementCardOpen, setSettlementCardOpen] = useState(false);
  const [hoverAnimation, setHoverAnimation] = useState(true);
  const [paymentOption, setPaymentOption] = useState({
    selectedpaymentOption: "",
    selectedpaymentType: "",
  });

  const [partialError, setpartialError] = useState("");

  const [updateLoad, setupdateLoad] = useState(false);
  const [changebtn, setchangebtn] = useState(false);
  const [paynowbtn, setpaynowbtn] = useState(false);

  const [paymentcharges, setpaymentcharges] = useState({
    late: 0,
    partial: 0,
    full: 0,
  });

  const [ifscloading, setifscloading] = useState(false);
  const formPayRef = useRef(null);

  const [loanData, setloanData] = useState([]);

  const [showLatePayment, setShowLatePayment] = useState(true);

  const PartialPaymentOption = ["Net Banking / UPI (charges added)"];

  const [paymentDate, setPaymentdate] = useState("");

  const options = [
    {
      id: "netbanking",
      label: "Net Banking",
      icon: "https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/Netbanking.png",
    },
    {
      id: "upi",
      label: "UPI",
      icon: "https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/UPI.png",
    },
    {
      id: "Direct Payment",
      label: "Bank Transfer",
      icon: "https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/Direct_payment.png",
    },
  ];

  const [mandateOpen, setMandateOpen] = useState(false);
  const handlemandateopen = () => setMandateOpen(true);
  const handlemandateclose = () => {
    setMandateOpen(false);
  };
  const [bankAccNoValue, setBankAccNoValue] = useState("");
  const [bankAccNoVerifyError, setBankAccNoVerifyError] = useState("");
  const [bankReAccNoValue, setBankReAccNoValue] = useState("");
  const [bankReAccNoVerifyError, setBankReAccNoVerifyError] = useState("");
  const [bankIfscValue, setBankIfscValue] = useState("");
  const [bankIfscVerifyError, setBankIfscVerifyError] = useState("");
  const [bankNameValue, setBankNameValue] = useState("");
  const [encryptedAccNo, setEncryptedAccNo] = useState("");
  const [encryptedReAccNo, setEncryptedReAccNo] = useState("");

   const convertDDMMYYYYFormat = (dateStr) => {
    console.log(dateStr,"dateStr")
     const [day, month, year] = dateStr.split("/");
     return `${day}/${month}/${year}`;
   };

  function removeSlash(port1) {
    let p1 = port1.split("/").join("");

    let np = p1;
    return np;
  }
  const changeTab = (index) => {
    setActiveTab(index);
  };

  let [pendingAmount, setPendingAmount] = useState(80000);
  let emiAmount = 3000;
  let interestRate = 0.12 / 12;

  const [alert, setAlert] = useState({
    show: false,
    message: "",
    type: "",
    duration: 3000, // Default duration
  });

  const showAlert = (message, type, duration = 3000) => {
    setAlert({
      show: true,
      message,
      type,
      duration,
    });

    // Automatically close the alert after the duration
    setTimeout(() => {
      setAlert((prevAlert) => ({
        ...prevAlert,
        show: false,
      }));
    }, duration);
  };

  const [payshow, setpayShow] = useState(false);

  const convertduetype = (value) => {
    let result = value
      .toLowerCase()
      .replace(/_/g, " ")
      .replace(/^\w/, (match) => match.toUpperCase());
    return result;
  };

  function getFormattedDate() {
    const today = new Date();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const year = today.getFullYear();
    console.log("adafdgfahgsd");
    setPaymentdate(`${month}-${day}-${year}`);
  }

  function checkDate(inputDate) {
    // Parse the input date (format: MM-DD-YYYY)
    const givenDate = new Date(inputDate);

    // Get today's date
    const today = new Date();

    // Calculate 1 year from today
    const oneYearLater = new Date();
    oneYearLater.setFullYear(today.getFullYear() + 1);
    console.log(inputDate, givenDate < oneYearLater, "checkkkk");
    // Compare the given date to one year from today
    return givenDate < oneYearLater;
  }

  const handleResponse = (res) => {
    if (
      typeof res != "undefined" &&
      typeof res.paymentMethod != "undefined" &&
      typeof res.paymentMethod.paymentTransaction != "undefined" &&
      typeof res.paymentMethod.paymentTransaction.statusCode != "undefined" &&
      res.paymentMethod.paymentTransaction.statusCode == "0300"
    ) {
      // success block
    } else if (
      typeof res != "undefined" &&
      typeof res.paymentMethod != "undefined" &&
      typeof res.paymentMethod.paymentTransaction != "undefined" &&
      typeof res.paymentMethod.paymentTransaction.statusCode != "undefined" &&
      res.paymentMethod.paymentTransaction.statusCode == "0398"
    ) {
      // initiated block
    } else {
      console.log("Error : ", res);
      // error block
    }
  };

  const changeBankAccount = async () => {
    try {
      const accId = localStorage.getItem("accId");
      const apiKey = localStorage.getItem("apiKey");

      // if (accId.trim() === "" || apiKey.trim() === "" || loadId.trim() === "") {
      //   showAlert("Not Registered", "danger");
      //   localStorage.clear();
      //   navigate("/login");
      //   return;
      // }

      if (bankAccNoValue.length < 10) {
        showAlert("Enter the valid bank account number", "danger");
      } else if (bankIfscValue.length !== 11) {
        showAlert("Enter the valid bank IFSC", "danger");
      } else if (loanData.bankAccount === bankAccNoValue) {
        showAlert(
          "Please enter a different bank account number, The new account number cannot be the same as the current one",
          "danger"
        );
      } else {
        setupdateLoad(true);
        setchangebtn(true);
        const response = await fetch(
          `${paymentUrl}/payment/changeBankAccount`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              accId: accId,
              apiKey: apiKey,
              loanId: loadId,
              bankAccount: bankAccNoValue,
              bankIfsc: bankIfscValue,
            }),
          }
        );
        const resJson = await response.json();
        if (resJson.Success) {
          let resJsVar = resJson.Success;
          resJsVar.consumerData.responseHandler = handleResponse;

          const $ = window.$;
          const pnCheckoutShared = window.pnCheckoutShared;
          $.pnCheckout(resJsVar);
          localStorage.setItem("message", true);
          localStorage.setItem("processType", "changebank");
          if (resJsVar.features && resJsVar.features.enableNewWindowFlow) {
            pnCheckoutShared.openNewWindow();
            handlemandateclose();
            setIsLoading(true);
          }
        } else {
          showAlert(resJson.Error, "danger");
        }
        setupdateLoad(false);
        setchangebtn(false);
        Metrixfunction(metrixserver, "changeBankAccount", "UPDATE");
      }
      // showAlert(resJson.Success, "success");
      // getHomeApi();
      // }
    } catch (error) {
      console.log(error);
      showAlert(error, "danger");
      setupdateLoad(false);
      setchangebtn(false);
    }
  };

  //  const calculateInterest = () => {

  //    return interestAmount;
  //  };

  // const calculateNextPayments = () => {
  //   let payments = [];
  //   let remainingAmount = pendingAmount;
  //   let lastpaymentFlag = 0;
  //   let currentDate = new Date();

  //   while (remainingAmount > 0) {
  //     if (remainingAmount < emiAmount) {
  //       emiAmount = remainingAmount;
  //       lastpaymentFlag = 1;
  //     }
  //     let interestAmount = Math.round(remainingAmount * interestRate);
  //     let currPrincipal = emiAmount - interestAmount;
  //     remainingAmount -= currPrincipal;
  //     if (lastpaymentFlag == 1) {
  //       remainingAmount = 0;
  //     }

  //     let nextPaymentDate = new Date(currentDate);
  //     nextPaymentDate.setMonth(nextPaymentDate.getMonth() + payments.length);

  //     payments.push({
  //       emi: emiAmount,
  //       interest: interestAmount,
  //       currPrincipal: currPrincipal,
  //       remainingAmount: remainingAmount, // Ensure remaining amount doesn't go below 0
  //       paymentDate: nextPaymentDate.toLocaleDateString("en-IN", {
  //         year: "numeric",
  //         month: "long",
  //       }),
  //     });
  //   }

  //   return payments;
  // };

  const calculateNextPayments = (
    unPaidPrincipleAmountArg,
    eligibleInterestArg,
    EMIDailyArg,
    tenure
  ) => {
    let payments = [];
    let remainingAmount =
      Math.round(unPaidPrincipleAmountArg) +
      Math.round(Number(dueCharges)) -
      Math.round(Number(duesPaid));
    let lastpaymentFlag = 0;
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 30);
    currentDate.setDate(1);
    let emiAmount = Math.round(EMIDailyArg);
    let interestRate = Number(
      (Math.round(eligibleInterestArg) / 100 / 12).toFixed(4)
    );

    let iterationCount = 0;
    const maxIterations = 100;

    while (remainingAmount > 0) {
      if (remainingAmount < emiAmount) {
        emiAmount = remainingAmount;
        lastpaymentFlag = 1;
      }
      let interestAmount = Math.round(remainingAmount * interestRate);
      let currPrincipal = emiAmount - interestAmount;
      if (currPrincipal < 0) {
        showAlert(
          "Issue with the loan please wait until we verify it",
          "danger"
        );
        break;
      }
      remainingAmount -= currPrincipal;
      if (lastpaymentFlag == 1) {
        remainingAmount = 0;
      }

      let nextPaymentDate = new Date(currentDate);
      nextPaymentDate.setMonth(nextPaymentDate.getMonth() + payments.length);

      payments.push({
        emi: emiAmount,
        interest: interestAmount,
        currPrincipal: currPrincipal,
        remainingAmount: remainingAmount,
        paymentDate: nextPaymentDate.toLocaleDateString("en-IN", {
          year: "numeric",
          month: "long",
        }),
      });

      iterationCount++;
      if (iterationCount >= maxIterations) {
        //console.log(
        //   "Max iterations reached. Breaking the loop to prevent infinite loop."
        // );
        break;
      }
    }

    return payments;
  };

  // const upcomingPayments = calculateNextPayments();

  const [settlementModalOpen, setSettlementModalOpen] = useState(false);
  const handlesettlementmodalopen = () => setSettlementModalOpen(true);
  const handlesettlementmodalclose = () => {
    setSettlementModalOpen(false);
  };
  const [dueChargesModalOpen, setDueChargesModalOpen] = useState(false);
  const handleduechargesmodalopen = () => setDueChargesModalOpen(true);
  const handleduechargesmodalclose = () => {
    setDueChargesModalOpen(false);
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);

    setPaymentOption({
      selectedpaymentOption: "",
      selectedpaymentType: event.target.value,
    });

    // if (event.target.value === "Late Payment") {
    //   setPaymentOption({
    //     selectedpaymentOption: "Net Banking / UPI (charges added)",
    //     selectedpaymentType: event.target.value,
    //   });
    // } else {

    // }
  };

  const [selectedOption, setSelectedOption] = useState("FULL");

  const [loanAmount, setLoanAmount] = useState(0);
  const handleLoanAmountChange = (newValue) => {
    setLoanAmount(newValue);
  };

  const getLoanDetailsApi = async (loanId) => {
    try {
      const accId = localStorage.getItem("accId");
      const apiKey = localStorage.getItem("apiKey");

      if (!accId || !apiKey || !loanId) {
        navigate("/dashboard");
        return;
      }

      const response = await fetch(`${Url}/business/v2/getLoanDetails`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          accId,
          apiKey,
          loanId,
        }),
      })
        .then((res) => res.json())
        .then((resJson) => {
          if (resJson.Success) {
            setloanData(resJson.Success);
            setpaymentcharges({
              full: resJson.Success.fullPaymentCharges,
              late: resJson.Success.latePaymentCharges,
              partial: resJson.Success.partitalPaymentCharges,
            });
            let sortedPayments = resJson.Success.payments
              .map((payment) => ({
                ...payment,
                date: new Date(payment.date),
              }))
              .sort((a, b) => a.date - b.date);

            setLoadId(resJson.Success.loanId);
            setShowLatePayment(resJson.Success.latePaymentCharges > 0);
            setPaymentList(sortedPayments);
            setInterestAmount(resJson.Success.loanInterestAmount);
            if (resJson.Success.loanStatus !== "BANKVERIFY") {
              setLoanStatusCard(resJson.Success.loanStatus);
            } else {
              navigate("/dashboard");
            }
            setUserName(localStorage.getItem("userName"));
            setLoanAmountStore(resJson.Success.loanAmount);
            setPaidPrincipleAmount(resJson.Success.paidPrincipalAmount);
            setUnPaidPrincipleAmount(resJson.Success.unpaidPrincipalAmount);
            setEMIDaily(resJson.Success.dailyEMI);
            setEligibleInterestStore(resJson.Success.loanInterestRatePct);
            setLoanStartDate(resJson.Success.loanStartDate);
            setLoanEndDate(resJson.Success.loanEndDate);
            setBankIFSC(resJson.Success.virtualAcc.virtualAccIFSC);
            setBankAccount(resJson.Success.virtualAcc.virtualAccNumber);
            setRemarksNotes(resJson.Success.virtualAcc.virtualAccRemarks);
            setRemarksNotes(resJson.Success.virtualAcc.virtualAccRemarks);
            setDueCharges(resJson.Success.dueCharges);
            setDuesPaid(resJson.Success.duesPaid);

            if (
              resJson.Success.loanStatus == "INPROGRESS" ||
              resJson.Success.loanStatus == "CLOSED"
            ) {
              setAgreementLinkData(resJson.Success.agreementLink);
              if (resJson.Success.loanStatus == "CLOSED") {
                setNocLinkData(resJson.Success.nocLink);
              }
              if (
                resJson.Success.loanStatus == "INPROGRESS" &&
                resJson.Success.loanDisbursedDate.length < 5
              ) {
                setLoanStatusCard("PENDINGAMOUNT");
              }
              const uppAmount =
                Number(resJson.Success.unpaidPrincipalAmount) +
                (Number(resJson.Success.dueCharges) -
                  Number(resJson.Success.duesPaid));
              setUnpaidChargesValue(uppAmount);

              setDuesChargesArrayList(resJson.Success.extraCharges);
              setUpcomingPayments(
                calculateNextPayments(
                  uppAmount,
                  Number(resJson.Success.loanInterestRatePct),
                  Number(resJson.Success.dailyEMI),
                  Number(resJson.Success.loanDuration)
                )
              );
            }
            setIsLoading(false);
          } else {
            navigate("/dashboard");
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          Metrixfunction(metrixserver, "getLoanDetails", "ONLOAD");
        });
    } catch (error) {
      console.log(error);
      showAlert(error, "danger");
    }
  };

  useEffect(() => {
    getLoanDetailsApi(localStorage.getItem("loanIdStore"));
  }, []);

  // faq

  const faqItems = [
    {
      question: "What does confirmation status mean in a transaction?",
      answer:
        "When the user goes to the retailer through MyKaasu and completes a transaction, the transaction amount will be received by the retailer. The transaction status will be updated to 'CONFIRMED' at this stage and can be viewed under transactions. Once the amount is added to the customer wallet, the status will change to 'PAID'.",
    },
    {
      question: "What is the difference between Bonus and Cashback?",
      answer:
        "Cashback is the amount you receive from your purchases and rewards are joining/referral bonus you receive when your friend purchases. We pay up to 10% of the cashback every time your referral purchases.",
    },
    {
      question: "Is there any expiry for my Cashback/Rewards?",
      answer:
        "NO. There is no expiry on the Cashback or rewards balance. But if you decide to close your account, then the account balance cannot be withdrawn if the amount is less than 50/-",
    },
    {
      question: "My Cashback/Rewards did not track, what can I do?",
      answer:
        "Please navigate to transactions and add a comment under 'Need Help' against the corresponding transaction. Our customer support team will revert back shortly.",
    },
    {
      question:
        "How can I combine my Cashback and Rewards to request for the Payment?",
      answer:
        "Navigate to 'Withdraw Amount' to view the cashback and rewards balance. You can combine both the balances and withdraw when the conditions are met,\n(i) Your total balance amount is Rs 50/- or higher. Withdraw button will be disabled when balance is low.\n(ii) Bank account details are updated under Account > Bank Account.\n(iii) Previous transaction is not in progress.",
    },
    {
      question: "How much will I earn on referring a friend?",
      answer:
        "There is no limit on the amount you can receive. Currently you are eligible to earn up to 10% of the total referral earnings.",
    },
    {
      question: "Can I pay off my loan early?",
      answer:
        "Some borrowers want to know if they can make extra payments or pay off the loan ahead of schedule without penalties.",
    },
    {
      question: "What is the loan approval timeline?",
      answer:
        "Borrowers often ask how long it takes to get approved for a loan and receive the funds.",
    },
    {
      question: "What are the interest rates?",
      answer:
        "Understanding the interest rates associated with a loan is a common concern, including fixed vs. variable rates.",
    },
  ];

  const encryptAccountNo = (type) => {
    if (type === "account") {
      const lastFour = bankAccNoValue.slice(-4);
      const maskedText =
        "*".repeat(Math.max(0, bankAccNoValue.length - 4)) + lastFour;
      setEncryptedAccNo(maskedText);
    } else {
      const lastFour = bankReAccNoValue.slice(-4);
      const maskedText =
        "*".repeat(Math.max(0, bankReAccNoValue.length - 4)) + lastFour;
      setEncryptedReAccNo(maskedText);
    }
  };
  const handleCopy = (e) => {
    e.preventDefault();
  };

  const handleVerifyOnChange = async (value, field) => {
    // Remove any non-numeric characters

    const accountnoregex = /^\d$/;
    const ifscregex = /^[A-Z]{4}0[A-Z0-9]{6}$/;

    if (field === "accno") {
      const accountnovalue = value.replace(/[^0-9]/g, "");
      setBankAccNoValue(accountnovalue);
      setBankAccNoVerifyError("");
    }
    if (field === "reaccno") {
      const reaccountnovalue = value.replace(/[^0-9]/g, "");
      setBankReAccNoValue(reaccountnovalue);
      let bankAccLen = bankAccNoValue.length;
      if (bankAccNoValue) {
        if (
          reaccountnovalue === bankAccNoValue &&
          reaccountnovalue.length == bankAccLen
        ) {
          setBankReAccNoVerifyError("");
        } else {
          setBankReAccNoVerifyError("Bank account number does not match");
        }
      } else {
        setBankReAccNoVerifyError("First enter your account number");
      }
    }
    if (field === "ifsc") {
      let uppercaseifsc = value.toUpperCase();
      setBankIfscValue(uppercaseifsc);
      if (uppercaseifsc.length === 11) {
        if (ifscregex.test(value)) {
          setBankIfscVerifyError("");
          try {
            setifscloading(true);
            await fetch(`${Url}/business/validateIfsc?ifsc=${uppercaseifsc}`)
              .then((res) => res.json())
              .then((resJson) => {
                if (resJson && resJson.bank && resJson.address) {
                  setBankNameValue(resJson.bank);
                } else {
                  setBankIfscVerifyError(
                    "Invalid IFSC code or bank details not found"
                  );
                  setBankNameValue("");
                }
                setifscloading(false);
              })
              .catch((err) => {
                console.log(err, "error");
              })
              .finally(() => {
                Metrixfunction(metrixserver, "validateIfsc", "UPDATE");
              });
          } catch (error) {
            setBankIfscVerifyError("Invalid IFSC code");
            setBankNameValue("");
            setifscloading(false);
          }
        } else {
          setBankIfscVerifyError("Please enter correct bank Ifsc number");
        }
      } else {
        if (uppercaseifsc.length > 9) {
          setBankNameValue("");
        }
      }
    }
  };

  function loanProgressFunc(loanAmount, unpaidAmount) {
    let finalPercent = Math.round(
      ((loanAmount - unpaidAmount) / loanAmount) * 100
    );

    if (finalPercent < 0) {
      finalPercent = 3;
    } else if (finalPercent >= 0 && finalPercent <= 5) {
      finalPercent = 3;
    } else if (finalPercent > 100) {
      finalPercent = 100;
    }
    return finalPercent;
  }

  function formatDate(isoString) {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  const getSettlementsApi = async (dayArg, monthArg, YearArg) => {
    try {
      const accId = localStorage.getItem("accId");
      const apiKey = localStorage.getItem("apiKey");

      if (dayArg === "" || monthArg === "" || YearArg === "") {
        showAlert("Date is empty. Pick the date", "danger");
        return;
      }
      if (!accId || !apiKey || !localStorage.getItem("loanIdStore")) {
        navigate("/dashboard");
        return;
      }

      const response = await fetch(`${Url}/test/getSettlementDetails`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          accId: accId,
          apiKey: apiKey,
          loanId: loadId,
          day: dayArg,
          month: monthArg,
          year: YearArg,
        }),
      });
      const resJson = await response.json();

      if (!resJson.Success) {
        showAlert("Not Registered", "danger");
        navigate("/dashboard");
        return;
      }

      if (resJson.Success) {
        setShowSettlementAmount(true);
        setSettlementAmountStore(resJson.Success.settlementAmt);
      } else if (resJson.Error) {
        setShowSettlementAmount(false);
        showAlert(resJson.Error, "danger");
      }
      setIsLoading(false);

      Metrixfunction(metrixserver, "getSettlementDetails", "ONLOAD");
    } catch (error) {
      console.error(error);
      showAlert(error, "danger");
    }
  };

  function isDateAbove30Days(dateToCheck) {
    const currentDate = new Date();
    const pastDate = new Date(dateToCheck);

    const timeDifference = currentDate - pastDate;

    const dayDifference = timeDifference / (1000 * 60 * 60 * 24);

    return dayDifference > 30;
  }

  // select date time picker for settlement
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-indexed month
  const currentDay = currentDate.getDate();

  const generateDays = (month, year) => {
    const daysInMonth = new Date(year, month, 0).getDate();
    let days = Array.from({ length: daysInMonth }, (_, i) => i + 1);

    // Filter out past days if current year and month are selected
    if (year === currentYear && month === currentMonth) {
      days = days.filter((day) => day >= currentDay);
    }

    return days;
  };

  // Generate month options (1 to 12)
  const generateMonths = (year) => {
    let months = Array.from({ length: 12 }, (_, i) => i + 1);

    // Filter out past months if current year is selected
    if (year === currentYear) {
      months = months.filter((month) => month >= currentMonth);
    }

    return months;
  };

  // Generate year options (from current year onwards)
  const generateYears = () => {
    const futureYears = Array.from({ length: 30 }, (_, i) => currentYear + i);
    return futureYears;
  };

  const settlementdays =
    selectedMonth && selectedYear
      ? generateDays(Number(selectedMonth), Number(selectedYear))
      : [];
  const settlementmonths = selectedYear
    ? generateMonths(Number(selectedYear))
    : [];
  const settlementyears = generateYears();

  const handleDayChange = (event) => setSelectedDay(event.target.value);
  const handleMonthChange = (event) => setSelectedMonth(event.target.value);
  const handleYearChange = (event) => setSelectedYear(event.target.value);

  const settlmentCardRef = useRef(null);
  const chargesRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        settlmentCardRef.current &&
        !settlmentCardRef.current.contains(event.target)
      ) {
        setSettlementCardOpen(false);
        setHoverAnimation(true);
        setSelectedDay("");
        setSelectedMonth("");
        setSelectedYear("");
      }
      if (chargesRef.current && !chargesRef.current.contains(event.target)) {
        setpayShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const LoanPayNow = async (amountArgs) => {
    try {
      const accId = localStorage.getItem("accId");
      const apiKey = localStorage.getItem("apiKey");

      if (amountArgs > 0) {
        const formData = new FormData();
        formData.append("amount", amountArgs);
        setpaynowbtn(true);
        setupdateLoad(true);
        await fetch(`${paymentUrl}/gateway/initiateTransaction`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            apiKey: apiKey,
            accId: accId,
            amount: amountArgs,
            loanId: loadId,
            paymentType: selectedOption,
            paymentDate: paymentDate,
          }),
        })
          .then((res) => res.json())
          .then((resJson) => {
            if (resJson.Success) {
              localStorage.setItem("message", true);
              localStorage.setItem("response", JSON.stringify(resJson.Success));
              setIsLoading(false);
              const formPay = formPayRef.current;
              const msg = resJson.Success;

              if (localStorage.getItem("message") === "true") {
                if (formPay && formPay.tagName === "FORM") {
                  localStorage.setItem("processType", "paymentgateway");
                  handlesettlementmodalclose();
                  setIsLoading(true);
                  formPay.querySelector("#EncData").value = msg.EncData;
                  formPay.querySelector("#MerchantId").value = msg.MerchantId;
                  formPay.querySelector("#BankId").value = msg.BankId;
                  formPay.querySelector("#TerminalId").value = msg.TerminalId;
                  formPay.method = "post";
                  formPay.action = msg.action; // Replace with actual URL
                  formPay.submit();
                } else {
                  console.error(
                    'Form element with ID "formPay" not found or is not a form.'
                  );
                }
              } else {
                console.log("Message is not set inside local storage");
              }
            } else {
              showAlert("Payment Failed", "danger");
            }
            setpaynowbtn(false);
            setupdateLoad(false);
          })
          .catch((err) => {
            console.log("Error", err);
            setpaynowbtn(false);
            setupdateLoad(false);
          })
          .finally(() => {
            Metrixfunction(metrixserver, "initiateTransaction", "UPDATE");
          });
      } else {
        showAlert("Enter Amount", "danger");
      }
    } catch (error) {
      console.log(error);
      showAlert("Server Error while Payment", "danger");
    }
  };

  const drpRef = useRef(null);
  const [checkDrpPartial, setCheckDrpPartial] = useState(true);
  const [checkDrpFull, setCheckDrpFull] = useState(true);

  useEffect(() => {
    if (drpRef.current) {
      drpRef.current.scrollTo({
        top: drpRef.current.scrollHeight,
        behavior: "smooth", // This adds a smooth scrolling effect
      });
    }
  }, [checkDrpPartial]);

  const scrollmodaldown = () => {
    if (drpRef.current) {
      drpRef.current.scrollTo({
        top: drpRef.current.scrollHeight,
        behavior: "smooth", // This adds a smooth scrolling effect
      });
    }
  };
  useEffect(() => {
    if (drpRef.current) {
      drpRef.current.scrollTo({
        top: drpRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [paymentOption]);

  const handleDropdownFunctionPartial = (event) => {
    setCheckDrpPartial(event);
  };
  const handleDropdownFunctionFull = (event) => {
    setCheckDrpFull(event);
  };

  return (
    (isLoading && <Loading />) || (
      <>
        {updateLoad && <UpdateLoader />}
        <div className="loan-details-container">
          {loanStatusCard === "INPROGRESS" && (
            <div
              className="loan-details-partial-container"
              style={{ width: "85%" }}
            >
              <button
                className="loan-details-partial-button"
                onClick={() => {
                  setShowSettlementAmount(false);
                  const currentDatePay = new Date();

                  const dayPay = currentDatePay.getDate().toString();
                  const monthPay = (currentDatePay.getMonth() + 1).toString();
                  const yearPay = currentDatePay.getFullYear().toString();

                  let showLatePayment;

                  if (paymentList.length == 0) {
                    showLatePayment = isDateAbove30Days(loanStartDate);
                  } else {
                    const PaymentdateValue = new Date(
                      paymentList[paymentList.length - 1].date
                    );
                    const PaymentValueyear = PaymentdateValue.getFullYear();
                    const PaymentValuemonth = String(
                      PaymentdateValue.getMonth() + 1
                    ).padStart(2, "0");
                    const PaymentValueday = String(
                      PaymentdateValue.getDate()
                    ).padStart(2, "0");

                    const PaymentDateFormat = `${PaymentValueyear}-${PaymentValuemonth}-${PaymentValueday}`;
                    showLatePayment = isDateAbove30Days(PaymentDateFormat);
                  }

                  // setShowLatePayment(showLatePayment);
                  getSettlementsApi(dayPay, monthPay, yearPay);
                  getFormattedDate();
                  handlesettlementmodalopen();
                }}
              >
                Pay Now
              </button>
              {loanData.mandateFlag === "yes" && (
                <button
                  className="loan-details-agreement-button"
                  onClick={handlemandateopen}
                >
                  Change Bank
                </button>
              )}
            </div>
          )}

          {loanStatusCard === "PENDINGAMOUNT" && (
            <div style={{ marginTop: 30, width: "85%", color: "white" }}>
              <div className="home-dynamic-card-content">
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h2 className="home-dynamic-card-left-side-title-inprogress">
                    ₹{loanAmountStore}
                  </h2>
                  <div
                    className="home-dynamic-card-top"
                    style={{ width: "40%" }}
                  >
                    <p className="home-dynamic-card-status"
                    style={{ color: "#000", backgroundColor: "rgb(219, 219, 219)" }}
                    >
                      PENDING BANK TRANSFER
                    </p>
                  </div>
                </div>
              </div>
              <div className="home-dynamic-card-left-sted-container">
                <div className="home-dynamic-card-left-sted-wrapper">
                  <p className="home-dynamic-card-left-sted-title">
                    Start Date
                  </p>
                  <p className="home-dynamic-card-left-sted-subtitle">
                    {loanStartDate.replace(/-/g, "/")}
                  </p>
                </div>
                <ChevronsRight
                  size={30}
                  style={{ animation: "none", color: "#262B47" }}
                />
                <div className="home-dynamic-card-left-sted-wrapper">
                  <p className="home-dynamic-card-left-sted-title">End Date</p>
                  <p className="home-dynamic-card-left-sted-subtitle">
                    {loanEndDate.replace(/-/g, "/")}
                  </p>
                </div>
              </div>
              <ProgressBar
                bgcolor={"#e32f45"}
                completed={loanProgressFunc(
                  loanAmountStore,
                  unpaidChargesValue
                )}
                bgcolorContainer={"#dbdbdb"}
              />
              <div
                className="home-dynamic-card-image-button-container"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div className="home-dynamic-card-image-button">
                  <p className="home-dynamic-card-image-button-title">Unpaid</p>
                  <p className="home-dynamic-card-image-button-subtitle">
                    ₹
                    {Math.round(Number(unPaidPrincipleAmount)) +
                      (Math.round(Number(dueCharges)) -
                        Math.round(Number(duesPaid))) >
                    0
                      ? Math.round(Number(unPaidPrincipleAmount)) +
                        (Math.round(Number(dueCharges)) -
                          Math.round(Number(duesPaid)))
                      : 0}
                    <p
                      style={{
                        position: "relative",
                        marginLeft: 5,
                        zIndex: 20,
                        width: 30,
                        height: 25,
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        setShowSettlementAmount(false);
                        setSettlementCardOpen(true);
                        setHoverAnimation(false);
                      }}
                    >
                      <Info
                        style={{
                          width: 18,
                          height: 18,
                          cursor: "pointer",
                          zIndex: 10,
                        }}
                      />
                    </p>
                  </p>
                </div>
                {settlementCardOpen && (
                  <div
                    ref={settlmentCardRef}
                    className="home-dynamic-result-disbursed-info-modal"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <div className="home-dynamic-result-disbursed-info-container">
                      <p className="home-dynamic-result-disbursed-info-key">
                        The actual amount will vary due to interest or other
                        charges associated with your account
                      </p>
                    </div>
                    {showSettlementAmount === false ? (
                      <>
                        <div className="home-dynamic-date-selectors">
                          <label>
                            <select
                              value={selectedDay}
                              onChange={handleDayChange}
                            >
                              <option value="">Day</option>
                              {settlementdays.map((day) => (
                                <option key={day} value={day}>
                                  {day}
                                </option>
                              ))}
                            </select>
                          </label>
                          <label>
                            <select
                              value={selectedMonth}
                              onChange={handleMonthChange}
                            >
                              <option value="">Month</option>
                              {settlementmonths.map((month) => (
                                <option key={month} value={month}>
                                  {month}
                                </option>
                              ))}
                            </select>
                          </label>
                          <label>
                            <select
                              value={selectedYear}
                              onChange={handleYearChange}
                            >
                              <option value="">Year</option>
                              {settlementyears.map((year) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                            </select>
                          </label>
                        </div>
                        <div className="home-dynamic-result-disbursed-info-container">
                          <p
                            className="home-dynamic-get-settlement"
                            onClick={(e) => {
                              e.stopPropagation();
                              getSettlementsApi(
                                selectedDay,
                                selectedMonth,
                                selectedYear
                              );
                            }}
                          >
                            Get Settlement
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <label className="home-dynamic-result-disbursed-info-key">
                          Settlement Amount
                        </label>
                        <div style={{ width: "90%", marginLeft: "5%" }}>
                          <InputField
                            placeholder="Settlement Amount"
                            maxLength={25}
                            value={settlementAmountStore}
                            editable={false}
                            leftIcon={
                              <Bank
                                style={{
                                  animation: "none",
                                  color: "#868585",
                                }}
                              />
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>
                )}
                <div className="home-dynamic-card-image-button">
                  <p className="home-dynamic-card-image-button-title">
                    Interest(APR)
                  </p>
                  <p className="home-dynamic-card-image-button-subtitle">
                    {eligibleInterestStore}%
                  </p>
                </div>
                <div className="home-dynamic-card-image-button">
                  <p className="home-dynamic-card-image-button-title">EMI</p>
                  <p className="home-dynamic-card-image-button-subtitle">
                    ₹{Math.round(EMIDaily)}
                  </p>
                </div>
              </div>
              {Math.round(Number(unPaidPrincipleAmount)) +
                (Math.round(Number(dueCharges)) -
                  Math.round(Number(duesPaid))) <=
                0 && (
                <p style={{ marginTop: 5, opacity: 0.8 }}>
                  * You have made all payments. Soon your loan will be closed.
                </p>
              )}
            </div>
          )}

          {loanStatusCard === "INPROGRESS" || loanStatusCard === "PAUSED" ? (
            <div style={{ marginTop: 30, width: "85%", color: "white" }}>
              <div className="home-dynamic-card-content">
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h2 className="home-dynamic-card-left-side-title-inprogress">
                    ₹{loanAmountStore}
                  </h2>
                  <div
                    className="home-dynamic-card-top"
                    style={{ width: "40%" }}
                  >
                    <p
                      className="home-dynamic-card-status"
                      style={{
                        color: "#000",
                        backgroundColor: "rgb(219, 219, 219)",
                      }}
                    >
                      IN PROGRESS
                    </p>
                  </div>
                </div>
              </div>

              <div className="home-dynamic-card-left-sted-container">
                <div className="home-dynamic-card-left-sted-wrapper">
                  <p className="home-dynamic-card-left-sted-title">
                    Start Date
                  </p>
                  <p className="home-dynamic-card-left-sted-subtitle">
                    {loanStartDate.replace(/-/g, "/")}
                  </p>
                </div>
                <ChevronsRight
                  size={30}
                  style={{ animation: "none", color: "#262B47" }}
                />
                <div className="home-dynamic-card-left-sted-wrapper">
                  <p className="home-dynamic-card-left-sted-title">End Date</p>
                  <p className="home-dynamic-card-left-sted-subtitle">
                    {loanEndDate.replace(/-/g, "/")}
                  </p>
                </div>
              </div>
              <ProgressBar
                bgcolor={"#e32f45"}
                completed={loanProgressFunc(
                  loanAmountStore,
                  unpaidChargesValue
                )}
                bgcolorContainer={"#dbdbdb"}
              />
              <div className="home-dynamic-card-image-button-container">
                <div className="home-dynamic-card-image-button">
                  <p className="home-dynamic-card-image-button-title">Unpaid</p>
                  <p className="home-dynamic-card-image-button-subtitle">
                    ₹
                    {Math.round(Number(unPaidPrincipleAmount)) +
                      (Math.round(Number(dueCharges)) -
                        Math.round(Number(duesPaid))) >
                    0
                      ? Math.round(Number(unPaidPrincipleAmount)) +
                        (Math.round(Number(dueCharges)) -
                          Math.round(Number(duesPaid)))
                      : 0}
                    {/* <p
                          style={{
                            position: "relative",
                            marginLeft: 5,
                            zIndex: 20,
                            width: 30,
                            height: 25,
                            display: "flex",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            setShowSettlementAmount(false);
                            setSettlementCardOpen(true);
                            setHoverAnimation(false);
                          }}
                        >
                          <Info
                            style={{
                              width: 18,
                              height: 18,
                              cursor: "pointer",
                              zIndex: 10,
                            }}
                          />
                        </p> */}
                  </p>
                </div>
                {settlementCardOpen && (
                  <div
                    ref={settlmentCardRef}
                    className="home-dynamic-result-disbursed-info-modal"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <div className="home-dynamic-result-disbursed-info-container">
                      <p className="home-dynamic-result-disbursed-info-key">
                        The actual amount will vary due to interest or other
                        charges associated with your account
                      </p>
                    </div>
                    {showSettlementAmount === false ? (
                      <>
                        <div className="home-dynamic-date-selectors">
                          <label>
                            <select
                              value={selectedDay}
                              onChange={handleDayChange}
                            >
                              <option value="">Day</option>
                              {settlementdays.map((day) => (
                                <option key={day} value={day}>
                                  {day}
                                </option>
                              ))}
                            </select>
                          </label>
                          <label>
                            <select
                              value={selectedMonth}
                              onChange={handleMonthChange}
                            >
                              <option value="">Month</option>
                              {settlementmonths.map((month) => (
                                <option key={month} value={month}>
                                  {month}
                                </option>
                              ))}
                            </select>
                          </label>
                          <label>
                            <select
                              value={selectedYear}
                              onChange={handleYearChange}
                            >
                              <option value="">Year</option>
                              {settlementyears.map((year) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                            </select>
                          </label>
                        </div>
                        <div className="home-dynamic-result-disbursed-info-container">
                          <p
                            className="home-dynamic-get-settlement"
                            onClick={(e) => {
                              e.stopPropagation();
                              getSettlementsApi(
                                selectedDay,
                                selectedMonth,
                                selectedYear
                              );
                            }}
                          >
                            Get Settlement
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <label className="home-dynamic-result-disbursed-info-key">
                          Settlement Amount
                        </label>
                        <div style={{ width: "90%", marginLeft: "5%" }}>
                          <InputField
                            placeholder="Settlement Amount"
                            maxLength={25}
                            value={settlementAmountStore}
                            editable={false}
                            leftIcon={
                              <Bank
                                style={{
                                  animation: "none",
                                  color: "#868585",
                                }}
                              />
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>
                )}
                <div className="home-dynamic-card-image-button">
                  <p className="home-dynamic-card-image-button-title">
                    Interest(APR)
                  </p>
                  <p className="home-dynamic-card-image-button-subtitle">
                    {eligibleInterestStore}%
                  </p>
                </div>
                <div className="home-dynamic-card-image-button">
                  <p className="home-dynamic-card-image-button-title">EMI</p>
                  <p className="home-dynamic-card-image-button-subtitle">
                    ₹{Math.round(EMIDaily)}
                  </p>
                </div>
              </div>
              {Math.round(Number(unPaidPrincipleAmount)) +
                (Math.round(Number(dueCharges)) -
                  Math.round(Number(duesPaid))) <=
                0 && (
                <p style={{ marginTop: 5, opacity: 0.8 }}>
                  * You have made all payments. Soon your loan will be closed.
                </p>
              )}
              {/* <svg
          width="48"
          viewBox="0 -960 960 960"
          height="48"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m393-165 279-335H492l36-286-253 366h154l-36 255Zm-73 85 40-280H160l360-520h80l-40 320h240L400-80h-80Zm153-395Z"></path>
        </svg> */}
            </div>
          ) : null}

          {loanStatusCard === "CLOSED" && (
            <div style={{ marginTop: 30, width: "85%", color: "white" }}>
              <div className="home-dynamic-card-content">
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h2 className="home-dynamic-card-left-side-title-inprogress">
                    ₹{loanAmountStore}
                  </h2>
                  <div
                    className="home-dynamic-card-top"
                    style={{ width: "40%" }}
                  >
                    <p
                      className="home-dynamic-card-status"
                      style={{
                        color: "#000",
                        backgroundColor: "rgb(219, 219, 219)",
                      }}
                    >
                      CLOSED
                    </p>
                  </div>
                </div>
              </div>

              <div className="home-dynamic-card-left-sted-container">
                <div className="home-dynamic-card-left-sted-wrapper">
                  <p className="home-dynamic-card-left-sted-title">
                    Start Date
                  </p>
                  <p className="home-dynamic-card-left-sted-subtitle">
                    {loanStartDate.replace(/-/g, "/")}
                  </p>
                </div>
                <ChevronsRight
                  size={30}
                  style={{ animation: "none", color: "#262B47" }}
                />
                <div className="home-dynamic-card-left-sted-wrapper">
                  <p className="home-dynamic-card-left-sted-title">End Date</p>
                  <p className="home-dynamic-card-left-sted-subtitle">
                    {loanEndDate.replace(/-/g, "/")}
                  </p>
                </div>
              </div>
              <ProgressBar
                bgcolor={"#e32f45"}
                completed={loanProgressFunc(
                  loanAmountStore,
                  unpaidChargesValue
                )}
                bgcolorContainer={"#dbdbdb"}
              />
              <div className="home-dynamic-card-image-button-container">
                <div className="home-dynamic-card-image-button">
                  <p className="home-dynamic-card-image-button-title">Unpaid</p>
                  <p className="home-dynamic-card-image-button-subtitle">
                    ₹
                    {Math.round(Number(unPaidPrincipleAmount)) +
                      (Math.round(Number(dueCharges)) -
                        Math.round(Number(duesPaid))) >
                    0
                      ? Math.round(Number(unPaidPrincipleAmount)) +
                        (Math.round(Number(dueCharges)) -
                          Math.round(Number(duesPaid)))
                      : 0}
                  </p>
                </div>
                <div className="home-dynamic-card-image-button">
                  <p className="home-dynamic-card-image-button-title">
                    Interest(APR)
                  </p>
                  <p className="home-dynamic-card-image-button-subtitle">
                    {eligibleInterestStore}%
                  </p>
                </div>
                <div className="home-dynamic-card-image-button">
                  <p className="home-dynamic-card-image-button-title">EMI</p>
                  <p className="home-dynamic-card-image-button-subtitle">
                    ₹{Math.round(EMIDaily)}
                  </p>
                </div>
              </div>
            </div>
          )}

          {loanStatusCard === "LOANREJECTED" && (
            <div style={{ marginTop: 30, width: "85%" }}>
              <div className="home-dynamic-card-image-container reverse">
                <div className="home-dynamic-card-left-side">
                  <h2 className="home-dynamic-card-left-side-title noteligibleTitle">
                    Your Loan has been Rejected
                  </h2>
                  <p className="home-dynamic-card-left-side-subtitle">
                    Sorry, we could not approve at this time.
                  </p>
                  <p className="home-dynamic-card-left-side-subtitle">
                    Please try after 30 days
                  </p>
                </div>
                <div className="home-dynamic-card-image">
                  <div
                    style={{
                      width: "100%",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <img
                      src={
                        "https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/sad.png"
                      }
                      className="home-dynamic-card-image-style"
                    />
                  </div>
                  <div className="home-dynamic-card-progress-container">
                    <ProgressBar
                      bgcolor={"#E32f45"}
                      completed={100}
                      bgcolorContainer={"#dbdbdb"}
                    />
                    <div className="home-dynamic-card-progress-wrapper">
                      <p className="home-dynamic-card-progress-text">
                        Intiated
                      </p>
                      <p className="home-dynamic-card-progress-text">
                        Kyc Pending
                      </p>
                      <p className="home-dynamic-card-progress-text">
                        Verification
                      </p>
                      <p className="home-dynamic-card-progress-text">
                        Loan Rejected
                      </p>
                    </div>
                  </div>

                  {/* <svg
          width="48"
          viewBox="0 -960 960 960"
          height="48"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m393-165 279-335H492l36-286-253 366h154l-36 255Zm-73 85 40-280H160l360-520h80l-40 320h240L400-80h-80Zm153-395Z"></path>
        </svg> */}
                </div>
              </div>
            </div>
          )}

          {loanStatusCard === "KYCPENDING" && (
            <div style={{ marginTop: 30, width: "85%" }}>
              <div className="home-dynamic-card-image-container reverse">
                <div className="home-dynamic-card-left-side">
                  <h2 className="home-dynamic-card-left-side-title">
                    Your loan documents is under verification
                  </h2>
                  <p className="home-dynamic-card-left-side-subtitle">
                    Our agent will reach out to you shortly.
                  </p>
                </div>
                <div className="home-dynamic-card-image">
                  <div
                    style={{
                      width: "100%",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <img
                      src={
                        "https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/neutral.png"
                      }
                      className="home-dynamic-card-image-style"
                    />
                  </div>
                  <div className="home-dynamic-card-progress-container">
                    <ProgressBar
                      bgcolor={"#e32f45"}
                      completed={62}
                      bgcolorContainer={"#dbdbdb"}
                    />
                    <div className="home-dynamic-card-progress-wrapper">
                      <p className="home-dynamic-card-progress-text">
                        Initiated
                      </p>
                      <p className="home-dynamic-card-progress-text">
                        Kyc Pending
                      </p>
                      <p className="home-dynamic-card-progress-text">
                        Verification
                      </p>
                      <p className="home-dynamic-card-progress-text">
                        Loan Disbursed
                      </p>
                    </div>
                  </div>

                  {/* <svg
          width="48"
          viewBox="0 -960 960 960"
          height="48"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m393-165 279-335H492l36-286-253 366h154l-36 255Zm-73 85 40-280H160l360-520h80l-40 320h240L400-80h-80Zm153-395Z"></path>
        </svg> */}
                </div>
              </div>
            </div>
          )}

          <div className="loan-details-buttons-container">
            {loanStatusCard == "CLOSED" && (
              <a
                className="loan-details-button-noc"
                href={nocLinkData}
                download="NOC.pdf"
              >
                <Download className="loan-details-button-noc-icon" /> NOC
              </a>
            )}
            {loanStatusCard === "INPROGRESS" && agreementLinkData.length > 2 ? (
              <a
                className="loan-details-button-noc"
                href={agreementLinkData}
                download="agreement.pdf"
              >
                Agreement
              </a>
            ) : null}
            {duesChargesArrayList.length != 0 && (
              <button
                className="loan-details-button-due-charges"
                onClick={handleduechargesmodalopen}
              >
                <Info className="loan-details-button-due-charges-icon" />
                Due charges
              </button>
            )}
          </div>

          {loanStatusCard == "INPROGRESS" || loanStatusCard == "CLOSED" ? (
            <>
              <div className="loan-details-tab-container">
                <input
                  type="radio"
                  name="tab"
                  id="tab1"
                  className="loan-details-tab loan-details-tab--1"
                  onClick={() => changeTab(0)}
                />
                <label className="loan-details-tab_label" for="tab1">
                  Upcoming Payments
                </label>

                <input
                  type="radio"
                  name="tab"
                  id="tab2"
                  className="loan-details-tab loan-details-tab--2"
                  onClick={() => changeTab(1)}
                />
                <label className="loan-details-tab_label" for="tab2">
                  Past Payments
                </label>

                <div className="loan-details-tab-indicator"></div>
              </div>

              {activeTab === 0 && (
                <div className="loan-details-tab-content">
                  {loanStatusCard == "INPROGRESS" ||
                  loanStatusCard == "PENDINGAMOUNT" ? (
                    <>
                      {upcomingPayments.length > 0 ? (
                        <Accordion>
                          {upcomingPayments.map((payment, index) => (
                            <Accordion.Item
                              key={index}
                              className="p-0 m-1 mt-3 "
                              eventKey={index}
                            >
                              <Accordion.Header color="grey">
                                <div className="loan-details-tab-content-card">
                                  <div className="loan-details-tab-content-img-container">
                                    <div className="loan-details-tab-content-img-wrapper">
                                      <img
                                        className="loan-details-tab-content-img-style"
                                        src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/transaction.png"
                                      />
                                    </div>
                                    <div className="loan-details-tab-content-paid-wrapper">
                                      <p className="loan-details-tab-content-paid-title">
                                        {payment.paymentDate}
                                      </p>
                                      <p className="loan-details-tab-content-paid-subtitle">
                                        {payment.date}{" "}
                                        {/* Assuming you have a date property in your payment object */}
                                      </p>
                                    </div>
                                  </div>
                                  <p className="loan-details-tab-content-amount">
                                    ₹{Math.round(payment.emi)}
                                  </p>
                                </div>
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="loan-details-tab-Body-container-cover">
                                  <div className="loan-details-tab-Body-container">
                                    <div className="loan-details-tab-Body-wrapper">
                                      <p className="loan-details-tab-Body-key">
                                        Principle Amount
                                      </p>
                                      <p className="loan-details-tab-Body-value">
                                        ₹{Math.round(payment.currPrincipal)}
                                      </p>
                                    </div>
                                    <div className="loan-details-tab-Body-wrapper">
                                      <p className="loan-details-tab-Body-key">
                                        Interest Amount
                                      </p>
                                      <p className="loan-details-tab-Body-value">
                                        ₹{Math.round(payment.interest)}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="loan-details-tab-Body-container">
                                    <div className="loan-details-tab-Body-wrapper">
                                      <p className="loan-details-tab-Body-key">
                                        EMI Amount
                                      </p>
                                      <p className="loan-details-tab-Body-value">
                                        ₹{Math.round(payment.emi)}
                                      </p>
                                    </div>
                                    <div className="loan-details-tab-Body-wrapper">
                                      <p className="loan-details-tab-Body-key">
                                        Pending Amount
                                      </p>
                                      <p className="loan-details-tab-Body-value">
                                        ₹{Math.round(payment.remainingAmount)}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          ))}
                        </Accordion>
                      ) : (
                        <img
                          src={
                            "https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/No-data.png"
                          }
                          alt="No loans found"
                          className="nothing-img"
                          style={{
                            display: "flex",
                            position: "relative",
                            left: "50%",
                            transform: "translate(-50%)",
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <img
                      src={
                        "https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/No-data.png"
                      }
                      alt="No loans found"
                      className="nothing-img"
                      style={{
                        display: "flex",
                        position: "relative",
                        left: "50%",
                        transform: "translate(-50%)",
                      }}
                    />
                  )}
                </div>
              )}
              {activeTab === 1 && (
                <div className="loan-details-tab-content">
                  {paymentList.length === 0 ? (
                    <img
                      src={
                        "https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/No-data.png"
                      }
                      alt="No loans found"
                      className="nothing-img"
                      style={{
                        display: "flex",
                        position: "relative",
                        left: "50%",
                        transform: "translate(-50%)",
                      }}
                    />
                  ) : (
                    <Accordion>
                      {paymentList.map((item, index) => (
                        <Accordion.Item
                          key={index}
                          className="p-0 m-1 mt-3 "
                          eventKey={index}
                        >
                          <Accordion.Header color="grey">
                            <div className="loan-details-tab-content-card">
                              <div className="loan-details-tab-content-img-container">
                                <div className="loan-details-tab-content-img-wrapper">
                                  <img
                                    className="loan-details-tab-content-img-style"
                                    src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/transaction.png"
                                  />
                                </div>
                                <div className="loan-details-tab-content-paid-wrapper">
                                  <p className="loan-details-tab-content-paid-title">
                                    {item.paymentType}
                                  </p>
                                  <p className="loan-details-tab-content-paid-subtitle">
                                    {formatDate(item.date)}
                                    {/* Assuming you have a date property in your payment object */}
                                  </p>
                                </div>
                              </div>
                              <p className="loan-details-tab-content-amount">
                                ₹{Math.round(item.amt) || 0}
                              </p>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="loan-details-tab-Body-container-cover">
                              <div className="loan-details-tab-Body-container-past">
                                <p className="loan-details-tab-Body-key-past">
                                  Principle Amount
                                </p>
                                <p className="loan-details-tab-Body-value-past">
                                  ₹{Math.round(item.principal)}
                                </p>
                              </div>
                              <div className="loan-details-tab-Body-container-past">
                                <p className="loan-details-tab-Body-key-past">
                                  Interest Amount
                                </p>
                                <p className="loan-details-tab-Body-value-past">
                                  ₹{Math.round(item.interest)}
                                </p>
                              </div>
                            </div>
                            {Number(item.dues) > 0 && (
                              <div
                                className="loan-details-tab-Body-container-cover"
                                style={{ marginTop: 15 }}
                              >
                                <div className="loan-details-tab-Body-container-past">
                                  <p className="loan-details-tab-Body-key-past">
                                    Dues
                                  </p>
                                  <p className="loan-details-tab-Body-value-past">
                                    ₹{Math.round(item.dues)}
                                  </p>
                                </div>
                              </div>
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  )}
                </div>
              )}
            </>
          ) : null}

          {loanStatusCard == "REJECTED" || loanStatusCard == "KYCPENDING" ? (
            <>
              <h2 style={{ marginTop: 60 }}>Frequently Asked Questions</h2>
              <div className="loan-details-tab-content">
                <Accordion>
                  {faqItems.map((item, index) => (
                    <Accordion.Item
                      key={index}
                      className="p-0 m-1 mt-3 "
                      eventKey={index}
                    >
                      <Accordion.Header color="grey">
                        <div className="loan-details-tab-content-card">
                          {item.question}
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="loan-details-tab-Body-container-cover">
                          <p>{item.answer}</p>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            </>
          ) : null}
          <Modal
            isOpen={dueChargesModalOpen}
            onClose={handleduechargesmodalclose}
            subTitleRequired={true}
            modalTitle={"Due Charges"}
            modalSubTitle={"Dues will be added to your final EMI payment"}
            modalwidth={"50%"}
            savebtn={false}
            closebtn={true}
          >
            <div
              className="loan-details-duecharges-table-responsive"
              style={{ marginTop: 10 }}
            >
              <table className="loan-details-duecharges-table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {duesChargesArrayList.length > 0 ? (
                    duesChargesArrayList.map((charge, index) => (
                      <tr key={index}>
                        <td>{convertDDMMYYYYFormat(new Date(charge.date).toLocaleDateString())}</td>
                        <td>{convertduetype(charge.type)}</td>
                        <td className="d-flex gap-2">
                          <div style={{ minWidth: "40px" }}>{charge.amt}</div>
                          {charge.paidFlag === "yes" && (
                            <div>
                              <Check color="green" size={16} />
                            </div>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5">No due charges found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Modal>

          <Modal
            isOpen={settlementModalOpen}
            onClose={handlesettlementmodalclose}
            modalTitle={"Pay Now"}
            modalwidth={"70%"}
            savebtn={false}
            closebtn={true}
            modalref={drpRef}
          >
            <h4 style={{ marginTop: "20px", textAlign: "center" }}>
              Select Payment Type
            </h4>
            <div className="loan-label-wrapper">
              {showLatePayment && (
                <label className="preview-radio-label">
                  <input
                    type="radio"
                    name="loan"
                    value="LATE"
                    checked={selectedOption === "LATE"}
                    className="loan-radio-input"
                    onChange={handleOptionChange}
                  />
                  <div className="loan-details-payment-type-font">
                    Late Payment
                  </div>
                </label>
              )}

              <label className="preview-radio-label">
                <input
                  type="radio"
                  name="loan"
                  value="PARTIAL"
                  checked={selectedOption === "PARTIAL"}
                  className="loan-radio-input"
                  onChange={handleOptionChange}
                />
                <div className="loan-details-payment-type-font">
                  Partial Payment
                </div>
              </label>

              <label className="preview-radio-label">
                <input
                  type="radio"
                  name="loan"
                  value="FULL"
                  checked={selectedOption === "FULL"}
                  className="loan-radio-input"
                  onChange={handleOptionChange}
                />
                <div className="loan-details-payment-type-font">
                  Full Payment
                </div>
              </label>
            </div>

            {selectedOption == "PARTIAL" && (
              <div className="paymentselectcontainer">
                <div className="paymentselectwrapper">
                  <div className="paymentselectinner">
                    <div className="loan-details-label-wrapper">
                      <label>Outstanding Loan</label>
                    </div>
                    <div className="loan-details-label-wrapper2">
                      <InputField
                        placeholder="Enter Amount"
                        maxLength={25}
                        keyboardType={"number-pad"}
                        value={unpaidChargesValue}
                        inputType={""}
                        editable={false}
                        leftIcon={
                          <CurrencyRupee style={{ animation: "none" }} />
                        }
                      />
                    </div>
                  </div>
                  <div className="paymentselectinner">
                    <div className="loan-details-label-wrapper">
                      <label>Enter Partial Amount</label>
                    </div>
                    <div className="loan-details-label-wrapper2">
                      <InputField
                        placeholder="Enter Amount"
                        maxLength={25}
                        keyboardType={"number-pad"}
                        value={paymentPartialAmount}
                        inputType={""}
                        onChangeText={(e) => {
                          // Remove non-numeric characters and limit to 10 digits
                          let numericValue = e
                            .replace(/[^0-9]/g, "")
                            .slice(0, 10);

                          const maxAllowedAmount = unpaidChargesValue * 0.9;
                          // Convert the numeric value to a number and compare to unpaidChargesValue
                          const numericAmount = Math.min(
                            Number(numericValue),
                            Number(maxAllowedAmount)
                          );

                          // Update the state with the minimum value
                          setPaymentPartialAmount(Math.round(numericAmount));

                          if (numericAmount < 5000) {
                            setpartialError(
                              "Please enter a partial amount of 5000 or more"
                            );
                          } else {
                            setpartialError("");
                          }
                          if (numericValue === "") {
                            setPaymentPartialAmount("");
                          }
                        }}
                        leftIcon={
                          <CurrencyRupee style={{ animation: "none" }} />
                        }
                        validationError={partialError}
                      />
                    </div>
                  </div>
                  <div className="paymentselectinner mt-4">
                    <div className="loan-details-label-wrapper">
                      <label>Payment Options</label>
                    </div>
                    <div className="paymenttable">
                      <div className="paymentinnertable">
                        {options.map((option) => (
                          <Paymentoptions
                            key={option.id}
                            icon={option.icon}
                            label={option.label}
                            selected={
                              paymentOption.selectedpaymentOption === option.id
                            }
                            onClick={() => {
                              setPaymentOption({
                                selectedpaymentOption: option.id,
                                selectedpaymentType: selectedOption,
                              });
                            }}
                          />
                        ))}
                      </div>
                    </div>
                    {/* <div
                    className="loan-details-label-wrapper cardsraio-wrapper"
                    style={{ border: "0px solid" }}
                  >
                     <Dropdown
                    ref={drpRef}
                    options={FullPaymentOption}
                    leftIcon={<Bank style={{ color: "grey" }} />}
                    onSelect={(e) =>
                      setPaymentOption({
                        selectedpaymentOption: e,
                        selectedpaymentType: selectedOption,
                      })
                    }
                    drpdownWidth={"100%"}
                    drpdownHeight={"50px"}
                    placeHolder={"Select Option"}
                    value={paymentOption.selectedpaymentOption}
                    editable={true}
                    setOpenDropdownCheck={handleDropdownFunctionPartial}
                    // validationError={validationErrors.userMaritalStatus}
                  /> 
                  
                  </div> */}
                </div>
               
               <div className="d-flex justify-content-center gap-5 mt-3">
                <div style={{textAlign:"right",width:"40%"}}>
                  payment Date:
                </div>
                <div style={{width:"50%"}}>
                  <input  type="date"
                 onChange={
                  (e)=>{
                    setPaymentdate(e.target.value);
                    console.log(e.target.value)
                    let date=e.target.value
                    let datesplit=date.split("-")

                    getSettlementsApi(datesplit[2],datesplit[1],datesplit[0])
                  }}
                  /></div>
               </div>
                {paymentPartialAmount > 0 && !partialError.length>0 &&
               ( paymentOption.selectedpaymentOption === "netbanking" ||  paymentOption.selectedpaymentOption === "upi") ? (
                  <div className="paymentbtnwrapper"
                  >
                    <div style={{display:"flex",justifyContent:"center",flexDirection:"column",gap:"0.3rem"}}>
                    <p style={{fontSize:"13px"}}>Processing Charges: {paymentcharges.partial}</p>
                    <button
                      className="loanpaybtn"
                      onClick={() => {
                        LoanPayNow(Number(paymentPartialAmount) + Number(paymentcharges.partial));
                      }}
                      disabled={paynowbtn}
                    >
                      <div>Pay</div>
                      <div>₹ {Number(paymentPartialAmount) + Number(paymentcharges.partial)}</div>
                    </button>
                    </div>
                    {/* <div className="paynowcontainer">
                      <div
                        onClick={() => {
                          setpayShow(!payshow);
                        }}
                      >
                        <InfoCircle />
                      </div>

                      {payshow && (
                        <div className="chargescard" ref={chargesRef}>
                          <div >
                            <div className="chargercardinner">
                              <div style={{ width: "50%", textAlign: "right" }}>
                                <span>Paying Amount:</span>
                              </div>
                              <span>{paymentPartialAmount}</span>
                            </div>
                            <div className="chargercardinner">
                              <div style={{ width: "48%", textAlign: "right" }}>
                                <span>Charges:</span>
                              </div>
                              <span>{paymentcharges}</span>
                            </div>
                            <hr />
                            <div className="chargercardinner">
                              <div style={{ width: "48%", textAlign: "right" }}>
                                <span>Total:</span>
                              </div>
                              <span>{paymentPartialAmount + parseInt(paymentcharges)}</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div> */}
                    </div>
                  ) : null}
                </div>
              </div>
            )}

            {selectedOption == "LATE" && (
              <>
                <div className="paymentselectcontainer">
                  <div className="paymentselectwrapper">
                    <div className="paymentselectinner">
                      <div className="loan-details-label-wrapper">
                        <label>Outstanding Loan</label>
                      </div>
                      <div className="loan-details-label-wrapper2">
                        <InputField
                          placeholder="Enter Amount"
                          maxLength={25}
                          keyboardType={"number-pad"}
                          value={unpaidChargesValue}
                          inputType={""}
                          editable={false}
                          leftIcon={
                            <CurrencyRupee style={{ animation: "none" }} />
                          }
                        />
                      </div>
                    </div>
                    <div className="paymentselectinner">
                      <div className="loan-details-label-wrapper">
                        <label>Late EMI Amount</label>
                      </div>
                      <div className="loan-details-label-wrapper2">
                        <InputField
                          placeholder="Enter Amount"
                          maxLength={25}
                          keyboardType={"number-pad"}
                          value={Number(EMIDaily)}
                          inputType={""}
                          editable={false}
                          leftIcon={
                            <CurrencyRupee style={{ animation: "none" }} />
                          }
                        />
                      </div>
                    </div>

                    <div className="paymentselectinner">
                      <div className="loan-details-label-wrapper">
                        <label>Payment Options</label>
                      </div>
                      <div className="paymenttable">
                        <div className="paymentinnertable">
                          {options.map((option) => (
                            <Paymentoptions
                              key={option.id}
                              icon={option.icon}
                              label={option.label}
                              selected={
                                paymentOption.selectedpaymentOption ===
                                option.id
                              }
                              onClick={() => {
                                setPaymentOption({
                                  selectedpaymentOption: option.id,
                                  selectedpaymentType: selectedOption,
                                });
                              }}
                            />
                          ))}
                        </div>
                      </div>
                    </div>

                <div className="d-flex justify-content-center gap-5 mt-3">
                <div style={{textAlign:"right",width:"40%"}}>
                  payment Date:
                </div>
                <div style={{width:"50%"}}>
                  <input  type="date" 
                   onChange={
                    (e)=>{
                      setPaymentdate(e.target.value);
                      console.log(e.target.value)
                      let date=e.target.value
                      let datesplit=date.split("-")
  
                      getSettlementsApi(datesplit[2],datesplit[1],datesplit[0])
                    }}
                  /></div>
               </div>
               
                {paymentLateAmount &&( paymentOption.selectedpaymentOption === "netbanking" ||  paymentOption.selectedpaymentOption === "upi")  ? (
                
                   <div className="paymentbtnwrapper"
                   >
                     <div style={{display:"flex",justifyContent:"center",flexDirection:"column",gap:"0.3rem"}}>
                     <p style={{fontSize:"13px"}}>Processing Charges: {paymentcharges.late}</p>
                     <button
                       className="loanpaybtn"
                       onClick={() => {
                         LoanPayNow(Number(EMIDaily) + Number(paymentcharges.late));
                       }}
                       disabled={paynowbtn}
                     >
                       <div>Pay</div>
                       <div>₹ {Number(EMIDaily) + Number(paymentcharges.late)}</div>
                     </button>
                     </div>
                     {/* <div className="paynowcontainer">
                       <div
                         onClick={() => {
                           setpayShow(!payshow);
                         }}
                       >
                         <InfoCircle />
                       </div>
 
                       {payshow && (
                         <div className="chargescard" ref={chargesRef}>
                           <div >
                             <div className="chargercardinner">
                               <div style={{ width: "50%", textAlign: "right" }}>
                                 <span>Paying Amount:</span>
                               </div>
                               <span>{paymentPartialAmount}</span>
                             </div>
                             <div className="chargercardinner">
                               <div style={{ width: "48%", textAlign: "right" }}>
                                 <span>Charges:</span>
                               </div>
                               <span>{paymentcharges}</span>
                             </div>
                             <hr />
                             <div className="chargercardinner">
                               <div style={{ width: "48%", textAlign: "right" }}>
                                 <span>Total:</span>
                               </div>
                               <span>{paymentPartialAmount + parseInt(paymentcharges)}</span>
                             </div>
                           </div>
                         </div>
                       )}
                     </div> */}
                      </div>
                    ) : null}
                  </div>
                </div>

                {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 20,
                  }}
                >
                  <div className="loan-details-label-wrapper">
                    <label>Outstanding Loan</label>
                  </div>
                  <div className="loan-details-label-wrapper">
                    ₹{unpaidChargesValue}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="loan-details-label-wrapper">
                    <label>Late EMI Amount</label>
                  </div>
                  <div className="loan-details-label-wrapper">
                    ₹{Number(EMIDaily) + 500}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginTop: "1rem",
                  }}
                >
                  <div className="loan-details-label-wrapper">
                    <label>Select Payment Option</label>
                  </div>
                  <div
                    className="loan-details-label-wrapper cardsraio-wrapper"
                    style={{ border: "0px solid" }}
                  >
                   
                    <div className="text-center">
                      <CardsRadio
                        img={"./assets/images/UPI2.jpg"}
                        value="Net Banking / UPI (charges added)"
                        onChange={(e) => {
                          setPaymentOption({
                            selectedpaymentOption: e.target.value,
                            selectedpaymentType: selectedOption,
                          });
                        }}
                        selectedValue={paymentOption.selectedpaymentOption}
                      />
                      <span style={{ fontSize: "14px" }}>
                        {" "}
                        Net Banking / UPI (charges added)
                      </span>
                    </div>
                    <div className="text-center">
                      <CardsRadio
                        img={"./assets/images/NEFT2.jpg"}
                        value="Direct Payment"
                        onChange={(e) => {
                          setPaymentOption({
                            selectedpaymentOption: e.target.value,
                            selectedpaymentType: selectedOption,
                          });

                          setTimeout(() => {
                            scrollmodaldown();
                          }, 200);
                        }}
                        selectedValue={paymentOption.selectedpaymentOption}
                      />
                      <span style={{ fontSize: "14px" }}>Direct Payment</span>
                    </div>
                  </div>
                </div>
                {paymentLateAmount ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div
                      style={{
                        backgroundColor: "#E32f45",
                        color: "#fff",
                        padding: "8px 15px",
                        marginTop: "30px",
                        minWidth: 120,
                        borderRadius: 20,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        gap: 5,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        LoanPayNow(Number(EMIDaily) + 500);
                      }}
                    >
                      <div>Pay</div>
                      <div>₹ {Number(EMIDaily) + 500}</div>
                    </div>
                  </div>
                ) : null} */}
              </>
            )}

            {selectedOption == "FULL" && (
              <div className="paymentselectcontainer">
                <div className="paymentselectwrapper">
                  <div className="paymentselectinner">
                    <div className="loan-details-label-wrapper">
                      <label>Settlement Amount</label>
                    </div>
                    <div className="loan-details-label-wrapper2">
                      <InputField
                        placeholder="Enter Amount"
                        maxLength={25}
                        keyboardType={"number-pad"}
                        value={settlementAmountStore}
                        inputType={""}
                        editable={false}
                        leftIcon={
                          <CurrencyRupee style={{ animation: "none" }} />
                        }
                      />
                      {checkDate(paymentDate) && (
                        <span
                          style={{
                            fontSize: "13px",
                            paddingLeft: "15px",
                            color: "#e32f45",
                          }}
                        >
                          * Your payments might include early payment charges
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="paymentselectinner">
                    <div className="loan-details-label-wrapper">
                      <label>Payment Options</label>
                    </div>
                    <div className="paymenttable">
                      <div className="paymentinnertable">
                        {options.map((option) => (
                          <Paymentoptions
                            key={option.id}
                            icon={option.icon}
                            label={option.label}
                            selected={
                              paymentOption.selectedpaymentOption === option.id
                            }
                            onClick={() => {
                              setPaymentOption({
                                selectedpaymentOption: option.id,
                                selectedpaymentType: selectedOption,
                              });
                            }}
                          />
                        ))}
                      </div>
                    </div>
                    {/* <div
                    className="loan-details-label-wrapper cardsraio-wrapper"
                    style={{ border: "0px solid" }}
                  >
                     <Dropdown
                    ref={drpRef}
                    options={FullPaymentOption}
                    leftIcon={<Bank style={{ color: "grey" }} />}
                    onSelect={(e) =>
                      setPaymentOption({
                        selectedpaymentOption: e,
                        selectedpaymentType: selectedOption,
                      })
                    }
                    drpdownWidth={"100%"}
                    drpdownHeight={"50px"}
                    placeHolder={"Select Option"}
                    value={paymentOption.selectedpaymentOption}
                    editable={true}
                    setOpenDropdownCheck={handleDropdownFunctionPartial}
                    // validationError={validationErrors.userMaritalStatus}
                  /> 
                  
                  </div> */}
                  </div>

                <div className="d-flex justify-content-center gap-5 mt-3">
                <div style={{textAlign:"right",width:"40%"}}>
                  payment Date:
                </div>
                <div style={{width:"50%"}}><input  type="date" 
                  onChange={
                    (e)=>{
                      setPaymentdate(e.target.value);
                      console.log(e.target.value)
                      let date=e.target.value
                      let datesplit=date.split("-")

                      getSettlementsApi(datesplit[2],datesplit[1],datesplit[0])
                    }}     
                    value={paymentDate}        
                    /></div>
               </div>
               
                {settlementAmountStore>0 &&
                ( paymentOption.selectedpaymentOption === "netbanking" ||  paymentOption.selectedpaymentOption === "upi")? (
                  <div className="paymentbtnwrapper"
                  >
                    <div style={{display:"flex",justifyContent:"center",flexDirection:"column",gap:"0.3rem"}}>
                    <p style={{fontSize:"13px"}}>Processing Charges: {paymentcharges.full}</p>
                    <button
                      className="loanpaybtn"
                      onClick={() => {
                        LoanPayNow(parseInt(settlementAmountStore) + paymentcharges.full);
                      }}
                      disabled={paynowbtn}
                    >
                      <div>Pay</div>
                      <div>₹ {parseInt(settlementAmountStore) + Number(paymentcharges.full)}</div>
                    </button>
                    </div>
                    {/* <div className="paynowcontainer">
                      <div
                        onClick={() => {
                          setpayShow(!payshow);
                        }}
                      >
                        <InfoCircle />
                      </div>

                      {payshow && (
                        <div className="chargescard" ref={chargesRef}>
                          <div >
                            <div className="chargercardinner">
                              <div style={{ width: "50%", textAlign: "right" }}>
                                <span>Paying Amount:</span>
                              </div>
                              <span>{paymentPartialAmount}</span>
                            </div>
                            <div className="chargercardinner">
                              <div style={{ width: "48%", textAlign: "right" }}>
                                <span>Charges:</span>
                              </div>
                              <span>{paymentcharges}</span>
                            </div>
                            <hr />
                            <div className="chargercardinner">
                              <div style={{ width: "48%", textAlign: "right" }}>
                                <span>Total:</span>
                              </div>
                              <span>{paymentPartialAmount + parseInt(paymentcharges)}</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div> */}
                    </div>
                  ) : null}
                </div>
              </div>
            )}

            {(selectedOption === "FULL" ||
              selectedOption === "PARTIAL" ||
              selectedOption === "LATE") &&
            paymentOption.selectedpaymentOption === "Direct Payment" ? (
              <>
                <h4 style={{ marginTop: "40px", textAlign: "center" }}>
                  Account Details
                </h4>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: 20,
                    marginTop: 20,
                  }}
                >
                  <table className="directpaytable">
                    <tbody>
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            padding: "10px",
                            fontWeight: "bold",
                            backgroundColor: "#e3e3e3",
                            color: "#2e2e2e",
                            border: "1px solid #e3e3e3",
                          }}
                        >
                          Bank Account Number
                        </td>
                        <td
                          style={{
                            width: "50%",
                            padding: "10px",
                            backgroundColor: "white",
                            border: "1px solid #e3e3e3",
                          }}
                        >
                          <span
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span style={{ marginLeft: "10px" }}>
                              {bankAccount}
                            </span>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            padding: "10px",
                            fontWeight: "bold",
                            backgroundColor: "#e3e3e3",
                            color: "#2e2e2e",
                            border: "1px solid #e3e3e3",
                          }}
                        >
                          Bank IFSC
                        </td>
                        <td
                          style={{
                            width: "50%",
                            padding: "10px",
                            backgroundColor: "white",
                            border: "1px solid #e3e3e3",
                          }}
                        >
                          <span
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span style={{ marginLeft: "10px" }}>
                              {bankIFSC}
                            </span>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            padding: "10px",
                            fontWeight: "bold",
                            backgroundColor: "#e3e3e3",
                            color: "#2e2e2e",
                            border: "1px solid #e3e3e3",
                          }}
                        >
                          Remarks/Notes
                        </td>
                        <td
                          style={{
                            width: "50%",
                            padding: "10px",
                            backgroundColor: "white",
                            border: "1px solid #e3e3e3",
                          }}
                        >
                          <span
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span style={{ marginLeft: "10px" }}>
                              {remarksNotes}
                            </span>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {/* {showSettlementAmount === false &&
              selectedOption === "Full Payment" ? (
                <>
                  <div>
                    <label>
                      Day:
                      <select value={selectedDay} onChange={handleDayChange}>
                        <option value="">Day</option>
                        {settlementdays.map((day) => (
                          <option key={day} value={day}>
                            {day}
                          </option>
                        ))}
                      </select>
                    </label>
                    <label>
                      Month:
                      <select
                        value={selectedMonth}
                        onChange={handleMonthChange}
                      >
                        <option value="">Month</option>
                        {settlementmonths.map((month) => (
                          <option key={month} value={month}>
                            {month}
                          </option>
                        ))}
                      </select>
                    </label>
                    <label>
                      Year:
                      <select value={selectedYear} onChange={handleYearChange}>
                        <option value="">Year</option>
                        {settlementyears.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </label>
                    <div
                      className="loan-details-get-settlement"
                      onClick={() => {
                        getSettlementsApi();
                      }}
                    >
                      Get Settlement Amount
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <label>Settlement Amount</label>
                  <InputField
                    placeholder="Settlement Amount"
                    maxLength={25}
                    value={settlementAmountStore}
                    editable={false}
                    leftIcon={<Bank style={{ animation: "none" }} />}
                  />
                </>
              )} */}
              </>
            ) : null}
            <form id="formPay" ref={formPayRef} style={{ display: "none" }}>
              <input type="hidden" id="EncData" name="EncData" />
              <input type="hidden" id="MerchantId" name="MerchantId" />
              <input type="hidden" id="BankId" name="BankId" />
              <input type="hidden" id="TerminalId" name="TerminalId" />
            </form>
          </Modal>
          <Modal
            isOpen={mandateOpen}
            onClose={handlemandateclose}
            modalTitle={"Change Bank Account"}
            modalwidth={"70%"}
            savebtn={true}
            closebtn={true}
            submitButtonPlaceHolder="Change Bank"
            savebtneditable={
              !(
                bankReAccNoVerifyError.length > 0 ||
                !bankNameValue.length > 0 ||
                bankReAccNoValue.length < 10 ||
                changebtn
              )
            }
            onSave={changeBankAccount}
          >
            <div className="paymentselectinner">
              <div className="loan-details-label-wrapper">
                <label>New Bank Account Number</label>
              </div>
              <div className="loan-details-label-wrapper2">
                <InputField
                  placeholder="Enter Bank Account Number"
                  maxLength={25}
                  value={
                    encryptedAccNo.length !== 0
                      ? encryptedAccNo
                      : bankAccNoValue
                  }
                  onBlur={() => encryptAccountNo("account")}
                  onChangeText={(e) => {
                    handleVerifyOnChange(e, "accno");
                  }}
                  onFocusText={() => {
                    setEncryptedAccNo("");
                  }}
                  onCopy={handleCopy}
                  onCut={handleCopy}
                  // editable={false}
                  leftIcon={<Bank style={{ animation: "none" }} />}
                  validationError={bankAccNoVerifyError}
                />
              </div>
            </div>
            <div className="paymentselectinner">
              <div className="loan-details-label-wrapper">
                <label>Re Bank Account Number</label>
              </div>
              <div className="loan-details-label-wrapper2">
                <InputField
                  placeholder="Enter Bank Account Number"
                  maxLength={25}
                  value={
                    encryptedReAccNo.length !== 0
                      ? encryptedReAccNo
                      : bankReAccNoValue
                  }
                  onChangeText={(e) => {
                    handleVerifyOnChange(e, "reaccno");
                  }}
                  onBlur={() => encryptAccountNo()}
                  onFocusText={() => setEncryptedReAccNo("")}
                  onPaste={handleCopy}
                  // editable={false}
                  leftIcon={<Bank style={{ animation: "none" }} />}
                  validationError={bankReAccNoVerifyError}
                />
              </div>
            </div>
            <div className="paymentselectinner">
              <div className="loan-details-label-wrapper">
                <label>New Bank Ifsc</label>
              </div>
              <div className="loan-details-label-wrapper2">
                <InputField
                  placeholder="Enter Bank Ifsc"
                  maxLength={11}
                  value={bankIfscValue}
                  onChangeText={(e) => {
                    handleVerifyOnChange(e, "ifsc");
                  }}
                  // editable={false}
                  leftIcon={<Bank style={{ animation: "none" }} />}
                  validationError={bankIfscVerifyError}
                />
              </div>
            </div>

            <div className="paymentselectinner">
              <div className="loan-details-label-wrapper">
                <label>Bank Name</label>
              </div>
              <div className="loan-details-label-wrapper2">
                {ifscloading ? (
                  <div
                    className="chat-message-loading"
                    style={{
                      width: "40%",
                      paddingBottom: "30px",
                      paddingTop: "10px",
                    }}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                ) : (
                  <InputField
                    placeholder="Enter your account number to show the bank name"
                    maxLength={25}
                    value={bankNameValue}
                    editable={false}
                    leftIcon={<Bank style={{ animation: "none" }} />}
                  />
                )}
              </div>
            </div>
          </Modal>

          {alert.show && (
            <Alert
              message={alert.message}
              type={alert.type}
              show={alert.show}
              duration={alert.duration}
              onClose={() => setAlert({ ...alert, show: false })}
            />
          )}
        </div>
      </>
    )
  );
}

export default LoanDetailsScreen;
