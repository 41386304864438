import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "react-feather";


const SuccessMessage = () => {
    const navigate = useNavigate();
    const message = localStorage.getItem("processType");

    useEffect(() => {
        setTimeout(() => {
            
            localStorage.removeItem("message");
        }, 1000);
    }, []);

    return (
        <div className="MessageScreen-main-wrapper">
            <div className="MessageScreen-main">
                <img className="MessageScreen-status" src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/success.gif" alt="success" />
                <div className="MessageScreen-text wow fadeInUp">
                    <h1>SUCCESS!</h1>
                    {message ==="bankverify" && <p>Bank verified. You should receive loan shortly.</p>}
                    {message ==="changebank" && <p>Bank account updated.</p>}
                    {message ==="paymentgateway" && <p>Payment completed.</p>}
                    <div className="MessageScreen-button-wrapper">
                        <button className="MessageScreen-button" onClick={() => {navigate("/loanDetails");localStorage.removeItem("message");localStorage.removeItem("processType")}}><ArrowLeft /> Back to Loan</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SuccessMessage;