import React, { useEffect, useRef, useState } from "react";
import "../styles/componentStyles/otpInput.css"; // Import the CSS file

function OtpInput({ sendotp, reset, editable = false }) {
  const inputref = useRef(null);
  const [inputvalue, setinputvalue] = useState("");

  const [inputfocus, setfocus] = useState(true);

  const numberregex = /^[0-9]*$/;

  const [otpValue, setOtpValue] = useState(Array(6).fill("")); // Initialize an array of 6 empty strings

  const handlechange = (e) => {
    let value = e.target.value;
    if ((value.length < 7 && numberregex.test(value)) || value === "") {
      setinputvalue(value);
      let arr = value.split("").concat(Array(6 - value.length).fill(""));
      setOtpValue(arr);
      sendotp(value);
    }
  };
  useEffect(() => {
    if (reset) {
      setinputvalue("");
      setOtpValue(Array(6).fill(""));
    }
  }, [reset]);

  return (
    <>
      <div className="otp-wrapper" onClick={() => inputref.current.focus()}>
        {otpValue.map((digit, index) => (
          <div
            key={index}
            className={`otp-box ${((inputvalue.length === index  && inputfocus ) || (inputvalue.length===6 && index +1 ===inputvalue.length)) && "otpactive"}`}
          >
            {digit}
          </div>
        ))}
      </div>
      <input
        id="email-Otp-Input"
        value={inputvalue}
        onFocus={() => {setfocus(true)}}
        onBlur={() => {setfocus(false)}}
        maxLength={6}
        onChange={handlechange}
        ref={inputref}
        className="otp-dummy-input"
        disabled={!editable}
        autoComplete={false}
        
      />
    </>
  );
}

export default OtpInput;
