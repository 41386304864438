import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "../../styles/screenStyles/WorkInfo.css";
import Dropdown from "../../components/DropDown";
import FileUpload from "../../components/FileUpload";
import InputField from "../../components/InputField";
import {
  Calendar,
  Check,
  Edit,
  MapPin,
  Plus,
  Search,
  XCircle,
} from "react-feather";
import {
  Cash,
  PersonWorkspace,
} from "react-bootstrap-icons";
import DateTimePicker from "../../components/DateTimePicker";
import Modal from "../../components/Modal";
import OtpInput from "../../components/OtpInput";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet"; // Import Leaflet
import Alert from "../../components/Alert";
import { MyContext } from "../../components/GlobalValues";
import Loading from "../../components/Loading";
import ModalAddress from "../../components/ModalAddress";
import { validationRegex } from "../../utlis/Regex";
import { useLocation, useNavigate } from "react-router-dom";
import Metrixfunction from "../../components/MetrixFunction";
import AssetDisplay from "../../components/AssetDisplay";
import UpdateLoader from "../../components/updateLoader";

function WorkInfo({
  component,
  componenttype,
  componentdata,
  nextclick,
  onnavigate = () => {},
}) {
  const accountId = localStorage.getItem("accId");
  const apiKey = localStorage.getItem("apiKey");

  const {
    userJoiningLetterPdf,
    setuserJoiningLetterPdf,
    userSalarySlipPdf,
    setSalarySlipPdf,
    userinfo,
    userEmpIdFile,
    setEmpIdFile,
    Url,
    workinfo,
    setworkinfo,
    workverify,
    setworkverify,
    getWorkInfoApi,
    workinfoposition,
    setposition,
    updateworkinfo,
    getFileNameFromUrl,
    metrixserver

  } = useContext(MyContext);
  
  const location=useLocation()


  const [haveotpshow, sethaveotp] = useState(false);
  const [isFocusedDatePicker, setIsFocusedDatePicker] = useState(false);
  const [resetDateTime, setResetDateTime] = useState(true);

  const [showalert, setshowalert] = useState({
    show: false,
    msg: "",
    color: "",
  });

  function removeSlash(port1) {
    let p1 = port1.split("/").join("");

    let np = p1;
    return np;
  }

  const alertmessage = (msg, color) => {
    setshowalert({ ...showalert, show: true, msg: msg, type: color });

    setTimeout(() => {
      setshowalert({ ...showalert, show: false, msg: "", type: "" });
    }, 3000);
  };

  const [loadingscreen, setloading] = useState(true);

  const [verifybtn, setverifybtn] = useState(false);

  const navigate = useNavigate();

  const [otpedit, setotpedit] = useState(true);

  useEffect(() => {
    if (component) {
      if (!(componenttype === "preview" || componenttype === "loanpreview")) {
        getdetails();
      } else {
        setcomponentedit(false);
        setloading(false);
      }
    } else {
      
      getdetails();
    }
  }, []);

  const [showFilterDropdown, setShowFilterDropdown] = useState({
    date: false,
  });

  const filterDropdownRef = useRef({
    date: null,
  });

  const [emailotp, setemailotp] = useState("");

  const [updateLoad,setupdateLoad]=useState(false)


  const [resendotp, setresentotp] = useState(false);

  const [resenttime, setresenttime] = useState(180);

  const [timeInterval, setTimeInterval] = useState(null);

  const [otpModalOpen, setotpModalOpen] = useState(false);


  const closeotpModal = () => {
    setOtpError("");

    setotpModalOpen(false);
    setTimeout(() => {
      setresentotp(true);
    }, 1000);
  };

  const openotpModal = (haveotp) => {
    setOtpError("");
    setotpModalOpen(true);
    !haveotp && setresentotp(false);
  };

  const [isModalOpen, setModalOpen] = useState(false);

  const [updatevalue, setupdatedvalue] = useState("");

     const convertDDMMYYYYFormat = (dateStr) => {
       const [month, day, year] = dateStr.split("/");
       //  console.log(`${day}/${month}/${year}`,"checking date format")
       return `${day}/${month}/${year}`;
     };
    const convertMMDDYYYYFormat = (dateStr) => {
      const [month, day, year] = dateStr.split("/");
      //  console.log(`${month}/${day}/${year}`, "checking date format");
      return `${month}/${day}/${year}`;
    };


  // const openModal = () => setModalOpen(true);
  const openModal = () => {
    setModalOpen(true)
    setAddressValidState((prev) => ({ ...prev,"tempAddress": false }));
    document.body.classList.add("no-scroll");
  };
  // const closeModal = () => setModalOpen(false);
  const closeModal = () => {
    setModalOpen(false);
    setAddressValidState((prev) => ({ ...prev,"tempAddress": true }));
    document.body.classList.remove("no-scroll");
  };

  const modalref = useRef(null);

  // const handleAddAddress = () => {
  //   //console.log("");
  //   closeModal();
  // };

  const [addressValidState, setAddressValidState] = useState({
    tempAddress: false,
    permAddress: false,
  });

  let pageOne = document.getElementsByClassName("pageOne")[0];
  let pageTwo = document.getElementsByClassName("pageTwo")[0];

  const [AddressPageNum, setAddressPageNum] = useState(0);

  const handleaddressvalid = (ObjSchema) => {
    let valid = true;
    let responseObj = {
      isValid: true,
      errorField: "",
      errorKeyvalue: "",
    };
    let errorMessage = "";
    let errorKey = "";

    const keys = Object.keys(ObjSchema);
    let value = "";

    for (let index = 0; index < keys?.length; index++) {
      let key = keys[index];

      const regex = validationRegex[key];

      value = workAddressInputField[key];

      const minCriteria = fieldConfigurations[key];

      if (value === "" || (regex && !regex.test(value))) {
        //console.log("valuecame" ,value)

        valid = false;
        let length;
        length = key === "companyPincode" ? 6 : 2;
        errorMessage =
          value?.length >= length
            ? `Invalid ${minCriteria.label}.`
            : `Please enter valid ${minCriteria.label}`;
        errorKey = key;
        break;
      }
    }

    //console.log("arrayy", valid);
    // emptyFields?.length > 0  && seterrorField(emptyFields[0])

    if (!valid) {
      responseObj.isValid = valid;
      responseObj.errorField = errorMessage;
      responseObj.errorKeyvalue = errorKey;
    }

    return responseObj;
  };

  const handleAllInputsValid = (ObjSchema) => {
    let valid = true;
    let responseObj = {
      isValid: true,
      errorField: "",
      errorKeyvalue: "",
    };
    let errorMessage = "";
    let errorKey = "";

    const keys = Object.keys(ObjSchema);
    let value = "";

    for (let index = 0; index < keys?.length; index++) {
      let key = keys[index];

      const regex = validationRegex[key];

      value = workinfo[key];

      const minCriteria = fieldConfigurations[key];

      if (value === "" || (regex && !regex.test(value))) {
        //console.log("valuecame" ,value)

        valid = false;
        let length;
        length = key === "companyPincode" ? 6 : 2;
        errorMessage =
          value?.length >= length
            ? `Invalid ${minCriteria.label}.`
            : `Please enter valid ${minCriteria.label}`;
        errorKey = key;
        break;
      }
    }

    //console.log("arrayy", valid);
    // emptyFields?.length > 0  && seterrorField(emptyFields[0])

    if (!valid) {
      responseObj.isValid = valid;
      responseObj.errorField = errorMessage;
      responseObj.errorKeyvalue = errorKey;
    }

    return responseObj;
  };

  const handleAddAddress = (addressCategory, pageNum) => {
    let tempAddressObj = {
      companyCity: "",
      companyDistrict: "",
      companyState: "",
      companyCountry: "",
      companyPincode: "",
      companyStreet: "",
      companyLandmark: "",
    };

    if (addressCategory === "company" && pageNum > 0) {
      let resvalidTemp = handleaddressvalid(tempAddressObj);

      //console.log("resvalidTemp",resvalidTemp);

      if (resvalidTemp && resvalidTemp.isValid) {
        closeModal();
        let addressKeysTemp = Object.keys(workAddressInputField);

        addressKeysTemp.forEach((key) => {
          workinfo[key] = workAddressInputField[key];
        });

        setIsLocationFetched(false);
        // setAddressValidState((prev) => ({ ...prev, "tempAddress": true }));
      } else {
        alertmessage("Please fill all the inputs", "danger");
        //console.log("current address validation error =>",resvalidTemp)
        // setAddressValidState((prev) => ({ ...prev, "tempAddress": false }));
      }
    } else {
      setAddressPageNum(1);
      pageOne.style.transform = "translate(-120%)";
      pageTwo.style.transform = "translate(0)";
    }

    // if(pageNum > 0){
    //   handleAddtressInfoall();
    // }

    // setAddressPageNum(1);
    // pageOne.style.transform = "translate(-120%)";
    // pageTwo.style.transform = "translate(0)";

    // closeModal();
  };

  const handleBack = () => {
    setAddressPageNum(0);
    pageOne.style.transform = "translate(0)";
    pageTwo.style.transform = "translate(100%)";
  };

  const [map, setMap] = useState(null);
  const currentmarkerRef = useRef(null);

  const markerRef = useRef(null);

  let timeOut = 2000;

  const handleemailotp = (value) => {
    setemailotp(value);
    if (value.length === 6) {
      handleverifyotp(value);
    }
  };

  const companyType = [
    "Private",
    "Public",
    "Non-Profit",
    "Government",
    "Partnership",
    "Sole Proprietorship",
    "Corporation",
    "Limited Liability Company (LLC)",
    "Cooperative",
    "Franchise",
    "Joint Venture",
  ];

  // let Designation = [
  //   "General Manager",
  //   "Assistant General Manager",
  //   "Senior Manager",
  //   "Manager",
  //   "Assistant Manager",
  //   "Team Leader",
  //   "Supervisor",
  //   "Senior Engineer",
  //   "Engineer",
  //   "Junior Engineer",
  //   "Analyst",
  //   "Senior Analyst",
  //   "Associate",
  //   "Senior Associate",
  //   "Executive",
  //   "Senior Executive",
  //   "Officer",
  //   "Consultant",
  //   "Advisor",
  //   "Intern",
  //   "Trainee",
  // ];

  let Designation = [
    "Intern",
    "Trainee",
    "Junior Associate",
    "Associate",
    "Executive",
    "Analyst",
    "Assistant",
    "Clerk",
    "Coordinator",
    "Support Staff",
    "Customer Service Representative",
    "Sales Executive",
    "Administrative Assistant",
    "Technician",
    "Senior Associate",
    "Senior Executive",
    "Team Lead",
    "Supervisor",
    "Specialist",
    "Consultant",
    "Senior Analyst",
    "Manager",
    "Operations Manager",
    "Project Manager",
    "Product Manager",
    "Account Manager",
    "Business Development Manager",
    "Marketing Manager",
    "Sales Manager",
    "IT Manager",
    "HR Manager",
    "Senior Manager",
    "Director",
    "Senior Director",
    "Vice President (VP)",
    "Assistant Vice President (AVP)",
    "Associate Vice President",
    "General Manager (GM)",
    "Chief Financial Officer (CFO)",
    "Chief Marketing Officer (CMO)",
    "Chief Technology Officer (CTO)",
    "Chief Executive Officer (CEO)",
    "Chief Operations Officer (COO)",
    "Partner",
    "Principal Consultant",
    "Software Developer",
    "Software Engineer",
    "Data Scientist",
    "Data Analyst",
    "Systems Engineer",
    "Network Engineer",
    "Solutions Architect",
    "DevOps Engineer",
    "Cloud Engineer",
    "IT Support Specialist",
    "Technical Lead",
    "UI/UX Designer",
    "Graphic Designer",
    "Marketing Specialist",
    "Content Writer",
    "Copywriter",
    "Public Relations Officer",
    "Social Media Manager",
    "Human Resources Executive",
    "Recruitment Specialist",
    "Office Manager",
    "Financial Analyst",
    "Accountant",
    "Auditor",
    "Compliance Officer",
    "Consultant",
    "Freelancer",
    "Contractor",
    "Advisor",
    "Educator",
    "Researcher",
    "Scientist",
    "Medical Officer",
    "Nurse",
    "Teacher",
    "Founder",
    "Co-Founder",
    "Chairman",
    "Managing Director (MD)",
    "President"
  ];
  

  // const [companyName, setcompanyName] = useState("");
  //   const [companyEmail, setcompanyEmail] = useState("");
  //   const [companyTypeValue, setcompanyTypeValue] = useState("");
  //   const [designationvalue, setdesignationvalue] = useState("");
  //   const [companySalary, setcompanySalary] = useState("");
  //   const [companyAddress, setcompanyAddress] = useState("");

  // const [workinfo, setworkinfo] = useState({
  //   companyEmail: "",
  //   companySalary: "",
  //   companyJoinDate: "",
  //   companyEmployeeId: "",
  //   companyPaySlip: "",
  //   companyName: "",
  //   companyType: "",
  //   companyDesignation: "",
  //   companyCity: "",
  //   companyDistrict: "",
  //   companyState: "",
  //   companyCountry: "",
  //   companyPincode: "",
  //   companyStreet: "",
  //   companyLandmark: "",
  // });

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};


  const validations = {
    companyStreet: (value) => /^[a-zA-Z0-9\s,.'-]{3,}$/.test(value),
    companyCity: (value) => /^[a-zA-Z\s]{2,200}$/.test(value),
    companyDistrict: (value) => /^[a-zA-Z\s]{2,200}$/.test(value),
    companyState: (value) => /^[a-zA-Z\s]{2,200}$/.test(value),
    companyCountry: (value) => /^[a-zA-Z\s]{2,200}$/.test(value),
    companyPincode: (value) => /^[0-9]{6}$/.test(value),
    companyLandmark: (value) => /^[a-zA-Z0-9\s,.'-]{3,}$/.test(value), // Accepts letters, numbers, spaces, commas, periods, apostrophes, and hyphens
  };

  const validateField = (fieldName, value) => {
    if (validations[fieldName]) {
      return validations[fieldName](value);
    }
    return true;
  };

  const [componentedit, setcomponentedit] = useState(true);

  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelect = (option) => {
    setSelectedOption(option);
    // Handle selected option here
    //console.log("Selected option:", option);
  };

  const [errors, setErrors] = useState({
    companyEmail: "",
    companySalary: "",
    companyJoinDate: "",
    companyEmployeeId: "",
    salarySlip: [],
    companyName: "",
    companyType: "",
    companyDesignation: "",
    companyCity: "",
    companyDistrict: "",
    companyState: "",
    companyCountry: "",
    companyPincode: "",
    companyStreet: "",
    companyLandmark: "",
    companyTotalExp: "",
    companyIdPhoto: "",
    companyJoiningLetter: "",
  });

  const handleUserInfo = (e, inputName) => {
    const companyNameRegex =
      /^(?!\d+$)[a-zA-Z0-9,.'-][a-zA-Z0-9\s,.'-]{1,}[a-zA-Z0-9,.'-]( {0,3})?$/; // At least 3 characters, no leading/trailing spaces beyond 3
    const joiningDateRegex =
      /^((0[1-9])|(1[0-2]))\/((0[1-9])|([1-2][0-9])|(3[0-1]))\/(\d{4})$/; // MM/DD/YYYY format
    const salaryRegex = /^[0-9]*$/; // Only numeric digits
    const empIdRegex = /^[a-zA-Z0-9-_]{5,}$/;
    const experienceRegex = /^[0-9]*$/;
    const emailRegex =
      /^(?!.*@(gmail|outlook|yahoo|hotmail)\.).+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    //console.log("value of the input ::::::::::", e);

    let errorMessage = "";

    // Check each field against its respective regex pattern
    switch (inputName) {
      case "companyName":
        if (!companyNameRegex.test(e)) {
          errorMessage = "Invalid Company Name";
        }
        break;
      case "companyEmail":
        if (!emailRegex.test(e)) {
          errorMessage = "Invalid Company Email";
        }
        break;
      case "companyJoinDate":
        if (!joiningDateRegex.test(e)) {
          errorMessage = "Invalid Joining Date";
        }
        break;
      case "companySalary":
        if (salaryRegex.test(e)) {
          setworkinfo((prevState) => ({
            ...prevState,
            [inputName]: e,
          }));
        }

        break;
      case "companyEmployeeId":
        if (!empIdRegex.test(e)) {
          errorMessage = "Invalid Employee Id";
        }
        break;
      case "companyTotalExp":
        if (experienceRegex.test(e)) {
          setworkinfo((prevState) => ({
            ...prevState,
            [inputName]: e,
          }));
        }
        break;
      default:
        break;
    }

    if (errorMessage) {
      // Update the state with the error message if validation fails4
      //console.log("inside the error message if validation fails4")
      setErrors((prevErrors) => ({
        ...prevErrors,
        [inputName]: errorMessage,
      }));
    } else {
      // Clear the error message if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [inputName]: "",
      }));
    }

    // Update the state with the input value
    if (inputName !== "companySalary" && inputName !== "companyTotalExp") {
      setworkinfo((prevState) => ({
        ...prevState,
        [inputName]: e,
      }));
    }
  };

  const handleUserInfoall = () => {
    let isValid = true;
    //console.log("workinfo",errors.companyName?"true":"false");
    //console.log("inside the handleUserInfoall toalexperience:::::::::::::::::::::::::::::::::::",workinfo.companyTotalExp);
    //console.log("inside the handleUserInfoall employeeid:::::::::::::::::::::::::::::::::::",workinfo.companyEmployeeId.length);

    if (workinfo.companyName.length == 0 || errors.companyName) {
      //console.log("inside the handleUserInfoall company name length 0");
      alertmessage("Please enter the Valid company name", "danger");
      return (isValid = false);
    }
    if (workinfo.companyTotalExp == "" || errors.companyTotalExp) {
      alertmessage("Please enter the total experience", "danger");
      return (isValid = false);
    }
    if (workinfo.companyType.length == 0 || errors.companyType) {
      alertmessage("Please enter the company type", "danger");
      return (isValid = false);
    }
    if (workinfo.companyEmail.length == 0 || errors.companyEmail) {
      alertmessage("Please enter the company email", "danger");
      return (isValid = false);
    }
    if (workinfo.companySalary.length == 0 || errors.companySalary) {
      alertmessage("Please enter the Monthly   salary", "danger");
      return (isValid = false);
    }
    if (workinfo.companyJoinDate.length == 0 || errors.companyJoinDate) {
      alertmessage("Please enter the company join date", "danger");
      return (isValid = false);
    }
    if (workinfo.companyEmployeeId.length == 0 || errors.companyEmployeeId) {
      alertmessage("Please enter the company employee ID", "danger");
      return (isValid = false);
    }
    if (!workverify) {
      alertmessage("Please verify your work", "danger");
      return (isValid = false);
    }
    if (workinfo.companyCity.length == 0 || errors.companyCity) {
      alertmessage("Please enter the company address", "danger");
      return (isValid = false);
    }
    if (workinfo.companyDistrict.length == 0 || errors.companyDistrict) {
      alertmessage("Please enter the company address", "danger");
      return (isValid = false);
    }
    if (workinfo.companyState.length == 0 || errors.companyState) {
      alertmessage("Please enter the company address", "danger");
      return (isValid = false);
    }
    if (workinfo.companyCountry.length == 0 || errors.companyCountry) {
      alertmessage("Please enter the company address", "danger");
      return (isValid = false);
    }
    if (workinfo.companyPincode.length == 0 || errors.companyPincode) {
      alertmessage("Please enter the company address", "danger");
      return (isValid = false);
    }
    if (workinfo.companyLandmark.length == 0 || errors.companyLandmark) {
      alertmessage("Please enter the company address", "danger");
      return (isValid = false);
    }
    if (workinfo.companyStreet.length == 0 || errors.companyStreet) {
      alertmessage("Please enter the company address", "danger");
      return (isValid = false);
    }

    if (userEmpIdFile.length == 0) {
      alertmessage("Please upload your employee id", "danger");
      return (isValid = false);
    }

    if (userJoiningLetterPdf.length == 0) {
      alertmessage("Please upload your joining letter", "danger");
      return (isValid = false);
    }

    if (userSalarySlipPdf.length < 3) {
      alertmessage("Please upload your salary slip", "danger");
      return (isValid = false);
    }

    // Proceed with further logic
    return isValid;
  };

  const handleAddtressInfo = (value, fieldName) => {
    //console.log("value", value);
    //console.log("fieldName", fieldName);

    const streetRegex =
      /^[a-zA-Z0-9,.'-][a-zA-Z0-9\s,.'-]{1,}[a-zA-Z0-9,.'-]( {0,3})?$/; // At least 3 characters, no more than 3 trailing spaces
    const cityRegex = /^[a-zA-Z][a-zA-Z\s]{0,198}[a-zA-Z]( {0,3})?$/; // 2 to 200 characters, no more than 3 trailing spaces
    const districtRegex = /^[a-zA-Z][a-zA-Z\s]{0,198}[a-zA-Z]( {0,3})?$/; // 2 to 200 characters, no more than 3 trailing spaces
    const stateRegex = /^[a-zA-Z][a-zA-Z\s]{0,198}[a-zA-Z]( {0,3})?$/; // 2 to 200 characters, no more than 3 trailing spaces
    const countryRegex = /^[a-zA-Z][a-zA-Z\s]{0,198}[a-zA-Z]( {0,3})?$/; // 2 to 200 characters, no more than 3 trailing spaces
    const pincodeRegex = /^[0-9]{6}$/; // Accepts exactly 6 numeric digits
    const landmarkRegex =
      /^[a-zA-Z0-9,.'-][a-zA-Z0-9\s,.'-]{1,}[a-zA-Z0-9,.'-]( {0,3})?$/; // At least 3 characters, no more than 3 trailing spaces

    let errorMessage = "";

    // Check each field against its respective regex pattern
    if (fieldName === "companyStreet") {
      if (!streetRegex.test(value)) {
        errorMessage = "Invalid Street Address";
      }
    } else if (fieldName === "companyCity") {
      if (!cityRegex.test(value)) {
        errorMessage = "Invalid City";
      }
    } else if (fieldName === "companyDistrict") {
      if (!districtRegex.test(value)) {
        errorMessage = "Invalid District";
      }
    } else if (fieldName === "companyState") {
      if (!stateRegex.test(value)) {
        errorMessage = "Invalid State";
      }
    } else if (fieldName === "companyCountry") {
      if (!countryRegex.test(value)) {
        errorMessage = "Invalid Country";
      }
    } else if (fieldName === "companyPincode") {
      if (!pincodeRegex.test(value)) {
        errorMessage = "Invalid Pincode";
      }
    } else if (fieldName === "companyLandmark") {
      if (!landmarkRegex.test(value)) {
        errorMessage = "Invalid Landmark";
      }
    }

    // Update state with error message if validation fails, otherwise clear the error message
    if (errorMessage) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: errorMessage,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: "",
      }));
    }

    // Update state with the input value
    setworkinfo((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleAddtressInfoall = () => {
    if (errors.companyStreet) {
      alertmessage("Please enter a valid street name", "danger");
    } else if (errors.companyCity) {
      alertmessage("Please enter a valid city name", "danger");
    } else if (errors.companyDistrict) {
      alertmessage("Please enter a valid district name", "danger");
    } else if (errors.companyState) {
      alertmessage("Please enter a valid state", "danger");
    } else if (errors.companyCountry) {
      alertmessage("Please enter a valid country ", "danger");
    } else if (errors.companyPincode) {
      alertmessage("Please enter a valid pincode", "danger");
    } else if (errors.companyLandmark) {
      alertmessage("Please enter a valid company landmark", "danger");
    } else if (workinfo.companyStreet.length < 2) {
      alertmessage(
        "Company street must be at least 2 characters long",
        "danger"
      );
    } else if (workinfo.companyCity.length < 2) {
      alertmessage("Company city must be at least 2 characters long", "danger");
    } else if (workinfo.companyDistrict.length < 2) {
      alertmessage(
        "Company district must be at least 2 characters long",
        "danger"
      );
    } else if (workinfo.companyState.length < 2) {
      alertmessage(
        "Company state must be at least 2 characters long",
        "danger"
      );
    } else if (workinfo.companyCountry.length < 2) {
      alertmessage(
        "Company country must be at least 2 characters long",
        "danger"
      );
    } else if (workinfo.companyPincode.length < 2) {
      alertmessage(
        "Company pincode must be at least 2 characters long",
        "danger"
      );
    } else if (workinfo.companyLandmark.length < 2) {
      alertmessage(
        "Company landmark must be at least 2 characters long",
        "danger"
      );
    } else {
      closeModal();
    }
  };

  const handleChangeDateFormat = (e) => {
    handleUserInfo(e, "companyJoinDate");
  };

  const handledateclick = () => {
    setShowFilterDropdown({ ...showFilterDropdown, date: false });
  };

  useEffect(() => {
    // //console.log(componentedit, componenttype, "workinfo");
    // if (
    //   component &&
    //   (componenttype === "preview" || componenttype === "loanpreview")
    // ) {
    //   setcomponentedit(false);
    //   //console.log(componentdata, companyType, "data");
    //   setworkinfo(componentdata);
    // }
  }, []);

  // =========================================
  const mapcondref = useRef(false);
  const customIcon = new L.Icon({
    iconUrl: "https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/gif/pinmap.gif", // Replace with the path to your custom icon image
    iconSize: [45, 45], // Size of the icon
    iconAnchor: [16, 32], // Anchor point of the icon
    popupAnchor: [0, -32], // Popup anchor point
  });

  const onMove = useCallback(() => {
    //console.log( mapcondref.current,"zoommove")
    if (mapcondref.current) return;
    const newCenter = map.getCenter();
    currentmarkerRef.current.setLatLng(newCenter);
    // setCurrentuserPosition({})
  }, [map]);

  const Moveend = useCallback(async () => {
    //console.log( mapcondref.current,"zoomend")
    if (mapcondref.current) return;
    const newCenter = map.getCenter();
    let latitude = newCenter.lat.toFixed(13);
    let longitude = newCenter.lng.toFixed(13);

    
   
    // addressField.forEach((obj,index)=>{

    //   let key = Object.keys(obj)[0];

    //   setworkinfo((prev) => ({
    //     ...prev,
    //     [key]: "",
    //   }));

    // })

    setworkAddressInputField((prev) => ({
      ...prev,
      companyStreet: "",
      companyLandmark: "",
    }));

    setposition({
      ...workinfoposition,
      companyLatitude: latitude,
      companyLongitude: longitude,
    });

    addressfetch(latitude, longitude);
  }, [map]);

  const handleZoomStart = useCallback(() => {
    mapcondref.current = true;

    //console.log("zoomin")
  }, []);

  const handleZoomEnd = useCallback(() => {
    mapcondref.current = false;

    //console.log("zoomout")
  }, [map]);

  function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }
  const debouncedHandleZoomEnd = useRef(
    debounce(() => {
      handleZoomEnd();
    }, 1500) // Adjust the delay (in milliseconds) as needed
  ).current;

  // Use this function to attach to the map's zoom end event

  useEffect(() => {
    if (map) {
      map.on("move", onMove);
      map.on("moveend", Moveend);
      map.on("zoomstart", handleZoomStart);
      map.on("zoomend", debouncedHandleZoomEnd);
      return async () => {
        map.off("moveend", Moveend);
        map.off("move", onMove);
        map.off("zoomstart", handleZoomStart);
        map.off("zoomend", handleZoomEnd);
      };
    }
  }, [map]);

  useEffect(() => {
    if (navigator.geolocation) {
      const { state } = navigator.permissions.query({ name: "geolocation" });
      //console.log(state, "state");

      navigator.geolocation.getCurrentPosition(
        (position) => {
          // //console.log(position, "latti");

          let latitude = position.coords.latitude;
          let longitude = position.coords.longitude;
          // //console.log(latitude, longitude);
          // alert("latitude",latitude,"long",longitude)
          // //console.log("working geolocation");
          setposition({
            ...workinfoposition,
            companyLatitude: latitude.toFixed(13),
            companyLongitude: longitude.toFixed(13),
          });
        },
        (err) => {
          // setError(err.message);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        }
      );
    } else {
    }
  }, []);

  // ============================================

  const payload = {
    accId: accountId,
    apiKey: apiKey,
    companyName: workinfo.companyName,
    companyType: workinfo.companyType,
    companyDesignation: workinfo.companyDesignation,
    companyCity: workinfo.companyCity,
    companyDistrict: workinfo.companyDistrict,
    companyState: workinfo.companyState,
    companyCountry: workinfo.companyCountry,
    companyPincode: workinfo.companyPincode,
    companyStreet: workinfo.companyStreet,
    companyLandmark: workinfo.companyLandmark,
    companyLatitude: workinfoposition.companyLatitude,
    companyLongitude: workinfoposition.companyLongitude,
    companySalary: workinfo.companySalary,
    companyJoinDate: workinfo.companyJoinDate,
    companyEmployeeId: workinfo.companyEmployeeId,
    companyPaySlip: [],
    companyTotalExp: 0,
    companyIdPhoto: "",
    companyJoiningLetter: "",
  };

  // const [showModalPdf, setshowModalPdf] = useState({
  //   "modelOpen":"close",
  //   "pdfUrl":""
  //   });

  // const handlePdfReview = (toggleModal,url)=>{

  //   setshowModalPdf((prev)=> ({...prev,modelOpen:toggleModal,pdfUrl:url}))

  // }

  const addressfetch = async (lat, lng) => {
    const language = "en"; // Specify the language code for English

    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&zoom=18&addressdetails=1&accept-language=${language}`
      );

      const data = await response.json();

      if (data.display_name) {
        const order = [
          // "house_number",
          "road",
          "neighbourhood",
          "suburb",
          "state_district",
          // "state",
          // "postcode",
          "city",
          // "district",
          // "country",
        ];

        //console.log("workinfo address fetch", data);

        for (const component of order) {
          if (data.address[component]) {
            //console.log(component,workinfo,"compo")
            if (component === "road") {
              handleAddtressInfo(data.address[component], "companyStreet");
            }
            // else if (component === "city" ) {
            //   handleAddtressInfo(data.address[component],"companyCity")

            // }
            //  else if (
            //   (component === "state_district" || component === "district") &&
            //   !workinfo.companyDistrict
            // ) {
            //     handleAddtressInfo(data.address[component], "companyDistrict");

            // } else if (component === "state" ) {
            //     handleAddtressInfo(data.address[component],"companyState")

            // } else if (component === "country") {
            //   //console.log("map executed")
            //     handleAddtressInfo(data.address[component],"companyCountry")

            // } else if (component === "postcode") {
            //     handleAddtressInfo(data.address[component],"companyPincode")
            //     validatePincode(data.address[component]);

            // }
          }
        }
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  const handleEditUploadImage = (uploadType) => {
    switch (uploadType) {
      case "IdCard":
        //console.log("handleEditUploadImage working")
        setworkinfo((prev) => ({ ...prev, companyIdPhoto: "" }));
        setEmpIdFile([]);
        break;
      case "joiningLetter":
        setworkinfo((prev) => ({ ...prev, companyJoiningLetter: "" }));
        setuserJoiningLetterPdf([]);
        break;

      default:
        break;
    }
  };

  const handleRemoveUploadedPdf = (type,url) => {
    //console.log(url, "url")
    let temparr = [...workinfo.salarySlip];

    temparr = temparr.filter((e, i) => e != url);

    //console.log(temparr, "temp")

    setworkinfo((prev) => ({ ...prev, salarySlip: temparr }));

    setSalarySlipPdf((prev) => [...prev.filter((obj) => obj.url !== url)]);
  };

  const handleresent = () => {
    let time = 180;
    const newinterval = setInterval(() => {
      time = time - 1;
      setresenttime(time);
      if (time === 0) {
        setresentotp(true);
        setresenttime(180);
        clearInterval(newinterval);
      }
    }, 1000);
    setTimeInterval(newinterval);
  };

  useEffect(() => {
    if (userSalarySlipPdf.length === 3) {
      let newFinalSalarySlipArr = [];

      userSalarySlipPdf.forEach((obj) => {
        let keys = Object.keys(obj);

        keys.forEach((key) => {
          if (key === "url") {
            newFinalSalarySlipArr.push(obj[key]);
          }
        });
      });
      setworkinfo((prev) => ({ ...prev, salarySlip: newFinalSalarySlipArr }));
      // setfinalsalarySlipArr(newFinalSalarySlipArr);
    }
  }, [userSalarySlipPdf]);

  // Example usage:
  // const url = "https://retailcashbacks.sgp1.digitaloceanspaces.com/uploads/1001716536571-employeeID_salarySlip.pdf";
  // const fileName = getFileNameFromUrl(url);
  // //console.log(fileName); // Output: employeeID_salarySlip.pdf

  async function handleWorkInfoAPi() {
    let valid = handleUserInfoall();
    //console.log("handleUserInfoall",valid)
    if (valid) {
      //console.log("inside the handle:::::::::::::::::::::",)
      if (workverify) {
        try {

          const data = await updateworkinfo(removeSlash(location.pathname));

          if (data.Success) {
            // setloading(true);
            //console.log("Inside the value of the ")
            alertmessage(`${data.Success}`, "success");
            !component && getdetails();
          } else if (data.Warning) {
            alertmessage(data.Warning, "danger");
          } else {
            alertmessage(`${data.Error}`, "danger");
          }
        } catch (err) {
          console.log(err, "error");
        }
      } else {
        alertmessage("Please Verify Email before proceed", "danger");
      }
    } else {
      alertmessage("Please fill all the required fields", "danger");
      // setAddressValidState((prev) => ({ ...prev,"tempAddress": false }));
    }
  }

  // joel code

  const [pincodeError, setPincodeError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [otpError, setOtpError] = useState("");
  // const [otpVerify, setOtpVerify] = useState(false);

  // const validatePincode = async (pincode) => {

  //   if (pincode?.length === 6) {

  //       setPincodeError("");
  //       try {
  //         const response = await fetch(
  //           `${Url}/business/isPincodeValid?pincode=${pincode}`
  //         );
  //         const resJson = await response.json();
  //         //console.log("pincode response:", resJson);
  //         if (resJson.Error) {
  //           setPincodeError(resJson.Error);
  //         } else {
  //           setPincodeError("");
  //           // setuserAddress((prevState) => ({
  //           //   ...prevState,
  //           //   permanentPincode: pincode
  //           // }));
  //         }
  //       } catch (error) {
  //         //console.log("Error:", error);
  //         setPincodeError("Unable to verify Pincode,Try again later");
  //       }

  //   } else {
  //     setPincodeError("  ");
  //   }
  // };

  const verifyEmail = async () => {
    // const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const emailRegex =/^(?!.*@(gmail|outlook|yahoo|hotmail)\.).+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    const accId = localStorage.getItem("accId");
    const apiKey = localStorage.getItem("apiKey");

    if (!emailRegex.test(workinfo.companyEmail) || !accId || !apiKey) {
      setotpModalOpen(false);
      setEmailError("Enter Your valid company email address");
    } else {
      if (userinfo.userEmail === workinfo.companyEmail) {
        alertmessage("Company email cannot be same as User email", "danger");
      } else {
        //console.log("inside else of verify email");
        try {
          setverifybtn(true);
          setupdateLoad(true)
          await fetch(`${Url}/business/sendEmail`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              accId: accId.toString(),
              apiKey: apiKey,
              email: workinfo.companyEmail,
            }),
          })
            .then((res) => res.json())
            .then((resJson) => {
              //console.log("email response:", resJson);
              if (resJson.error) {
                setEmailError("Invalid Email");
              } else {
                setEmailError("");
                openotpModal();
                setshowalert({
                  ...showalert,
                  show: true,
                  msg: "OTP sent successfully",
                  type: "success",
                });
                sethaveotp(true);
                resenttime < 180 && clearInterval(timeInterval);
                setresenttime(180);
                handleresent();
              }
              setverifybtn(false);
              setupdateLoad(false)
            })
            .finally(()=>{
          Metrixfunction(metrixserver, "sendEmail", "UPDATE");

            })
        } catch (error) {
          console.log("Error :", error);
          setverifybtn(false);
          setupdateLoad(false)
        }
      }
    }
  };

  const handleverifyotp = async (value) => {
    const accId = localStorage.getItem("accId");
    const apiKey = localStorage.getItem("apiKey");

    //console.log("Payload :", {
    //   accId: accId.toString(),
    //   apiKey: apiKey,
    //   token: value,
    //   email: workinfo.companyEmail,
    //   verifyType: "work",
    // });

    if (value.length < 6 || !accId || !apiKey) {
      setOtpError("OTP must be 6 digits long");
    } else {
      try {
        setotpedit(false);
        setupdateLoad(true)

        await fetch(`${Url}/business/validateEmailOTP`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            accId: localStorage.getItem("accId"),
            apiKey: localStorage.getItem("apiKey"),
            token: value,
            email: workinfo.companyEmail,
            verifyType: "work",
          }),
        })
          .then((res) => res.json())
          .then((resJson) => {
            //console.log("otp get response :", resJson);
            if (resJson.Error) {
              setOtpError("Enter valid OTP");
              alertmessage(resJson.Error, "danger");
            } else {
              setworkverify(true);
              setotpModalOpen(false);
              sethaveotp(false);
              alertmessage("Email Verified", "success");
            }
            setotpedit(true);
            setupdateLoad(false)
            setTimeout(() => {
              otpfocus();
            }, 100);
          })
          .catch((err)=>{
            console.log(err,"error")
            setupdateLoad(false)
          })
          .finally(()=>{
            Metrixfunction(metrixserver,"validateEmailOTP", "UPDATE");

          })
      } catch (error) {
        console.log("Error :", error);
        setOtpError("Enter valid OTP");
        setupdateLoad(false)
        setotpedit(true);
        setTimeout(() => {
          otpfocus();
        }, 100);
      }
    }
  };

  // const getdetails=async()=>{
  //    let data= await getWorkInfoApi()

  //    //console.log(data,"response")

  //    if (data.Success) {

  //     setinitialworkinfo(data.Success);

  //     if (data.Success?.companyEmail?.length > 0) {
  //       setworkverify(true);
  //     }

  //     setposition({
  //       companyLatitude: data.Success.companyLatitude,
  //       companyLongitude: data.Success.companyLongitude,
  //     });

  //   } else {
  //     alertmessage(`${data.Error}`, "danger");
  //   }
  // }

  const getdetails = async () => {
    try {

      let data = await getWorkInfoApi();

      //console.log(data, "json")

      if (data.Success) {
        if (component && componenttype === "stepper") {
          setworkinfo({
            ...workinfo,
            companyEmail: data.Success.companyEmail,
          });
        } else if (component && componenttype === "loanapply") {
          // setworkinfo(data.success)
          let obj = { ...workinfo };
          let obj2 = data.Success;

          for (let key in obj2) {
            if (obj2[key] !== "") {
              obj[key] = obj2[key];
            }
          }
          setworkinfo(obj);

          // setworkinfo(obj2)
        } else {
          setworkinfo(data.Success);
        }

        if (data.Success?.companyEmail?.length > 0) {
          setworkverify(true);
        } else {
          setworkverify(false);
        }

        if (
          data.Success?.companyLatitude?.length > 0 &&
          data.Success.companyLongitude
        ) {
          setposition({
            companyLatitude: data.Success.companyLatitude,
            companyLongitude: data.Success.companyLongitude,
          });
        }

        if (!data.Success.userEditable) {
          setcomponentedit(false);
        }
      } else {
        alertmessage(`${data.Error}`, "danger");
        navigate("/dashboard");
      }
      setloading(false);
      Metrixfunction(metrixserver, "getDetails", "ONLOAD");

    } catch (err) {
      console.log(err, "error");
    }
  };

  const otpfocus = () => {
    if (otpModalOpen && modalref.current) {
      const inputElement = modalref.current.querySelector(".otp-dummy-input");
      if (inputElement) {
        //console.log("execuuuuuuuu")
        inputElement.focus();
      }
    }
  };
  useEffect(() => {
    otpfocus();
  }, [otpModalOpen]);




  // --------------------------------------

  const [isLocationFetched, setIsLocationFetched] = useState(false);
  let googleResponseArray = [
    "route",
    "premise",
    "sublocality_level_2",
    "sublocality_level_1",
    "locality",
    "administrative_area_level_3",
    "administrative_area_level_1",
    "country",
    "postal_code",
  ];

  async function getLatLong(placeIds) {
    try {
      setIsMapLoading(true);
      await fetch("https://places.googleapis.com/v1/places/" + placeIds, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Goog-Api-Key": "AIzaSyCfG7cQW3f1A0-BdbD0QkNEGuiac2BmCHE",
          "X-Goog-FieldMask":
            "id,formattedAddress,plusCode,location,addressComponents,adrFormatAddress",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          //console.log("getLatLong response =>",data)
          if (data) {
            setposition({
              ...workinfoposition,
              companyLatitude: data.location.latitude,
              companyLongitude: data.location.longitude,
            });

            const addressObject = {};

            if (data.addressComponents?.length > 0) {
              data.addressComponents.forEach((component, index) => {
                googleResponseArray.forEach((type) => {
                  const val = component.types.includes(type);
                  if (val) {
                    addressObject[type] = component.longText;
                    return;
                  }
                });
              });

              //console.log("addressObject",addressObject);
            }

            setworkAddressInputField((prev) => ({
              ...prev,
              companyStreet: "",
              companyLandmark: "",
            }));

            // validatePincode(addressObject["postal_code"]);

            setworkAddressInputField((prev) => ({
              ...prev,
              companyPincode: addressObject["postal_code"]
                ? addressObject["postal_code"]
                : "",
              companyCountry: addressObject["country"]
                ? addressObject["country"]
                : "",
              companyState: addressObject["administrative_area_level_1"]
                ? addressObject["administrative_area_level_1"]
                : "",
              companyDistrict: addressObject["administrative_area_level_3"]
                ? addressObject["administrative_area_level_3"]
                : "",
              companyCity: addressObject["locality"]
                ? addressObject["locality"]
                : "",
              companyStreet:
                (addressObject["route"] ? addressObject["route"] + ", " : "") +
                (addressObject["sublocality_level_2"]
                  ? addressObject["sublocality_level_2"] + ", "
                  : "") +
                (addressObject["sublocality_level_1"]
                  ? addressObject["sublocality_level_1"]
                  : ""),
            }));

            // tempAddressInputField["currentPincode"] = pincodeStr;

            // tempAddressInputField["currentCountry"] = formattedAddressArr[formattedAddressArr.length-1];
            // tempAddressInputField["currentState"] = updatedStateStr;
            // tempAddressInputField["currentDistrict"] = formattedAddressArr[formattedAddressArr.length-3] == undefined ?"" : formattedAddressArr[formattedAddressArr.length-3];
            // userinfo["currentCity"] = formattedAddressArr[formattedAddressArr.length-4] == undefined ?"" : formattedAddressArr[formattedAddressArr.length-4];

            //  //console.log("formattedAddressArr",formattedAddressArr);

            setIsLocationFetched(true);
            setIsMapLoading(false);
          } else {
            setIsLocationFetched(false);
            setIsMapLoading(false);
          }
        })
        .catch((error) => console.error("Error:", error));
    } catch (e) {
      console.log(e);
    }
  }

  const [searchTerm, setsearchTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [IsMapLoading, setIsMapLoading] = useState(false);

  const regex = /^(?!\s*$).+/;

  function validateSearchInput(input) {
    return regex.test(input);
  }

  async function handleSearch(searchcode) {
    //console.log("Handle search api triggered",searchcode,+" "+ searchcode.length)
    try {
      if (searchcode.length > 2 && validateSearchInput(searchcode)) {
        setIsMapLoading(true);
        //console.log("searchTerm",searchcode)


        await fetch(`${Url}/business/searchMapAddress`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            accId: "1234567890",
            searchStr: searchcode,
            apiKey:
              "M2hZZytlZU1vL3h0aWR2TXVoOUFhdTV1RmNRaWVnaGYxZ0Vpb0hBVmFKbz",
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            //console.log("handleSearch response",data.Success);
            if (data.Success) {
              setOptions(data.Success);
              setIsLocationFetched(false);
              setIsMapLoading(false);
            } else {
              //console.log(data)
              setIsMapLoading(false);
              setIsLocationFetched(false);
              setOptions([]);
            }
          })
          .catch((error) => console.error("Error:", error))
          .finally(()=>{
        Metrixfunction(metrixserver,"searchMapAddress", "ONLOAD");

          })
      }
    } catch (e) {
      console.log(e);
    }
  }

  const debouncer = useRef(null);

  // var debouncer;
  const handleSearchLocation = (value) => {
    clearTimeout(debouncer.current);

    setsearchTerm(value);

    debouncer.current = setTimeout(() => {

      handleSearch(value);
      if (value.length < 3) {
        setIsLocationFetched(false);
        setOptions([]);
      }
      //console.log("value length",value.length)
    }, 1000);
  };

  const handleSplit = (location, isName) => {
    let result = "";

    if (isName) {
      result = location.split(", ")[0];
    } else {
      let parts = location.split(", ");
      let addressParts = parts.slice(1);
      let address = addressParts.join(", ");
      result = address;
    }

    return result;
  };

  const addressField = [
    {
      companyPremises: {
        labelName: "Premise Name",
        placeHolder: "Ex: Building Name",
        leftIcon: <i className="fa fa-user" />,
        maxLength: "100",
        errorKey: "companyPremises",
      },
    },
    {
      companyStreet: {
        labelName: " Street / Area",
        placeHolder: "Ex: abc street",
        leftIcon: <i className="fa fa-user" />,
        maxLength: "100",
        errorKey: "companyStreet",
      },
    },
  
    {
      companyLandmark: {
        labelName: "Landmark",
        placeHolder: "Ex: near xyz apartment",
        leftIcon: <i className="fa fa-user" />,
        maxLength: "100",
        errorKey: "companyLandmark",
      },
    },
    {
      companyCity: {
        labelName: "City",
        placeHolder: "Ex: chennai",
        leftIcon: <i className="fa fa-user" />,
        maxLength: "50",
        errorKey: "companyCity",
      },
    },
    {
      companyDistrict: {
        labelName: "District",
        placeHolder: "Ex: xxxxxx",
        leftIcon: <i className="fa fa-user" />,
        maxLength: "50",
        errorKey: "companyDistrict",
      },
    },
    {
      companyState: {
        labelName: "State",
        placeHolder: "Ex: Tamil Nadu",
        leftIcon: <i className="fa fa-user" />,
        maxLength: "50",
        errorKey: "companyState",
      },
    },
    // {
    //   "companyCountry": {
    //     "labelName": "Country",
    //     "placeHolder": "Ex: India",
    //     "leftIcon": <i className="fa fa-user" />,
    //     "maxLength": "50",
    //     "errorKey": "companyCountry"
    //   }
    // },
    {
      companyPincode: {
        labelName: "Pincode",
        placeHolder: "Ex: 600096",
        leftIcon: <i className="fa fa-user" />,
        maxLength: "6",
        errorKey: "companyPincode",
      },
    },
  ];

  const dateTimePickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      Object.keys(filterDropdownRef.current).forEach((key) => {
        if (
          filterDropdownRef.current[key] &&
          !filterDropdownRef.current[key].contains(event.target)
        ) {
          // //console.log("key", key);
          setShowFilterDropdown((prev) => ({ ...prev, [key]: false }));
        }
      });
      if (
        dateTimePickerRef.current &&
        !dateTimePickerRef.current.contains(event.target)
      ) {
        setIsFocusedDatePicker(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleEditAddress = (addressCategory) => {
    let pageOne = document.getElementsByClassName("pageOne")[0];
    let pageTwo = document.getElementsByClassName("pageTwo")[0];

    if (addressCategory === "company") {
      let addressKeysTemp = Object.keys(workAddressInputField);

      addressKeysTemp.forEach((key) => {
        if (workinfo[key] !== "") {
          workAddressInputField[key] = workinfo[key];
        }
      });

      setIsLocationFetched(true);
      setAddressPageNum(1);
      pageOne.style.transform = "translate(-150%)";
      pageTwo.style.transform = "translate(0)";
      openModal();
    }
  };

  const [workAddressInputField, setworkAddressInputField] = useState({
    companyCity: "",
    companyDistrict: "",
    companyState: "",
    companyCountry: "",
    companyPincode: "",
    companyStreet: "",
    companyLandmark: "",
    companyPremises:""
  });

  const [validationErrors, setValidationErrors] = useState({
    companyCity: "",
    companyDistrict: "",
    companyState: "",
    companyCountry: "",
    companyPincode: "",
    companyStreet: "",
    companyLandmark: "",
    companyPremises:""
  });

const fieldConfigurations = {
  companyStreet: { label: "Company Street", minLength: 1 },
  companyLandmark: { label: "Company Landmark", minLength: 2 },
  companyCity: { label: "Company City", minLength: 2 },
  companyDistrict: { label: "Company District", minLength: 2 },
  companyState: { label: "Company State", minLength: 2 },
  companyCountry: { label: "Company Country", minLength: 2 },
  companyPincode: { label: "Company Pincode", minLength: 6 },
  companyPremises: { label: "Company premise", minLength: 6 }
  
};


const convertDateFormat = (dateStr) => {
  const [day, month, year] = dateStr.split('/');
  return `${month}/${day}/${year}`;
};

  const handleAddressInfo = (value, field) => {
    let errorMessage = "";
    const regex = validationRegex[field];
    const minCriteria = fieldConfigurations[field];

    if (value == "" || (regex && !regex.test(value))) {
      if (
        // field === "currentHouseNo" ||
        field === "companyStreet" ||
        field === "companyCity" ||
        field === "companyDistrict" ||
        field === "companyState" ||
        field === "companyCountry" ||
        field === "companyPincode" ||
        field === "companyLandmark"
      ) {
        let length;
        length = field === "companyPincode" ? 6 : 2;
        errorMessage =
          value?.length >= length
            ? `Invalid ${minCriteria.label}.`
            : `${minCriteria.label} must be atleast ${minCriteria.minLength} characters.`;

        // break;
      }
    }

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [field]: errorMessage,
    }));

    if (field === "companyPincode" && /^\d{0,6}$/.test(value)) {
      // Allows up to 6 digits
      setworkAddressInputField((prevState) => ({
        ...prevState,
        [field]: value, // Use computed property names to dynamically set the key
      }));
    }

  if(field !== "companyPincode"){

    setworkAddressInputField((prevState) => ({
      ...prevState,
      [field]: value, // Use computed property names to dynamically set the key
    }));

  }
  
  
 

}






  // reset month and year

  const resetMonthDropdown = () => {
    setResetDateTime(false);
    setTimeout(() => {
      setResetDateTime(true);
    }, 300);
  };

  return loadingscreen ? (
    <Loading />
  ) : (
    <>
      {updateLoad && <UpdateLoader />}
      <div className="wi-main-container">
        <div className="wi-main-content-wrapper">
          <div className="wi-input-main">
            <h2 className="wi-input-title">Work Information</h2>

            <div className="wi-input-name-wrapper">
              <div className="wi-input-wrapper">
                <label>Company Name</label>
                <InputField
                  Id="companyName"
                  placeholder={"Ex: ABC pvt ltd"}
                  leftIcon={<i className="fa fa-user" />}
                  onChangeText={(e) => handleUserInfo(e, "companyName")}
                  value={workinfo.companyName}
                  maxLength={50}
                  editable={componentedit}
                  validationError={errors.companyName}
                />
                {componentedit}
              </div>
              <form
                className="wi-input-wrapper"
                onSubmit={(e) => {
                  e.preventDefault();
                  //console.log("innerform executed");
                }}
              >
                <div className="d-flex align-items-center justify-content-between ">
                  <label>Company Email ID</label>
                  {componentedit ? (
                    workverify ? (
                      <div className="d-flex align-items-center gap-3">
                        {/* <button
                        onClick={() => setworkverify(false)}
                        className="email-validate-button"
                        tabIndex="-1"
                      >
                       ReVerify
                      </button> */}
                        <p className="email-validate-button-verified">
                          <Check />
                        </p>
                      </div>
                    ) : (
                      <div className="usermail-otpwrapper">
                        {haveotpshow && (
                          <u
                            className="haveotp"
                            onClick={() => {
                              openotpModal(false);
                            }}
                          >
                            I have an OTP
                          </u>
                        )}
                        <button
                          onClick={() => {
                            verifyEmail();
                          }}
                          style={{
                            opacity:
                              errors.companyEmail.length > 0 ||
                              workinfo.companyEmail.length === 0
                                ? 0.5
                                : 1,
                          }}
                          className="email-validate-button"
                          tabIndex="-1"
                          disabled={
                            verifybtn ||
                            errors.companyEmail.length > 0 ||
                            workinfo.companyEmail.length === 0
                          }
                          id="companyemailverify"
                        >
                          Verify
                        </button>
                      </div>
                    )
                  ) : (
                    ""
                  )}
                </div>
                <InputField
                  Id="companyEmail"
                  placeholder={"Ex: xyz@abc.com"}
                  leftIcon={<i className="fa fa-envelope" />}
                  onChangeText={(e) => {
                    handleUserInfo(e, "companyEmail");
                    haveotpshow && sethaveotp(false);
                  }}
                  value={workinfo.companyEmail}
                  editable={componentedit && !workverify}
                  maxLength={50}
                  validationError={errors.companyEmail}
                />
              </form>

              <div className="wi-input-wrapper">
                <label>Company Type</label>

                <Dropdown
                  Id="companyType"
                  options={companyType}
                  // searchBar
                  leftIcon={
                    <i className="fa fa-building" style={{ color: "grey" }} />
                  }
                  onSelect={(e) => handleUserInfo(e, "companyType")}
                  drpdownWidth={"100%"}
                  drpdownHeight={"50px"}
                  placeHolder={"Select the company type"}
                  value={workinfo.companyType}
                  editable={componentedit}
                />
                {/* <InputField 
              placeholder={"Company type"} 
              leftIcon={<Users />}
              /> */}
                {errors.companyType && (
                  <span className="bank-error-text">{errors.companyType}</span>
                )}
              </div>

              <div
                className=" ui-input-dob wi-input-wrapper"
                id="datejoin"
                onClick={(e) => {
                  e.stopPropagation();
                  // setIsFocused(true)\
                  componentedit &&
                    setShowFilterDropdown({
                      ...showFilterDropdown,
                      date: !showFilterDropdown.date,
                    });
                }}
              >
                <label>Date of Joining</label>
                {/* <InputField
                placeholder={"Ex: 01/01/2024"}
                leftIcon={<Calendar />}
                onChangeText={(e) => handleUserInfo(e, "companyJoinDate")}
                value={workinfo.companyJoinDate}
                editable={componentedit}
                // onFocusText={(val)=> setIsFocused(val) }
              /> */}
                <button
                  style={{ border: "none" }}
                  className={`input-field-container  ${
                    isFocusedDatePicker ? "focuseddatetime" : ""
                  }`}
                  ref={(el) => (dateTimePickerRef.current = el)}
                  onClick={() => {
                    componentedit && setIsFocusedDatePicker(true);
                  }}
                  disabled={!componentedit}
                >
                  <Calendar color="#666666" />
                  {workinfo.companyJoinDate ? (
                    <p
                      style={{
                        color: componentedit ? "black" : "#4a4343",
                        marginLeft: "10px",
                        fontFamily: "'Jost', sans-serif",
                        fontSize: "14px",
                      }}
                    >
                      {convertDDMMYYYYFormat(workinfo.companyJoinDate)}
                    </p>
                  ) : (
                    <p
                      style={{
                        color: "#c4c4c4",
                        marginLeft: "10px",
                        fontFamily: "'Jost', sans-serif",
                        fontSize: "14px",
                      }}
                    >
                      DD/MM/YYYY
                    </p>
                  )}
                </button>
                {errors.companyJoinDate && (
                  <span className="bank-error-text">
                    {errors.companyJoinDate}
                  </span>
                )}
              </div>

              <div className="wi-input-wrapper">
                <label>Designation</label>
                <Dropdown
                  Id="empDesignation"
                  options={Designation}
                  searchBar={true}
                  leftIcon={
                    <i className="fa fa-users" style={{ color: "grey" }} />
                  }
                  onSelect={(e) => handleUserInfo(e, "companyDesignation")}
                  drpdownWidth={"100%"}
                  drpdownHeight={"50px"}
                  placeHolder={"Select the Designation"}
                  value={workinfo.companyDesignation}
                  editable={componentedit}
                />
                {errors.companyDesignation && (
                  <span className="bank-error-text">
                    {errors.companyDesignation}
                  </span>
                )}
              </div>

              <div className="wi-input-wrapper">
                <label>Monthly Salary</label>
                <InputField
                  Id="empSalary"
                  placeholder={"Ex: 25000"}
                  leftIcon={<Cash />}
                  onChangeText={(e) => handleUserInfo(e, "companySalary")}
                  value={workinfo.companySalary}
                  editable={componentedit}
                  maxLength={10}
                  validationError={errors.companySalary}
                />
              </div>
              {(!component ||
                !(
                  componenttype === "preview" || componenttype === "stepper"
                )) && (
                <>
                  {" "}
                  <div className="wi-input-wrapper">
                    <label>Employee ID</label>
                    <InputField
                      Id="empId"
                      placeholder={"Ex: EMP100007"}
                      leftIcon={<i className="fa fa-id-card" />}
                      onChangeText={(e) =>
                        handleUserInfo(e, "companyEmployeeId")
                      }
                      value={workinfo.companyEmployeeId}
                      editable={componentedit}
                      maxLength={20}
                      validationError={errors.companyEmployeeId}
                    />
                  </div>
                  <div className="wi-input-wrapper">
                    <label>Total Experience</label>
                    <InputField
                      Id="empExperience"
                      placeholder={"Ex: 4"}
                      leftIcon={<PersonWorkspace />}
                      onChangeText={(e) => handleUserInfo(e, "companyTotalExp")}
                      value={workinfo.companyTotalExp}
                      editable={componentedit}
                      maxLength={2}
                      validationError={errors.companyTotalExp}
                    />
                  </div>
                  <div className="ui-textArea-wrapper">
                    <div className="d-flex justify-content-between align-items-center ">
                      <label>Office Address</label>
                      {workinfo.companyCity === "" ||
                      workinfo.companyState === "" ||
                      workinfo.companyState === "" ||
                      workinfo.companyDistrict === "" ||
                      workinfo.companyCountry === "" ||
                      workinfo.companyStreet === "" ||
                      workinfo.companyPremises === "" ||
                      workinfo.companyPincode === ""
                        ? ""
                        : componentedit && (
                            <Edit
                              style={{ cursor: "pointer" }}
                              onClick={() => handleEditAddress("company")}
                            ></Edit>
                          )}
                    </div>

                    {workinfo.companyCity === "" ||
                    workinfo.companyState === "" ||
                    workinfo.companyDistrict === "" ||
                    workinfo.companyCountry === "" ||
                    workinfo.companyStreet === "" ||
                    workinfo.companyPremises === "" ||
                    workinfo.companyPincode === "" ? (
                      <div
                        id="addCompanyAddress"
                        className="ui-address-container"
                        onClick={openModal}
                      >
                        <Plus />
                        <p>Add Address details</p>
                      </div>
                    ) : (
                      <div className="wi-actual-address-wrapper">
                        <p
                          style={{ color: componentedit ? "black" : "#4a4343" }}
                        >
                          {`${workinfo.companyStreet}, ${
                            workinfo.companyPremises && workinfo.companyPremises
                          }, ${workinfo.companyCity}, ${
                            workinfo.companyDistrict
                          }, 
                            ${workinfo.companyState}, ${
                            workinfo.companyCountry
                          } - ${workinfo.companyPincode}. LandMark :${
                            workinfo.companyLandmark
                          } `}
                        </p>
                      </div>
                    )}
                  </div>
                </>
              )}
              {/* {addressValidState.tempAddress && workinfo["companyPincode"] == ""  &&  <p style={{marginTop:"10px",color:"#fa8391",fontSize:"small"}}>Kindly Add Office Address</p>} */}
            </div>

            {(!component ||
              !(
                componenttype === "preview" || componenttype === "stepper"
              )) && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {workinfo.companyIdPhoto === "" &&
                userEmpIdFile.length === 0 ? (
                  <div className="col col mt-5 ">
                    <label>Upload Current Company Employee ID </label>
                    <div className="mt-2">
                      <FileUpload
                        fileType="application/pdf,image/jpeg,image/jpg,image/png"
                        limitCount={1}
                        fileName={`${accountId}_IDCard_`}
                        fileId={"companyIdPhoto"}
                        labelId={"IDCardLabel"}
                        files={userEmpIdFile}
                        setFiles={setEmpIdFile}
                        // files={(e)=> handleUploadFiles(e,"utilityBillType")}
                      />
                    </div>
                  </div>
                ) : (
                  // <div className="col mt-5  ">
                  //   <div className="d-flex justify-content-between align-items-center  " style={{width:"60%"}}>
                  //   <label> Employee ID </label>

                  //   </div>
                  //   <div className="mt-2"></div>

                  //   <div className="col ui-imageWrapper" >
                  //   <XCircle onClick={()=> handleEditUploadImage("IdCard")} color="red" style={{position:"absolute",top:"10px",right:"10px",backgroundColor:"white",borderRadius:"50%",cursor:"pointer"}}/>
                  //     <img src={workinfo.companyIdPhoto || userEmpIdFile[0].url } style={{height:"100%",width:"100%"}} ></img>
                  //   </div>
                  // </div>
                  <div className="col mt-5" style={{ minHeight: "160px" }}>
                    <AssetDisplay
                      workinfo={workinfo.companyIdPhoto}
                      fileUploadSate={userEmpIdFile}
                      handleEditUploadImage={handleEditUploadImage}
                      fileType={"IdCard"}
                      editable={componentedit}
                      labelName={"Employee ID"}
                    />
                  </div>
                )}

                {workinfo.salarySlip && workinfo.salarySlip.length > 2 ? (
                  <div className="col mt-5  ">
                    <div
                      className="d-flex justify-content-between align-items-center mb-2 "
                      style={{ width: "60%" }}
                    >
                      <label>Last 3 months payslip</label>
                    </div>
                    <div className="mt-2"></div>
                    <div
                      style={{
                        padding: 10,
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        gap: "40px",
                      }}
                    >
                      {workinfo?.salarySlip?.length > 0 &&
                        workinfo.salarySlip.map((url, index) => {
                          return (
                            <div key={index} style={{ minHeight: "160px" }}>
                              <AssetDisplay
                                workinfo={url}
                                handleEditUploadImage={handleRemoveUploadedPdf}
                                fileType={"SalarSlip"}
                                editable={componentedit}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                ) : (
                  <div className="col col" style={{ marginTop: "5rem" }}>
                    <div
                      className="d-flex justify-content-between align-items-center  "
                      style={{ width: "100%" }}
                    >
                      <label>Upload your Last 3 months payslip</label>
                      {/* {(userSalarySlipPdf.length == 3 )&& <button onClick={()=> handleEditUploadImage("salarySlip")}>Upload</button>} */}
                    </div>
                    <div className="mt-2">
                      <FileUpload
                        fileType="application/pdf"
                        limitCount={3}
                        fileName={`${accountId}_salarySlip_`}
                        fileId={"userSalarySlip"}
                        labelId={"salarySlipLabel"}
                        files={userSalarySlipPdf}
                        setFiles={setSalarySlipPdf}
                        // files={(e)=> handleUploadFiles(e,"utilityBillType")}
                      />
                    </div>
                  </div>
                )}

                {/* {
               showModalPdf.modelOpen === "open" && <div style={{height:"70vh",width:"90vw",backgroundColor:"grey",position:"absolute",top:"0px"}}>
                  <PdfPreview url={showModalPdf.pdfUrl} />
                </div>
              } */}

                {
                  // (workinfo.companyJoiningLetter && workinfo.companyJoiningLetter != "" && userJoiningLetterPdf.length != 0 )  ?
                  workinfo.companyJoiningLetter === "" &&
                  userJoiningLetterPdf.length == 0 ? (
                    // <div className="col mt-5  ">
                    //   <div className="d-flex justify-content-between align-items-center  " style={{width:"60%"}}>
                    //     <label>Joining Letter</label>

                    //   </div>
                    //   <div className="mt-2"></div>
                    //   <div style={{padding:10,display:"flex",alignItems:"center",flexWrap:"wrap",gap:"40px"}}>

                    //     <div style={{height:"auto",width:"150px",position:"relative",backgroundColor:"white",display:"flex",flexDirection:"column",alignItems:"center",gap:"10px"}}  >
                    //       <XCircle color="red" style={{position:"absolute",top:"10px",right:"10px",backgroundColor:"white",borderRadius:"50%",cursor:"pointer"}} onClick={()=> handleEditUploadImage("joiningLetter")}/>
                    //       <a href={workinfo.companyJoiningLetter} target="/blank" ><img src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/pdf.png" style={{height:"80%",width:"80%"}}  ></img></a>
                    //       <p style={{fontSize:12}}>{getFileNameFromUrl(workinfo.companyJoiningLetter)}</p>
                    //   </div>

                    //   </div>
                    // </div>

                    <div>
                      <div
                        className="d-flex justify-content-between align-items-center  "
                        style={{ width: "100%" }}
                      >
                        <label>
                          Upload your current company Joining Letter or Offer
                          Letter
                        </label>
                        {/* {(userSalarySlipPdf.length == 3 )&& <button onClick={()=> handleEditUploadImage("salarySlip")}>Upload</button>} */}
                      </div>
                      <div className="mt-2">
                        <FileUpload
                          fileType="application/pdf,image/jpeg,image/jpg,image/png"
                          limitCount={1}
                          fileName={`${accountId}_Joining_Letter_`}
                          fileId={"userJoiningLetter"}
                          labelId={"joiningLetterLabel"}
                          files={userJoiningLetterPdf}
                          setFiles={setuserJoiningLetterPdf}
                          // files={(e)=> handleUploadFiles(e,"utilityBillType")}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="col mt-5" style={{ minHeight: "160px" }}>
                      <AssetDisplay
                        workinfo={workinfo.companyJoiningLetter}
                        fileUploadSate={userJoiningLetterPdf}
                        handleEditUploadImage={handleEditUploadImage}
                        fileType={"joiningLetter"}
                        editable={componentedit}
                        labelName={"Joining/Offer Letter"}
                      />
                    </div>
                  )
                }
              </div>
            )}

            {/* <InputField />   */}
            {!component && componentedit && (
              <div className="wi-action-btns-wrapper">
                <button
                  onClick={() => {
                    handleWorkInfoAPi();
                  }}
                >
                  Update
                </button>
              </div>
            )}
          </div>
        </div>

        <ModalAddress
          isOpen={isModalOpen}
          onClose={closeModal}
          modalTitle={
            AddressPageNum == 0
              ? "Add Your Work Location"
              : "Confirm Work Address"
          }
          submitButtonPlaceHolder={
            AddressPageNum == 0 ? "Confirm Location" : "Add Address"
          }
          onSave={() => handleAddAddress("company", AddressPageNum)}
          modalwidth={"50%"}
          modalHeight={"95%"}
          style={{ maxHeight: "none" }}
          savebtn={
            !IsMapLoading && (options?.length < 1 || isLocationFetched)
              ? true
              : false
          }
          isMap={AddressPageNum == 0 ? true : false}
          onBack={handleBack}
          InitialCloseButton={
            !isLocationFetched && AddressPageNum == 0 && options?.length < 1
              ? true
              : false
          }
        >
          <div
            className="pageAnimationWrapper"
            style={{ position: "relative", height: "100%", width: "100%" }}
          >
            <div className="userinfo-address-container pageOne">
              <div
                style={{
                  width: "100%",
                  position: "Sticky",
                  display: "flex",
                  alignItems: "center",
                  top: "-5px",
                  zIndex: "9999",
                  marginTop: "10px",
                }}
              >
                <input
                  id="companyAddress_searchbar"
                  className="userinfo-searchbar"
                  placeholder="Search for Area, Landmarks or nearby place"
                  onChange={(e) => handleSearchLocation(e.target.value)}
                  value={searchTerm}
                />

                {/* <button style={{height:"100%",width:"100px",borderRadius:"0px 10px 10px 0px"}} onClick={handleSearch}>Search</button> */}
                <Search
                  style={{ position: "absolute", left: "10px", top: "10px" }}
                />
              </div>

              {IsMapLoading ? (
                <div className="custom-loader"></div>
              ) : isLocationFetched ? (
                <div style={{ width: "100%" }}>
                  <div className="leafletmap-wrapper">
                    <MapContainer
                      center={[
                        workinfoposition.companyLatitude,
                        workinfoposition.companyLongitude,
                      ]}
                      scrollWheelZoom={true}
                      zoom={17}
                      ref={setMap}
                      style={{ height: "60vh", width: "100%" }}
                    >
                      <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />

                      <Marker
                        ref={currentmarkerRef}
                        position={[
                          workinfoposition.companyLatitude,
                          workinfoposition.companyLongitude,
                        ]}
                        icon={customIcon}
                      ></Marker>
                    </MapContainer>
                  </div>
                </div>
              ) : (
                <div
                  id="companyAddress_searchResults"
                  className="userinfo-searchResults"
                >
                  {
                    options && options.length > 0 ? (
                      options.map(({ address, placeId }, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              padding: "10px",
                              marginBottom: "5px",
                              borderBottom: "1px solid lightgrey",
                              position: "relative",
                              cursor: "pointer",
                            }}
                            onClick={() => getLatLong(placeId)}
                          >
                            <div>
                              <MapPin
                                style={{
                                  position: "absolute",
                                  left: "10px",
                                  top: "20px",
                                }}
                              />
                            </div>
                            <div style={{ marginLeft: "40px" }}>
                              <p style={{ color: "#E32f45" }}>
                                {handleSplit(address, true)}
                              </p>
                              <p style={{ fontSize: "13px" }}>
                                {handleSplit(address, false)}
                              </p>
                            </div>
                          </div>
                        );
                      })
                    ) : searchTerm.length > 2 ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          marginBottom: "60px",
                        }}
                      >
                        <div
                          style={{
                            height: "300px",
                            width: "fit-content",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        >
                          <img
                            src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/No-data.png"
                            style={{ height: "100%", width: "100%" }}
                          ></img>
                        </div>
                        <h4>No Results Found</h4>
                      </div>
                    ) : (
                      <div
                        style={{
                          height: "300px",
                          width: "fit-content",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginTop: "80px",
                          marginBottom: "30px",
                        }}
                      >
                        <img
                          src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/Location-search.png"
                          style={{ height: "100%", width: "100%" }}
                        ></img>
                      </div>
                    )
                    /* <div style={{padding:"15px",width:"100%",borderRadius:"10px",backgroundColor:"#FF6F59",color:"white",textAlign:"center"}}>Close</div> */
                  }
                </div>
              )}
            </div>

            <div
              className="userinfo-address-container pageTwo"
              style={{ justifyContent: "flex-start" }}
            >
              {addressField &&
                addressField.map((data, index) => {
                  let key = Object.keys(data)[0];
                  let value = data[Object.keys(data)[0]];
                  return key == "companyPincode" ? (
                    <div className="ui-input-wrapper" key={index}>
                      <label>Pincode</label>
                      <InputField
                        placeholder={"Ex: 600096"}
                        // leftIcon={<i className="fa fa-user" />}
                        onChangeText={(e) => {
                          handleAddressInfo(e, "companyPincode");
                          // validatePincode(e);
                        }}
                        value={workAddressInputField.companyPincode}
                        editable={componentedit}
                        maxLength={6}
                        validationError={validationErrors.companyPincode}
                        Id={key}
                        containerStyle={{
                          border:
                            pincodeError != "" ||
                            workAddressInputField[key] == ""
                              ? "1px solid red"
                              : "",
                        }}
                      />
                      {pincodeError && (
                        <span className="bank-error-text">{pincodeError}</span>
                      )}
                    </div>
                  ) : (
                    <div className="ui-input-wrapper" key={index}>
                      <label>{value.labelName}</label>
                      <InputField
                        placeholder={value.placeHolder}
                        // leftIcon={value.leftIcon}
                        onChangeText={(e) => handleAddressInfo(e, key)}
                        value={workAddressInputField[key]}
                        editable={componentedit}
                        maxLength={value.maxLength}
                        validationError={validationErrors[key]}
                        Id={key}
                        containerStyle={{
                          border:
                            validationErrors[key] != "" ||
                            workAddressInputField[key] == ""
                              ? "1px solid red"
                              : "",
                        }}
                      />
                      {/* {errors[key] && (
                          <span className="bank-error-text">{errors[key]}</span>
                        )} */}
                    </div>
                  );
                })}

              <p style={{ fontSize: "14px" }}>
                Note: If any information is incorrect, kindly edit and provide
                the Correct details.
              </p>
            </div>
          </div>
        </ModalAddress>
        <Modal
          isOpen={showFilterDropdown.date}
          onClose={() => {
            setShowFilterDropdown({ ...showFilterDropdown, date: false });
            resetMonthDropdown();
          }}
          modalTitle={"Pick Your Date of Joining"}
          modalwidth={"45%"}
        >
          <DateTimePicker
            // selectedDate={
            //   workinfo.joineddate ? new Date(convertDateFormat(workinfo.joineddate)) : new Date()
            // }
            selectedDate={
              workinfo.companyJoinDate
                ? new Date(convertMMDDYYYYFormat(workinfo.companyJoinDate))
                : new Date()
            }
            eighteenYearsCheck={false}
            onChange={(e) => handleChangeDateFormat(e)}
            ondateclick={handledateclick}
            resetMonthDropdown={resetDateTime}
          />
        </Modal>

        <Modal
          isOpen={otpModalOpen}
          onClose={closeotpModal}
          submitButtonPlaceHolder={"Submit OTP"}
          modalref={modalref}
          modalwidth={"600px"}
          savebtn={false}
          Id="companymailmodal"
        >
          <div className="otp-title-wrapper">
            <div className="otp-title">
              Please enter the One Time Password to verify your account
            </div>
            <div className="otp-body">
              A One-time Password has been sent to {workinfo.companyEmail}
            </div>
          </div>
          <div
            className="otpcontainer"
            onSubmit={(event) => {
              event.preventDefault();
              //console.log("clicked");
            }}
          >
            <div className="mb-2">
              <OtpInput
                sendotp={handleemailotp}
                editable={otpedit}
                reset={!resendotp}
              />
            </div>
            <div className="resent-wrapper">
              {resendotp ? (
                <button
                  className="resend-otp"
                  disabled={verifybtn}
                  onClick={() => {
                    setemailotp("");
                    if (otpModalOpen && modalref.current) {
                      const inputElement =
                        modalref.current.querySelector(".otp-dummy-input");
                      if (inputElement) {
                        inputElement.focus();
                      }
                    }
                    verifyEmail();
                  }}
                >
                  Resend OTP
                </button>
              ) : (
                <span className="resent-time">
                  Resent OTP in {formatTime(resenttime)} seconds
                </span>
              )}
            </div>
          </div>
        </Modal>

        {showalert.show && (
          <Alert
            show={true}
            message={showalert.msg}
            type={showalert.type}
            duration={3000}
            onClose={() => {
              setshowalert({ show: false, message: "", type: "" });
            }}
          />
        )}
      </div>
    </>
  );
}

export default WorkInfo;
