import React, { useEffect } from "react";
import "../../styles/screenStyles/MessageScreen.css";
import { useNavigate } from "react-router-dom";


const FailedMessage = () => {
    const navigate = useNavigate();
    const message = localStorage.getItem("processType");

    useEffect(() => {
        setTimeout(() => {
            
            localStorage.removeItem("message");
        }, 1000);   
     }, []);

    return (
        <div className="MessageScreen-main-wrapper">
            <div className="MessageScreen-main">
                <img className="MessageScreen-status" src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/failed.gif" alt="success" />
                <div className="MessageScreen-text wow fadeInUp">
                    <h1 style={{ color: "#d63c71" }}>FAILED!</h1>
                    {message ==="bankverify" && <p>Could not verify bank account. Please retry</p>}

                    {message ==="changebank" && <p>Bank verification Failed.</p>}

                    {message ==="paymentgateway" && <p>Payment Failed, please retry!</p>}
                    <div className="MessageScreen-button-wrapper" style={{background:'#ffe6ef'}}>
                        <button style={{ backgroundColor: "#d63c71" }} className="MessageScreen-button" onClick={() => {navigate("/loanDetails");localStorage.removeItem("message");localStorage.removeItem("processType")}}>Try Again</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FailedMessage;