export const validationRegex = {
  userName: /^(?!\s*$)[A-Za-z.,'\-\s]{2,}$/, // Min 2 characters, no only spaces, special characters allowed, no numbers
  phone: /^\d{10}$/, // 5 or 6 digit number, only numbers
  userEmail: /^[\w.%+-]+@(gmail|outlook|yahoo|hotmail)\.com$/, // Standard email pattern
  userGST: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
  userDOB: /^((0[1-9])|(1[0-2]))\/((0[1-9])|([1-2][0-9])|(3[0-1]))\/(\d{4})$/, // Date in YYYY-MM-DD format
  currentCountry: /^(?!\s*$).{2,}$/, // At least 2 characters, no only spaces
  currentState: /^(?!\s*$).{2,}$/, // At least 2 characters, no only spaces
  currentCity: /^(?!\s*$).{2,}$/, // At least 2 characters, no only spaces
  currentDistrict: /^(?!\s*$).{2,}$/, // At least 2 characters, no only spaces
  currentPincode: /^\d{6}$/, // 5 or 6 digit number, only numbers
  currentHouseNo: /^(?!\s*$).{1,}$/, // At least 1 character, no only spaces
  currentStreet: /^(?!\s*$).{2,}$/, // At least 2 characters, no only spaces
  currentLandmark: /^(?!\s*$).{2,}$/, // At least 2 characters, no only spaces
  permanentCountry: /^(?!\s*$).{2,}$/, // At least 2 characters, no only spaces
  permanentState: /^(?!\s*$).{2,}$/, // At least 2 characters, no only spaces
  permanentCity: /^(?!\s*$).{2,}$/, // At least 2 characters, no only spaces
  permanentDistrict: /^(?!\s*$).{2,}$/, // At least 2 characters, no only spaces
  permanentPincode: /^\d{6}$/, // 5 or 6 digit number, only numbers
  permanentHouseNo: /^(?!\s*$).{1,}$/, // At least 1 character, no only spaces
  permanentStreet: /^(?!\s*$).{2,}$/, // At least 2 characters, no only spaces
  permanentLandmark: /^(?!\s*$).{2,}$/, // At least 2 characters, no only spaces
  userFatherName: /^(?!\s*$)[A-Za-z.,'\-\s]{2,}$/, // Min 2 characters, no only spaces, special characters allowed, no numbers
  userMotherName: /^(?!\s*$)[A-Za-z.,'\-\s]{2,}$/, // Min 2 characters, no only spaces, special characters allowed, no numbers
  userMaritalStatus: /^(Single|Married|Divorced|Widowed)$/, // Marital status
  userCommunity: /^(?!\s*$)[A-Za-z.,'\-\s]{2,}$/, // Min 2 characters, no only spaces, special characters allowed, no numbers
  userNationality: /^(?!\s*$)[A-Za-z.,'\-\s]{2,}$/, // Min 2 characters, no only spaces, special characters allowed, no numbers
  userBloodGroup: /^(A|B|AB|O)[+-]$/, // Blood group types
  userEducation: /^(?!\s+$)[^\d]{2,}$/, // Min 2 characters, no only spaces, special characters allowed, no numbers
  userResidentialOwnerShip:
    /^(Owned|Rental|Paying Guest|Parental Property|Staying with Relatives|Other)$/, // Ownership status
  userLatitude: /^-?([1-8]?[0-9]|90)\.[0-9]{1,6}$/, // Latitude from -90.000000 to 90.000000
  userLongitude: /^-?((1[0-7][0-9])|([1-9]?[0-9]))\.[0-9]{1,6}$/, // Longitude from -180.000000 to 180.000000
  companyCountry: /^(?!\s*$).{2,}$/, // At least 2 characters, no only spaces
  companyState: /^(?!\s*$).{2,}$/, // At least 2 characters, no only spaces
  companyCity: /^(?!\s*$).{2,}$/, // At least 2 characters, no only spaces
  companyDistrict: /^(?!\s*$).{2,}$/, // At least 2 characters, no only spaces
  companyPincode: /^\d{6}$/, // 5 or 6 digit number, only numbers
  // companyName: /^(?!\s*$).{1,}$/, // At least 1 character, no only spaces
  companyStreet: /^(?!\s*$).{2,}$/, // At least 2 characters, no only spaces
  companyLandmark: /^(?!\s*$).{2,}$/, // At least 2 characters, no only spaces
};
