import React, { useEffect } from 'react'
import "../styles/componentStyles/UpdateLoader.css"
function UpdateLoader() {

  useEffect(()=>{
    document.body.style.overflow = 'hidden';

    return ()=>{
      document.body.style.overflow = 'auto';
    }
  },[])
  return (
    <div className="loader-overlay">
    <div class="updateloading">
    <div class="loaderd1"></div>
    <div class="loaderd2"></div>
</div>
</div>
  )
}

export default UpdateLoader