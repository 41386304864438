import React from "react";
import "../styles/componentStyles/DownArrowbtn.css";
import { Divide } from "react-feather";

function DownArrowbtn() {
  return (
    // <div class="btn-bounce">
    //     <svg class="bi bi-arrow-down" width="1em" height="1em" viewBox="0 0 16 16" fill="#fff" xmlns="http://www.w3.org/2000/svg">
    //         <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
    //     </svg>
    // </div>
    <div className="termArrow-wrapper">
      <div class="btn-bounce">
        <svg
          class="bi bi-arrow-down"
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          fill="#fff"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
          />
        </svg>
      </div>
      <span className="scrolldown-text">Scroll Down</span>
    </div>
    // <div>
    // <div class="btn-bounce">
    //     <svg class="bi bi-arrow-downx" width="1em" height="1em" viewBox="0 0 16 16" fill="#fff" xmlns="http://www.w3.org/2000/svg">
    //         <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
    //     </svg>
    // </div>
    //     <div>Scroll Down</div>
    //     </div>
  );
}

export default DownArrowbtn;
