import React, { useContext, useEffect, useRef, useState } from 'react'
import Metrixfunction from '../components/MetrixFunction';
import { MyContext } from '../components/GlobalValues';
// import Header from '../components/Header'
// import Footer from '../components/Footer'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import OwlCarousel from 'react-owl-carousel';


function Home() {

  const {  metrixserver} = useContext(MyContext);
//    var clientSlider = document.getElementsByClassName('.clients-slider')[0];

const options = {
  loop: true,
  center: true,
  items: 3,
  margin: 0,
  autoplay: true,
  dots: false,
  autoplayTimeout: 3500,
  smartSpeed: 450,
  nav: false,
  responsive: {
      0: {
          items: 1
      },
      600: {
          items: 3
      },
      1000: {
          items: 3
      }
  }
};

const partners = [
  "https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/icici.png",
  "https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/cashfree-logo.png",
  "https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/Renambl-logo.png",
   "https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/yesbank.png"
  // Add more partners as needed
];

let owl_carousel = require('owl.carousel');
window.fn = owl_carousel;


const owlcarousel=()=>{
  try{
   return(
    <OwlCarousel className="owl-theme mySwiper" {...options}>
    {partners.map((partner, index) => (
      <div key={index} className="item">
        <img
          src={partner}
          alt=""
          className="assouciates-img-carousel"
          width={"100%"}
        />
      </div>
    ))}
  </OwlCarousel>
   )
  }
  catch(err){
    console.log(err,"error  ")
  }
}



const [imagesLoaded, setImagesLoaded] = useState(0);
const totalImages = useRef(0);

useEffect(() => {
  // Count the total number of images
  const imageElements = document.querySelectorAll('img');
  totalImages.current = imageElements.length;


  // Add load event listeners to all images
  imageElements.forEach(img => {
    if (img.complete) {
      handleImageLoad();
    } else {
      img.addEventListener('load', handleImageLoad);
      img.addEventListener('error', handleImageLoad); // Handle error case
    }
  });

  // Cleanup function
  return () => {
    imageElements.forEach(img => {
      img.removeEventListener('load', handleImageLoad);
      img.removeEventListener('error', handleImageLoad);
    });
  };
}, []);

useEffect(() => {
  if (imagesLoaded === totalImages.current && totalImages.current > 0) {
    Metrixfunction(metrixserver,"static", "PAGE_ONLOAD");

  }
}, [imagesLoaded]);

const handleImageLoad = () => {
  setImagesLoaded(prev => prev + 1);
};





useEffect(() => {
  const handleDOMContentLoaded = () => {
 
    myFunction(); // Call your function here
  };

  if (document.readyState === 'complete') {
    handleDOMContentLoaded();
  } else {
    window.addEventListener('load', handleDOMContentLoaded);
    // Clean up the event listener on component unmount
    return () => window.removeEventListener('load', handleDOMContentLoaded);
  }
}, []);

const myFunction = () => {
 
  // Your function logic here
};


  return (
    <div className="bg-white container-fluid p-0 " id="contentt">
      {/* Spinner Start */}
      <div
        id="spinner"
        className=" bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
      >
        <div
          className="spinner-grow text-danger"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      {/* Spinner End */}
      {/* Navbar & Hero Start */}
      <div className="position-relative p-0" id="home">
        {/* ============= Nav Bar =========== */}
        <custom-header />
        {/* ========== Nav bar end ============ */}
        <div className="bg-primary hero-header">
          <div className="container px-lg-5">
            <div className="row g-4 mb-4">
              <div
                className="col-12 col-lg-6 wow fadeInUp"
                data-wow-delay="0.3s"
              >
                <div>
                  <h1 className="mb-4" style={{ color: "white" }}>
                    Empowering your financial future beyond banking boundaries.
                  </h1>
                  {/* <button
                  style={{
                    border: "2px solid #fff",
                    backgroundColor: "transparent",
                    color: "#fff",
                    fontSize: 20,
                    borderRadius: 10,
                    padding: "6px 40px",
                    fontFamily: '"Jost", sans-serif'
                  }}
                >
                  Apply Now
                </button> */}
              </div>
              {/* <p className="mb-4" style="color: white">Apply Now!</p> */}
              {/* <div className="row g-4 mb-2" style="padding: 10px;"> */}
              {/* <div className="col-sm-5 wow fadeIn d-flex bg-home-text rounded" style="padding: 5px;"  data-wow-delay="0.5s"> */}
              {/* <div className="col-8"><h5 className="home-text ms-2" style="margin-top: 7px; text-align: center; height: 30px !important;">Apply Now!</h5></div> */}
              {/* <div className="col"><a href="#"  className="icon"><i className="fab fa-apple fa-2x home-text flex-shrink-0"></i></a></div> */}
              {/* <div className="col"><a href="#" className="icon"><img src="./img/right-arrow (1).png" width="40px" style=" margin-top: 2px;  text-align: center"></a></div> */}
              {/* </div> */}
              {/* </div> */}
            </div>
            <div
              className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <img src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/E-Wallet.png" width="100%" height="100%" />
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Navbar & Hero End */}
    {/* About Start */}
    <div className="container-xxl py-2 " id="about">
      <div className="container py-5 px-lg-5">
        <div className="row gap-5 align-items-center justify-content-center ">
          <div
            className="col-lg-4  d-flex justify-content-center align-items-center wow fadeInLeft "
            style={{ height: 300, padding: 10, borderRadius: 20 }}
          >
            <img
              className="img-fluid wow fadeInUp"
              data-wow-delay="0.5s"
              src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/logo.png"
              height="450px"
              width="300px"
            />
          </div>
          <div className="col-lg-6 wow fadeInUp " data-wow-delay="0.1s">
            <h5 className="text-primary-gradient fw-medium">About Us</h5>
            <h1 className="mb-4">Who We are!</h1>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              MyKaasu is a dynamic and expanding Non-Banking Financial Company
              dedicated to support customers and  business merchants. Our core mission 
              is to facilitate the growth of customers by providing affordable loan
              options. We take pride in delivering user-friendly applications that 
              enhance the loan application process for users. Our commitment is to 
              empower business growth through accessible financial solutions and cutting-edge technologies.
            </p>
            {/* <a href="loan.html" className="btn btn-primary-gradient rounded-pill py-2 px-4 mt-4">View More</a> */}
            {/* <a href="" className="btn btn-primary-gradient py-sm-3 px-4 px-sm-5 rounded-pill mt-3">Read More</a> */}
          </div>
        </div>
      </div>
    </div>
    {/* About End */}
    {/* Loan Start */}
    <div className="container-xxl py-4" id="service">
      <div className="container py-1 px-lg-5">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h5 className="text-primary-gradient fw-medium">Our Service</h5>
          <h1 className="mb-5">what we serve!</h1>
        </div>
        <div
          className="tab-class text-center pricing wow fadeInUp"
          data-wow-delay="0.1s"
        >
           <div className="d-flex justify-content-center">
              <ul
                style={{
                  listStyle: "none",
                  padding: "2px 2px",
                  borderRadius: "30px",
                  flexWrap:"nowrap"
                }}
                className=" nav nav-pills d-flex bg-primary-gradient"
              >
                <li className="nav-item">
                  <button
                    className="nav-link active navloan-btn"
                    data-bs-toggle="pill"
                    href="#tab-1"
                  >
                    Business
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className="nav-link navloan-btn"
                    data-bs-toggle="pill"
                    href="#tab-2"
                  >
                    Personal
                  </button>
                </li>
              </ul>
            </div>

          <div className="tab-content text-start">
            <div id="tab-1" className="tab-pane fade show p-0 active">
              <div className="row g-4 business-loan-content rounded pb-4 mt-sm-5 mt-lg-0">
                <div className="col-12 col-md-6  rounded ">
                  <img
                    src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/service2.png"
                    height="100%"
                    width="100%"
                  />
                </div>
                <div className=" col-12 col-md-6 ">
                  <div className="bg-white rounded" style={{height:"100%",display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"flex-start"}}>
                    <div className="border-bottom p-1 mb-4">
                      <h3 className="mb-1">Business Loan</h3>
                      <span
                        style={{ fontFamily: '"Jost", sans-serif' }}
                        className="text-primary-gradient"
                      >
                        Fuel your business dreams with the capital you need.
                      </span>
                    </div>
                    <div className="p-1 pt-0">
                      <h6 className="mb-3">
                        {/* <small className="align-top" style="font-size: 22px; line-height: 45px;">$</small>14.99<small
                                            className="align-bottom" style="font-size: 16px; line-height: 40px;">/ Month</small> */}
                        We offer business loans with competitive interest rates
                        and expedited financing, requiring minimal documentation
                        for a streamlined application process.
                      </h6>
                      <div className="d-flex justify-content-between mb-3">
                        <span style={{ fontFamily: '"Jost", sans-serif' }}>
                          Affordable Interest Rates
                        </span>
                        <i className="fa fa-check text-primary-gradient pt-1" />
                      </div>
                      <div className="d-flex justify-content-between mb-3">
                        <span style={{ fontFamily: '"Jost", sans-serif' }}>
                          {" "}
                          Minimal Documentation
                        </span>
                        <i className="fa fa-check text-primary-gradient pt-1" />
                      </div>
                      <div className="d-flex justify-content-between mb-3">
                        <span style={{ fontFamily: '"Jost", sans-serif' }}>
                          Quick Loan Disbursals
                        </span>
                        <i className="fa fa-check text-primary-gradient pt-1" />
                      </div>
                      <div className="d-flex justify-content-between mb-2">
                        <span style={{ fontFamily: '"Jost", sans-serif' }}>
                          Flexible Loan Tenure
                        </span>
                        <i className="fa fa-check text-primary-gradient pt-1" />
                      </div>
                      <a
                        href="/business-loans"
                        className="btn btn-primary-gradient  py-2 px-4 mt-4"
                        style={{ fontFamily: '"Jost", sans-serif' }}
                      >
                        View More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div id="tab-2" className="tab-pane fade  p-0 ">
              <div className="row g-4 business-loan-content rounded pb-4 mt-sm-5 mt-lg-0">
                <div className="col-12 col-md-6 rounded d-flex justify-content-center  align-items-center ">
                  <img
                    src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/service6.png"
                    height="100%"
                    width="100%"
                  />
                </div>
                <div className=" col-12 col-md-6 ">
                  <div className="bg-white rounded" style={{height:"100%",display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"flex-start"}}>
                    <div className="border-bottom p-1 mb-4">
                      <h3 className="mb-1">Personal Loan</h3>
                      <span
                        style={{ fontFamily: '"Jost", sans-serif' }}
                        className="text-primary-gradient"
                      >
                        Achieve your Personal dreams faster with Mykaasu.
                      </span>
                    </div>
                    <div className="p-1 pt-0">
                      <h6 className="mb-3">
                        {/* <small className="align-top" style="font-size: 22px; line-height: 45px;">$</small>14.99<small
                                            className="align-bottom" style="font-size: 16px; line-height: 40px;">/ Month</small> */}
                          We offer Personal loans with faster application
                          process and best in market interest rates.
                        </h6>
                        <div className="d-flex justify-content-between mb-3">
                          <span style={{ fontFamily: '"Jost", sans-serif' }}>
                            Affordable Interest Rates
                          </span>
                          <i className="fa fa-check text-primary-gradient pt-1" />
                        </div>
                        <div className="d-flex justify-content-between mb-3">
                          <span style={{ fontFamily: '"Jost", sans-serif' }}>
                            Minimal Documentation
                          </span>
                          <i className="fa fa-check text-primary-gradient pt-1" />
                        </div>
                        <div className="d-flex justify-content-between mb-3">
                          <span style={{ fontFamily: '"Jost", sans-serif' }}>
                            Quick Loan Disbursals
                          </span>
                          <i className="fa fa-check text-primary-gradient pt-1" />
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <span style={{ fontFamily: '"Jost", sans-serif' }}>
                            Flexible Loan Tenure
                          </span>
                          <i className="fa fa-check text-primary-gradient pt-1" />
                        </div>
                        <a
                          href="/personal-loans"
                          className="btn btn-primary-gradient py-2 px-4 mt-4"
                          style={{ fontFamily: '"Jost", sans-serif' }}
                        >
                          View More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Loan End */}
      {/* Testimonial Start */}

      {/* <div className="container-xxl py-5" id="review">
      <div className="container py-5 px-lg-5">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h5 className="text-primary-gradient fw-medium">Testimonial</h5>
          <h1 className="mb-5">What Say Our Clients!</h1>
        </div>
        <div
          className="owl-carousel testimonial-carousel wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="testimonial-item rounded p-4">
            <div className="d-flex align-items-center mb-4">
              <div className="ms-4">
                <h5 className="mb-1">Client Name</h5>
                <p className="mb-1">Profession</p>
                <div>
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                </div>
              </div>
            </div>
            <p className="mb-0" style={{ fontFamily: '"Jost", sans-serif' }}>
              Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
              amet diam et eos. Clita erat ipsum et lorem et sit.
            </p>
          </div>
          <div className="testimonial-item rounded p-4">
            <div className="d-flex align-items-center mb-4">
              <div className="ms-4">
                <h5 className="mb-1">Client Name</h5>
                <p className="mb-1">Profession</p>
                <div>
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                </div>
              </div>
            </div>
            <p className="mb-0" style={{ fontFamily: '"Jost", sans-serif' }}>
              Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
              amet diam et eos. Clita erat ipsum et lorem et sit.
            </p>
          </div>
          <div className="testimonial-item rounded p-4">
            <div className="d-flex align-items-center mb-4">
              <div className="ms-4">
                <h5 className="mb-1">Client Name</h5>
                <p className="mb-1">Profession</p>
                <div>
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                </div>
              </div>
            </div>
            <p className="mb-0" style={{ fontFamily: '"Jost", sans-serif' }}>
              Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
              amet diam et eos. Clita erat ipsum et lorem et sit.
            </p>
          </div>
          <div className="testimonial-item rounded p-4">
            <div className="d-flex align-items-center mb-4">
              <div className="ms-4">
                <h5 className="mb-1">Client Name</h5>
                <p className="mb-1">Profession</p>
                <div>
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                </div>
              </div>
            </div>
            <p className="mb-0" style={{ fontFamily: '"Jost", sans-serif' }}>
              Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
              amet diam et eos. Clita erat ipsum et lorem et sit.
            </p>
          </div>
          <div className="testimonial-item rounded p-4">
            <div className="d-flex align-items-center mb-4">
              <div className="ms-4">
                <h5 className="mb-1">Client Name</h5>
                <p className="mb-1">Profession</p>
                <div>
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                </div>
              </div>
            </div>
            <p className="mb-0" style={{ fontFamily: '"Jost", sans-serif' }}>
              Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
              amet diam et eos. Clita erat ipsum et lorem et sit.
            </p>
          </div>
          <div className="testimonial-item rounded p-4">
            <div className="d-flex align-items-center mb-4">
              <div className="ms-4">
                <h5 className="mb-1">Client Name</h5>
                <p className="mb-1">Profession</p>
                <div>
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                </div>
              </div>
            </div>
            <p className="mb-0" style={{ fontFamily: '"Jost", sans-serif' }}>
              Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
              amet diam et eos. Clita erat ipsum et lorem et sit.
            </p>
          </div>
          <div className="testimonial-item rounded p-4">
            <div className="d-flex align-items-center mb-4">
              <div className="ms-4">
                <h5 className="mb-1">Client Name</h5>
                <p className="mb-1">Profession</p>
                <div>
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                </div>
              </div>
            </div>
            <p className="mb-0" style={{ fontFamily: '"Jost", sans-serif' }}>
              Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
              amet diam et eos. Clita erat ipsum et lorem et sit.
            </p>
          </div>
          <div className="testimonial-item rounded p-4">
            <div className="d-flex align-items-center mb-4">
              <div className="ms-4">
                <h5 className="mb-1">Client Name</h5>
                <p className="mb-1">Profession</p>
                <div>
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                </div>
              </div>
            </div>
            <p className="mb-0" style={{ fontFamily: '"Jost", sans-serif' }}>
              Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
              amet diam et eos. Clita erat ipsum et lorem et sit.
            </p>
          </div>
          <div className="testimonial-item rounded p-4">
            <div className="d-flex align-items-center mb-4">
              <div className="ms-4">
                <h5 className="mb-1">Client Name</h5>
                <p className="mb-1">Profession</p>
                <div>
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                  <small className="fa fa-star text-warning" />
                </div>
              </div>
            </div>
            <p className="mb-0" style={{ fontFamily: '"Jost", sans-serif' }}>
              Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
              amet diam et eos. Clita erat ipsum et lorem et sit.
            </p>
          </div>
        </div>
      </div>
    </div> */}

      {/* Testimonial End */}
      {/* Articles Start */}
      <div className="container mb-5 w-100 p-3  " id="Articles">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h5 className="text-primary-gradient fw-medium">Our Articles</h5>
          <h1 className="mb-5">Everything You Need to Know in Personal Loan</h1>
        </div>
        <div
          className="row g-4 bg-light pb-4  rounded wow fadeInUp d-flex justify-content-center flex-wrap "
          data-wow-delay="0.1s"
        >
          <div className=" col-12 col-lg 4 ">
            <div
              className=" pb-2 rounded "
              style={{
                backgroundColor: "white",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/steps.png"
                height="280px"
                width="80%"
              />
              {/* ----Header--- */}
              <div className=" p-4 mb-4">
                <h3 className="text-dark mb-2">
                  Important Factors to avail a Personal Loan
                </h3>
                <div className="d-flex  justify-content-between ">
                  <span
                    className="text-black-50"
                    style={{ fontFamily: '"Jost", sans-serif' }}
                  >
                    Last Updated : 31st October 2023
                  </span>
                </div>
              </div>
              {/* ---------body---- */}
              {/* -------footer------ */}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <a
                  href="/article1"
                  className="btn btn-light  rounded py-2 px-4 mb-2 border  "
                  style={{
                    fontWeight: 510,
                    fontFamily: '"Jost", sans-serif',
                    width: "80%",
                  }}
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
          <div className=" col-12 col-lg-4 ">
            <div
              className=" pb-2 rounded"
              style={{
                backgroundColor: "white",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/myth.png"
                height="280px"
                width="80%"
              />
              {/* ----Header--- */}
              <div className=" p-4 mb-4">
                <h3 className="text-dark   mb-2">
                  Some Common Myths around Personal Loans
                </h3>
                <div className="d-flex  justify-content-between ">
                  <span
                    className="text-black-50 "
                    style={{ fontFamily: '"Jost", sans-serif' }}
                  >
                    Last Updated : 31st October 2023
                  </span>
                </div>
              </div>
              {/* ---------body---- */}
              {/* -------footer------ */}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <a
                  href="/article2"
                  className="btn btn-light  rounded py-2 px-4 mb-2 border  "
                  style={{
                    fontWeight: 500,
                    fontFamily: '"Jost", sans-serif',
                    width: "80%",
                  }}
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
          <div className=" col-12 col-lg-4">
            <div
              className=" pb-2 rounded"
              style={{
                backgroundColor: "white",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src="https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/benefits.png"
                height="280px"
                width="80%"
              />
              {/* ----Header--- */}
              <div className=" p-4 mb-4 ">
                <h3 className="text-dark   mb-2">
                  The Important Benefits of a Personal Loan
                </h3>
                <div className="d-flex  justify-content-between ">
                  <span
                    className="text-black-50"
                    style={{ fontFamily: '"Jost", sans-serif' }}
                  >
                    Last Updated : 31st October 2023
                  </span>
                </div>
              </div>
              {/* ---------body---- */}
              {/* -------footer------ */}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <a
                  href="/article3"
                  className="btn btn-light  rounded py-2 px-4 mb-2 border  "
                  style={{
                    fontWeight: 500,
                    fontFamily: '"Jost", sans-serif',
                    width: "80%",
                  }}
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
          <div className="col-12  d-flex justify-content-center">
            <a
              href="/articles"
              className="btn btn-primary-gradient rounded py-2 px-4 mt-4 "
              style={{ fontFamily: '"Jost", sans-serif' }}
            >
              View More
            </a>
          </div>
        </div>
      </div>
      {/* Articles End */}
      {/* FAQ start */}
      {/* <div className="wow fadeInUp" dat  a-wow-delay="0.3s" id="faq">
  
    <div className="container-xxl py-5">
        <h2 className="accordion__heading">Frequently Asked Questions</h2>
        <br>
        <div className="accordion__item">
          <button className="accordion__btn">
      
            <span className="accordion__caption">What does confirmation status mean in a transaction?</span>
            <span className="accordion__icon"><i className="fa fa-plus"></i></span>
          </button>
      
          <div className="accordion__content">
            <p>When the user goes the retailer through MyKaasu and completes a transaction, the transaction amount will be received by the retailer. The transaction status will be updated to 'CONFIRMED' at this stage and can be viewed under transactions. Once the amount is added to the customer wallet, the status will change to 'PAID'.</p>
          </div>
        </div>
      
        <div className="accordion__item">
          <button className="accordion__btn">
      
            <span className="accordion__caption">What is the difference between Bonus and Cashback?</span>
            <span className="accordion__icon"><i className="fa fa-plus"></i></span>
          </button>
      
          <div className="accordion__content">
            <p>Cashback is the amount you receive from your purchases and rewards are joining/referral bonus you receive when your friend purchases. We pay upto 10% of the cashback everytime your referral purchases.</p>
          </div>
        </div>
      
        <div className="accordion__item">
          <button className="accordion__btn">
      
            <span className="accordion__caption">Is there any expiry for my Cashback/Rewards?</span>
            <span className="accordion__icon"><i className="fa fa-plus"></i></span>
          </button>
      
          <div className="accordion__content">
            <p>NO. There is no expiry on the Cashback or rewards balance. But if you decide to close your account, then the account balance cannot be withdrawn if the amount is less than 50/-
            </p>
          </div>
        </div>
      
        <div className="accordion__item">
          <button className="accordion__btn">
      
            <span className="accordion__caption">My Cashback/Rewards did not track, what can I do?</span>
            <span className="accordion__icon"><i className="fa fa-plus"></i></span>
          </button>
      
          <div className="accordion__content">
            <p>Please navigate to transactions and add a comment under 'Need Help' against the corresponding transaction. Our customer support team will revert back shortly.</p>
          </div>
        </div>
      
        <div className="accordion__item">
          <button className="accordion__btn">
      
            <span className="accordion__caption">How can I combine my Cashback and Rewards to request for the Payment?</span>
            <span className="accordion__icon"><i className="fa fa-plus"></i></span>
          </button>
      
          <div className="accordion__content">
            <p>Navigate to 'Withdraw Amount' to view the cashback and rewards balance. You can combine both the balances and withdraw when the conditions are met,
            </br>
            (i) Your total balance amount is Rs 50/- or higher. Withdraw button will be disabled when balance is low.
            <br>
            (ii) Bank account details are updated under Account > Bank Account. (iii) Previous transaction is not in progress.
            </p>
          </div>
        </div>
        <div className="accordion__item">
            <button className="accordion__btn">
        
              <span className="accordion__caption">How much will I earn on referring a friend?</span>
              <span className="accordion__icon"><i className="fa fa-plus"></i></span>
            </button>
        
            <div className="accordion__content">
              <p>There is no limit on the amount you can receive. Currently you are elible to earn upto 10% of the total referral earnings.
              </p>
            </div>
          </div>
    </div>
      </div> */}
      {/* FAQ end */}
      {/* Contact Start */}
      {/* <div className="container-xxl py-5" id="contact"
        <div className="container py-5 px-lg-5">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h5 className="text-primary-gradient fw-medium">Contact Us</h5>
                <h1 className="mb-5">Get In Touch!</h1>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-9">
                    <div className="wow fadeInUp" data-wow-delay="0.3s">
                        <p className="text-center mb-4">The contact form is currently inactive. Get a functional and working contact form with Ajax & PHP in a few minutes. Just copy and paste the files, add a little code and you're done. <a href="https://htmlcodex.com/contact-form">Download Now</a>.</p>
                        <form>
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <div className="form-floating">
                                        <input type="text" className="form-control" id="name" placeholder="Your Name">
                                        <label for="name">Your Name</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-floating">
                                        <input type="email" className="form-control" id="email" placeholder="Your Email">
                                        <label for="email">Your Email</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating">
                                        <input type="text" className="form-control" id="subject" placeholder="Subject">
                                        <label for="subject">Subject</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating">
                                        <textarea className="form-control" placeholder="Leave a message here" id="message" style="height: 150px"></textarea>
                                        <label for="message">Message</label>
                                    </div>
                                </div>
                                
                            </div>
                        </form>
                            <div className="col-12 text-center" style="margin: 10px;">
  
                                <div id="success-show" className="alert alert-success" role="alert">
                                    <span > <input id="Txt-value-success" className="txt-style text-center" type="text" style="font-weight: 500; width: 100% !important;" disabled/></span>
                                </div>
  
                                <div id="danger-show" className="alert alert-danger" role="alert">
                                    <span > <input id="Txt-value-danger" className="txt-style text-center" type="text" style="font-weight: 500; width: 100% !important;" disabled/></span>
                                </div>
  
                                <button className="btn btn-primary-gradient rounded-pill py-3 px-5" type="submit" onclick="sendMail()">Send Message</button>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
      {/* Contact End */}
      {/* Associate Partners Start*/}
      <div className="wow fadeInUp" data-wow-delay="1s" id="associate-partners">
        <section id="clients" className="clients">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h1 className="mb-5">Associated Partners</h1>
          </div>
          <div className="container" data-aos="zoom-in">
            <div
              style={{
                height: "250px",
                width: "100%",
                padding: "10px",
                marginTop: "30px",
              }}
            >

              {owlcarousel()}
             
            </div>
          </div>
        </section>
      </div>
      {/* Associate Partners End*/}
      {/* Footer Start */}
      <custom-footer />
      {/* Footer End */}
      {/* Back to Top */}
      <a href="#" className="btn btn-lg btn-lg-square back-to-top pt-2">
        <i className="bi bi-arrow-up home-text" />
      </a>
    </div>
  );
}

export default Home