// Create a context
import React, { createContext, useState,useRef } from "react";
import { useEffect } from "react";
import Loading from "./Loading";
import Metrixfunction from "./MetrixFunction";

export const MyContext = createContext();

export const GlobalValues = ({ children }) => {
 

     const Url="https://app.mykaasu.com";
    // const Url="http://65.1.100.95:6500";
    const metrixserver="https://metrics.mykaasu.com";
    // const Url="http://192.168.1.7:6500";
   // const Url="http://65.0.65.163:6500";
    const paymentUrl="http://65.0.65.163:8000";


    const [utilityFile, setUtilityFile] = useState([]);
    const [userSignFile, setuserSignFile] = useState([]);
    const [userChequeFile, setuserChequeFile] = useState([]);
    const [userPanFile, setuserPanFile] = useState([]);
    const [userEmpIdFile, setEmpIdFile] = useState([]);
    
    const [userSalarySlipPdf, setSalarySlipPdf] = useState([]);
    const [finalsalarySlipArr,setfinalsalarySlipArr] = useState([]);
    const [userJoiningLetterPdf, setuserJoiningLetterPdf] = useState([]);
    const [openDropdown, setOpenDropdown] = useState(false);


    const [openChat, setOpenChat] = useState(false);
    const [chatMessage, setChatMessage] = useState("");
    const [ticketCreated, setTicketCreated] = useState(false);
    const [newQuestion, setNewQuestion] = useState('');
    const [userBankStatementPdf, setBankStatementPdf] = useState([]);
    const [createNewTicket,setCreateNewTicket] = useState(false);
    const [ticketStatus, setTicketStatus] = useState("");
    const [notification, setNotification] = useState("yes");
    const [dummyLoading, setDummyLoading] = useState(false);
    const [chatflag, setchatflag] = useState(false);
    const [ticketId,setTicketId] = useState("");
    const [refIsCleared, setRefIsCleared] = useState(true);
    const [supportResponse, setSupportResponse] = useState(false);


    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [hasScrolled, setHasScrolled] = useState(false);

    // const [isFilesUploaded, setisFilesUploaded] = useState({
    //   employeeId: true,
    //   utilityBill : true,
    //   joiningLetter : true,
    //   salarySlip : true,
    //   bankStatement : true,
    //   panCard : true
    // });

    const [isFilesUploaded, setisFilesUploaded] = useState(true)



    
    const [sameAddress, setsameAddress] = useState("");


  const [accountdetails,setaccountdetails]=useState({accountId:"",apiKey:""})

  const [userinfo, setuserinfo] = useState({
    loanStatus: "",
    userName: "",
    userEmail: "",
    userDOB: "",
    currentCountry: "",
    currentState: "",
    currentCity: "",
    currentDistrict: "",
    currentPincode: "",
    currentHouseNo: "",
    currentStreet: "",
    // currentArea:"",
    currentLandmark: "",
    permanentCountry: "",
    permanentState: "",
    permanentCity: "",
    permanentDistrict: "",
    permanentPincode: "",
    permanentHouseNo: "",
    permanentStreet: "",
    // permanentArea:"",
    permanentLandmark: "",
    userProfilePic: "",
    userAge: 0,
    userGender: "",
    userFatherName: "",
    userMotherName: "",
    userMaritalStatus: "",
    userCommunity: "",
    userNationality: "",
    userGST:"",
    userBloodGroup: "",
    userEducation: "",
    userResidentialOwnerShip: "owned",
    passcode: "SG5kQjZEVVV1ajBRN29jQytqZldIUT09",
    utilityBillImgUrl:"",
    utilityBillType:"",
    userDependents:""
  });

  const [initialuserinfo,setinitialuserinfo]=useState({})

  const [initialworkinfo,setinitialworkinfo]=useState("")

  const [initialbankDetails,setinitialbankdetails]=useState({})

  const [workinfoposition, setposition] = useState({
    companyLatitude: "13.0843",
    companyLongitude: "80.2705",
  });

  const [workinfo, setworkinfo] = useState({
    companyEmail: "",
    companySalary: "",
    companyJoinDate: "",
    companyEmployeeId: "",
    salarySlip: [],
    companyName: "",
    companyType: "",
    companyDesignation: "",
    companyCity: "",
    companyPremises:"",
    companyDistrict: "",
    companyState: "",
    companyCountry: "",
    companyPincode: "",
    companyStreet: "",
    companyLandmark: "",
    companyTotalExp:"0",
    companyIdPhoto:"",
    companyJoiningLetter: "",

  });

  const [userverify, setuserverify] = useState(false);

  const [workverify, setworkverify] = useState(false);

  const [aadhaarverify, setaadhaarverify] = useState(false);

  const [panverify, setpanverify] = useState(false);

  const [pandata,setpandata]=useState({
    panno:"",
    panImg:"",
    panName:""
  })

  const [aadhardata, setaadhardata] = useState({
    aadharNo: "",
    name: "",
    dob: "",
    address: "",
  });


  const[loandetails,setloandetails]=useState({
    loanAmount: 0,
    monthlyEMI: 0,
    loanDuration: 0,
    loanInterestRatePct: 0,
    processingCharges: 0,
    altcontact1: "",
    altcontact2: "",
    altname1: "",
    altname2: "",
    signimgurl: "",
    chequeimgurl: "",
    altrelation1:"",
    altrelation2:"",
  })


  const [bankDetails, setBankDetails] = useState({
    bankAcctName: "",
    bankAcctNum: "",
    bankName: "",
    bankIfsc: "",
    bankStatement :"",
  });

  const [reBankAccNo, setReBankAccNo] = useState("");


  const [eligibilitydetails,seteligibilitydetails]=useState({loancount:50000,loanperiod:6,termagree:false})


  const [usercurrentPosition,setCurrentuserPosition]=useState({userLatitude:"13.0843",userLongitude:"80.2705"})

   const convertMMDDYYYYFormat = (dateStr) => {
     const [month, day, year] = dateStr.split("/");
    //  console.log("checking date payload",day,month,year)
     return `${month}/${day}/${year}`;
   };
 

  const getFileNameFromUrl = (url,maxLength) => {

    try{

        if (typeof url !== 'string') {
          return ''; // Return an empty string if the URL is not a string
        }

        // Split the URL by '/'
        const parts = url.split('/');
        // Get the last part of the URL which is the filename
        const filename = parts[parts?.length - 1];
        // Split the filename by '-'
        const nameParts = filename.split('__');
        // Return the part after the hyphen

        
        let fullFileName='';
        // if(nameParts.length > 2){
      
        //     nameParts.forEach((item,index)=>{
        //       if(index !== 0){
        //         fullFileName += item;
        //       }
        //     })
          
        // }else{
        //   fullFileName = nameParts[1];
        // }
          fullFileName = nameParts[nameParts.length-1];
      
      
        let exctension = fullFileName.split(".");
        exctension = exctension[exctension.length-1];
      
      
        return fullFileName.length > maxLength? fullFileName.slice(0, maxLength-4) + "... ." + exctension: fullFileName;
      }catch (error){
          return '';
      }
  };
  

  function comparevalues(obj1,obj2) {

 

    

    let output=false
   

    for (let key in obj1) {
      if (obj2.hasOwnProperty(key)) {


       if(obj1[key] !== obj2[key]){
          output=true
       }
       

    
      } 
    }
    if(initialuserinfo.userLatitude!== usercurrentPosition.userLatitude || initialuserinfo.userLongitude!==usercurrentPosition.userLongitude)
      {
        output=true
      }
    return output
  }
  function compareworkvalues(obj1,obj2) {


    let output=false
   

    for (let key in obj1) {
      if (obj2.hasOwnProperty(key)) {
        
       if(obj1[key].toString() !== obj2[key].toString()){
         output=true
       }
       

    
      } 
    }
    if(initialworkinfo.userLatitude!== workinfoposition.userLatitude || initialworkinfo.userLongitude!==workinfoposition.userLongitude)
      {
        output=true
      }
    return output
  }

  function comparebankvalues(obj1,obj2) {

     let output=false
   

    for (let key in obj1) {
      if (obj2.hasOwnProperty(key)) {
        if(key!=="bankAcctName")
        {
          if(obj1[key] !== obj2[key]){
              output=true
          }
      }

    
      } 
    }
    if(initialbankDetails.bankStatement !== userBankStatementPdf[0].url)
      {
        output=true
      }
    return output
  }

 

  const getUserInfo = async () => {
      if (localStorage.getItem("accId") && localStorage.getItem("apiKey") ) {
      try {
        const response = await fetch(
          `${Url}/business/getDetails`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              accId:localStorage.getItem("accId"),
              apiKey: localStorage.getItem("apiKey"),
              type: "user",
            }),
          }
        );
        const resJson = await response.json();
         if(resJson.Success)
          {
            setinitialuserinfo(resJson.Success);
          }
        return resJson;
      }
       catch (error) {
        console.log("Error :", error);
      }
    }
  };

  async function getWorkInfoApi() {

    
 try{
   const response= await fetch(`${Url}/business/getDetails`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Authorization': `Bearer ${payload.apiKey}` // Include API key in headers if necessary
      },
      body: JSON.stringify({
        accId:localStorage.getItem("accId"),
        apiKey: localStorage.getItem("apiKey"),
        type: "work",
      }),
    })
    const resJson = await response.json();
     
    if(resJson.Success)
      {
        setinitialworkinfo(resJson.Success);
        // if()
        // setfinalsalarySlipArr(resJson.Success.salarySlip);
        setSalarySlipPdf((prev)=> (resJson.Success.salarySlip.map(value => ({url:value}) )))
        // setEmpIdFile([resJson.Success.companyIdPhoto]);
        if(resJson.Success.companyJoiningLetter.length>0)
          {
            setuserJoiningLetterPdf([resJson.Success.companyJoiningLetter])
          }
        
        if(resJson.Success.companyIdPhoto.length>0)
          {
            setEmpIdFile([resJson.Success.companyIdPhoto])
          }
      }
    return resJson;
}
catch(err){
  console.log(err, "error");
}
  }


  const updateuserinfo=async(pathname)=>{

    

    const payload = {
      accId: localStorage.getItem("accId"),
      apiKey: localStorage.getItem("apiKey"),
      userName: userinfo.userName,
      userDOB: convertMMDDYYYYFormat(userinfo.userDOB),
      currentCountry: "India",
      currentCity: userinfo.currentCity,
      currentState: userinfo.currentState,
      currentDistrict: userinfo.currentDistrict,
      currentPincode: userinfo.currentPincode,
      currentHouseNo: userinfo.currentHouseNo,
      currentStreet: userinfo.currentStreet,
      currentLandmark: userinfo.currentLandmark,
      permanentCountry: "India",
      permanentCity: userinfo.permanentCity,
      permanentState: userinfo.permanentState,
      permanentDistrict: userinfo.permanentDistrict,
      permanentPincode: userinfo.permanentPincode,
      permanentHouseNo: userinfo.permanentHouseNo,
      permanentStreet: userinfo.permanentStreet,
      permanentLandmark: userinfo.permanentLandmark,
      userLatitude: usercurrentPosition.userLatitude,
      userLongitude: usercurrentPosition.userLongitude,
      userFatherName: userinfo.userFatherName,
      userMotherName: userinfo.userMotherName,
      userMaritalStatus: userinfo.userMaritalStatus,
      userCommunity: "NA", // Temporarily we don't need this field, SO "NA" hardcoded. In future, If Needed replace "NA" with userinfo.userCommunity.
      userNationality: "NA", // Temporarily we don't need this field, SO "NA" hardcoded. In future, If Needed replace "NA" with userinfo.userNationality.
      userBloodGroup: "NA",
      userDependents: userinfo.userDependents,
      userEducation: userinfo.userEducation,
      userResidentialOwnerShip: userinfo.userResidentialOwnerShip,
      utilityBillType: userinfo.utilityBillType,
      utilityBillImgUrl: userinfo.utilityBillImgUrl,
      // userGST:userinfo.userGST
    };

    if(userinfo.userGST && userinfo.userGST != "" ){
      payload["userGST"] = userinfo.userGST
    }

 

    

       

    let value=comparevalues(userinfo,initialuserinfo)
 

    if(!value)
      {
       

        return {Warning:"You have made no changes,please made any change before update"}
      }
  
  try{

    const response= await fetch(`${Url}/business/updateUserDetails`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Authorization': `Bearer ${payload.apiKey}` // Include API key in headers if necessary
      },
      body: JSON.stringify(payload),
    })
    
    const resJson = await response.json();
    Metrixfunction(metrixserver,"updateUserDetails", "UPDATE");

    
    
    return resJson;
  }
  catch(err)
  {
    console.log(err,"error")
  }
     
  }


  const updateworkinfo=async(pathname)=>{

    
  

     
  

    let value=compareworkvalues(workinfo,initialworkinfo)
    

    if(!value)
      {
       

        return {Warning:"You have made no changes,please made any change before update"}
      }
  
  try{

    const payload = {
      accId: localStorage.getItem("accId"),
      apiKey: localStorage.getItem("apiKey"),
      companyName: workinfo.companyName,
      companyType: workinfo.companyType,
      companyDesignation: workinfo.companyDesignation,
      companyCity: workinfo.companyCity,
      companyDistrict: workinfo.companyDistrict,
      companyState: workinfo.companyState,
      companyCountry: "India",
      companyPincode: workinfo.companyPincode,
      companyStreet: workinfo.companyStreet,
      companyLandmark: workinfo.companyLandmark,
      companyPremises: workinfo.companyPremises,
      companyLatitude: workinfoposition.companyLatitude,
      companyLongitude: workinfoposition.companyLongitude,
      companySalary: workinfo.companySalary,
      companyJoinDate: convertMMDDYYYYFormat(workinfo.companyJoinDate),
      companyEmployeeId: workinfo.companyEmployeeId,
      companyPaySlip: workinfo.salarySlip,
      companyTotalExp: workinfo.companyTotalExp,
      companyIdPhoto: userEmpIdFile[0]?.url || userEmpIdFile[0],
      companyJoiningLetter:
        userJoiningLetterPdf[0]?.url || userJoiningLetterPdf[0],
    };

 


    const response= await fetch(`${Url}/business/updateWorkDetails`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Authorization': `Bearer ${payload.apiKey}` // Include API key in headers if necessary
      },
      body: JSON.stringify(payload),
    })
    
    const resJson = await response.json();
    Metrixfunction(metrixserver, "updateWorkDetails", "UPDATE");

     
    return resJson;
  }
  catch(err)
  {
    console.log(err,"error")
    return {Error:err}
  }
     
  }


  const updatebank=async(pathname)=>{

    let value=comparebankvalues(bankDetails,initialbankDetails)
     
    if(!value)
      {
        

        return {Warning:"You have made no changes,please made any change before update"}
      }
  

    try{

      let payload={
        accId: localStorage.getItem("accId"),
        apiKey: localStorage.getItem("apiKey"),
        bankAcctName: bankDetails.bankAcctName,
        bankAcctNum: bankDetails.bankAcctNum,
        bankIfsc: bankDetails.bankIfsc,
        bankName: bankDetails.bankName,
        bankStatement: userBankStatementPdf[0].url ,
      }
 

      const response= await fetch(`${Url}/business/updateBankDetails`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Authorization': `Bearer ${payload.apiKey}` // Include API key in headers if necessary
        },
        body: JSON.stringify(payload),
      })

    
      const resJson = await response.json();
      Metrixfunction(metrixserver, "updateBankDetails", "UPDATE");

    
    return resJson;
    }
    catch(err){
      console.log(err,"error")
    }
  }

 useEffect(()=>{
  if(localStorage.getItem("apiKey") && localStorage.getItem("accId"))
    {
      setaccountdetails({
          apiKey:localStorage.getItem("apiKey"),
          accountId:localStorage.getItem("accId")
      })
    }

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
 },[])





  return (
  
    <MyContext.Provider
      value={{
        Url,
        userinfo,
        setuserinfo,
        setinitialuserinfo,
        initialuserinfo,
        userverify,
        setuserverify,
        workinfo,
        setworkinfo,
        workverify,
        setworkverify,
        pandata,
        setpandata,
        eligibilitydetails,
        seteligibilitydetails,
        getUserInfo,
        usercurrentPosition,
        setCurrentuserPosition,
        getWorkInfoApi,
        loandetails,
        setloandetails,
        aadhardata,
        setaadhardata,
        aadhaarverify,
        setaadhaarverify,
        bankDetails,
        setBankDetails,
        reBankAccNo,
        setReBankAccNo,
        updateuserinfo,
        updateworkinfo,
        workinfoposition,
        setposition,
        updatebank,
        initialbankDetails,
        setinitialbankdetails,
        utilityFile,setUtilityFile,userSignFile,
        setuserSignFile,userChequeFile,
        setuserChequeFile,
        userPanFile,
        setuserPanFile,
        userEmpIdFile,
        setEmpIdFile,
        panverify,
        setpanverify,
        setsameAddress,
        sameAddress,
        accountdetails,
        userSalarySlipPdf, setSalarySlipPdf,
        userJoiningLetterPdf, setuserJoiningLetterPdf,
        userBankStatementPdf, setBankStatementPdf,
        openChat,setOpenChat,
        chatMessage,setChatMessage,
        ticketCreated,setTicketCreated,
        newQuestion, setNewQuestion,
        createNewTicket,setCreateNewTicket,
        finalsalarySlipArr,setfinalsalarySlipArr,
        ticketStatus,setTicketStatus,
        isFilesUploaded, setisFilesUploaded,
        getFileNameFromUrl,
        notification,setNotification,
        windowWidth,
        openDropdown, setOpenDropdown,
        metrixserver,
        hasScrolled,
        setHasScrolled,
        paymentUrl,
        dummyLoading,setDummyLoading,
        chatflag,setchatflag,
        ticketId,setTicketId,
        refIsCleared,setRefIsCleared,
        supportResponse,setSupportResponse,
      }}
    >
      
      {(accountdetails.apiKey.length==="" && accountdetails.accountId.length==="")?<Loading/>:children}
    </MyContext.Provider>
  );
};
