import React from 'react'
import '../styles/componentStyles/Error404.css'
import { ArrowRight } from 'react-bootstrap-icons';
import CustomHeader from './customHeader';

function Error404() {
 
  return (
    <>
    <CustomHeader/>
    <div className='error404-main'>
      <p className='error404-text'>404</p>
      <div className='error404-content-text-wrapper'>
        <p className='error404-content-text'>So sorry,</p>
        <p className='error404-content-text'>we couldn't find what you were looking for...</p>
        <button onClick={() =>{
          if (localStorage.getItem("accId") && localStorage.getItem("apiKey")) {
            window.location.href = "/dashboard";
          } else {
          window.location.href = "/home";
          }
        } } className='error404-button'>Back to the home page <ArrowRight /></button>
      </div>
    </div>
    </>
  )
}

export default Error404;