import React, { useState, useEffect, useContext,  } from "react";
import { useNavigate } from "react-router-dom";

import '../../styles/screenStyles/Profile.css';
import { ArrowRightShort, BoxArrowRight, ChatLeft, Gear, Person,  } from "react-bootstrap-icons";
import Metrixfunction from "../../components/MetrixFunction";
import { MyContext } from "../../components/GlobalValues";


function Profile({ handlelogout }) {
    const navigate = useNavigate()

    const {  metrixserver} = useContext(MyContext);



    // const [notification, setNotification] = useState(false)
    // const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [userMobileNo, setUserMobileNo] = useState('')
    const [userName, setUserName] = useState('')

    const profileContent = [
        {
            name: 'My Account',
            icon: <Person className="profile-card-icon" />,
            link: "/account"
        },
        {
            name: 'Settings',
            icon: <Gear className="profile-card-icon" />,
            link: "/settings"
        },
        {
            name: 'Support',
            icon: <ChatLeft className="profile-card-icon" />,
            link: "/tickets"
        },
        // {
        //     name: 'Grivance Redressal',
        //     icon: <PersonSlash className="profile-card-icon" />,
        //     link: "/deactivate-account"
        // },
        {
            name: 'Log Out',
            icon: <BoxArrowRight className="profile-card-icon profile-logout" />,
            link: "/"
        },

    ]

    useEffect(() => {
        const no = localStorage.getItem('mobileNo')
        const name = localStorage.getItem('userName')
        setUserMobileNo(no);
        setUserName(name)
        Metrixfunction(metrixserver,"static", "PAGE_ONLOAD");

    },[])

    // useEffect(() => {
    //     const handleResize = () => {
    //         setWindowWidth(window.innerWidth);
    //     };

    //     window.addEventListener('resize', handleResize);

    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, []);

    // const navigateScreenWidth = 500;

    const navigatehome = async (name, link) => {
        if (name === "Log Out") {
            // setChatMessage([])
            // setTicketId(null)
            // setOpenChat(false)
            // setTicketCreated(false)
            // setMessages([])
            // setNewQuestion('')
            // localStorage.clear()
            // await handlelogout()
            // navigate("/")

            // setworkinfo({
            //   companyEmail: "",
            //   companySalary: "",
            //   companyJoinDate: "",
            //   companyEmployeeId: "",
            //   salarySlip: [],
            //   companyName: "",
            //   companyType: "",
            //   companyDesignation: "",
            //   companyCity: "",
            //   companyDistrict: "",
            //   companyState: "",
            //   companyCountry: "",
            //   companyPincode: "",
            //   companyStreet: "",
            //   companyLandmark: "",
            //   companyTotalExp:0,
            //   companyIdPhoto:"",
            //   companyJoiningLetter: "",
          
            // })
      
            // setuserinfo({
            //   loanStatus: "",
            //   userName: "",
            //   userEmail: "",
            //   userDOB: "",
            //   currentCountry: "",
            //   currentState: "",
            //   currentCity: "",
            //   currentDistrict: "",
            //   currentPincode: "",
            //   currentHouseNo: "",
            //   currentStreet: "",
            //   currentLandmark: "",
            //   permanentCountry: "",
            //   permanentState: "",
            //   permanentCity: "",
            //   permanentDistrict: "",
            //   permanentPincode: "",
            //   permanentHouseNo: "",
            //   permanentStreet: "",
            //   permanentLandmark: "",
            //   userProfilePic: "",
            //   userAge: 0,
            //   userGender: "",
            //   userFatherName: "",
            //   userMotherName: "",
            //   userMaritalStatus: "",
            //   userCommunity: "",
            //   userNationality: "",
            //   userBloodGroup: "",
            //   userEducation: "",
            //   userResidentialOwnerShip: "owned",
            //   passcode: "SG5kQjZEVVV1ajBRN29jQytqZldIUT09",
            //   utilityBillImgUrl:"",
            //   utilityBillType:""
          
            // })
      
            // setpandata({
            //   panno:"",
            //   panImg:"",
            //   panName:""
            // })
      
            // seteligibilitydetails({loancount:50000,loanperiod:6})
            // setaadhardata({
            //     aadharNo: "",
            //     name: "",
            //     dob: "",
            //     address: "",
            //   })
        
            //   setBankDetails({
            //     bankAcctName: "",
            //     bankAcctNum: "",
            //     bankName: "",
            //     bankIfsc: "",
            //     bankStatement :"",
            //   })
        
            //   setloandetails({
            //     loanAmount: 0,
            //     monthlyEMI: 0,
            //     loanDuration: 0,
            //     loanInterestRatePct: 0,
            //     processingCharges: 0,
            //     altcontact1: "",
            //     altcontact2: "",
            //     altname1: "",
            //     altname2: "",
            //     signimgurl: "",
            //     chequeimgurl: "",
            //     altrelation1:"",
            //     altrelation2:"",
            //   })
        } else {
            let options={updateBreadcrumbs:false}
            navigate(link, {
                state: {
                    options:options
                },
              });

        }

    }

    return (
        <div className="profile-screen-main">
            <div className="profile-screen-container">

                <div className="profile-screen-top">
                    <div className="proile-photo-container">
                        <img className="proile-photo" src='https://mykaasu.blr1.cdn.digitaloceanspaces.com/website/images/faceHappy.png' alt="/" />
                    </div>
                    <div className="profile-name">
                        <p>{userName ? userName : 'User Name'}</p>
                        <p>+91 {userMobileNo}</p>
                    </div>
                </div>

                <div className="profile-screen-bottom">

                    <div className="profile-screen-content">
                        {profileContent.map((value, ind) => {
                            return value.name === "Log Out" ? (
                              <a
                                href={value.link}
                                key={ind}
                                className="profile-content-card"
                                id={"profile-content-card1"}
                                onClick={async () => {
                                  localStorage.clear();
                                  await handlelogout();
                                }}
                              >
                                <span
                                  className={`d-flex align-items-center gap-3 ${
                                    value.name == "Log Out" && "profile-logout"
                                  }`}
                                >
                                  {value.icon}
                                  <p
                                    className={`profile-card-content-text ${
                                      value.name == "Log Out" &&
                                      "profile-logout"
                                    }`}
                                  >
                                    {value.name}
                                  </p>
                                </span>
                                <ArrowRightShort
                                  className={`profile-card-icon ${
                                    value.name == "Log Out" && "profile-logout"
                                  }`}
                                />
                              </a>
                            ) : (
                              <div
                                key={ind}
                                className="profile-content-card"
                                id={"profile-content-card2"}
                                onClick={() => {
                                  navigatehome(value.name, value.link);
                                }}
                              >
                                <span
                                  className={`d-flex align-items-center gap-3 ${
                                    value.name == "Log Out" && "profile-logout"
                                  }`}
                                >
                                  {value.icon}
                                  <p
                                    className={`profile-card-content-text ${
                                      value.name == "Log Out" &&
                                      "profile-logout"
                                    }`}
                                  >
                                    {value.name}
                                  </p>
                                </span>
                                <ArrowRightShort
                                  className={`profile-card-icon ${
                                    value.name == "Log Out" && "profile-logout"
                                  }`}
                                />
                              </div>
                            );
                        })
                        }

                    </div>
                </div>
            </div>
        </div>

    )
}

export default Profile;
