import React, { useEffect, useRef, useState } from "react";
import "../../styles/screenStyles/Aadhardetails.css";
import InputField from "../../components/InputField";
import { Check, CreditCard, Edit, Home, Phone } from "react-feather";
import { Calendar2Check, Person } from "react-bootstrap-icons";
import Modal from "../../components/Modal";
import OtpInput from "../../components/OtpInput";
// import ResizableTextarea from "../../components/textareatype";
import Alert from "../../components/Alert";
import {useNavigate } from "react-router-dom";
import { useContext } from "react";
import { MyContext } from "../../components/GlobalValues";
import Loading from "../../components/Loading";
import Metrixfunction from "../../components/MetrixFunction";
import UpdateLoader from "../../components/updateLoader";

function Aadhardetails({ component, componenttype, componentdata }) {
  // const [verify, setverify] = useState(false);

  const { Url, aadhardata, setaadhardata, aadhaarverify, setaadhaarverify, metrixserver  } =useContext(MyContext);

  const [otpflag,setotpflag]=useState(false)

  const [updateLoad,setupdateLoad]=useState(false)


  const [loadingscreen, setloading] = useState(false);

  const [otpedit,setotpedit]=useState(true)


  const formatadhaarNumber = (numberString) => {
    return numberString.replace(/(\d{4})(?=\d)/g, "$1-");
  };

  const [timeInterval, setTimeInterval] = useState(null);

  const [verifybtn, setverifybtn] = useState(false);

  const [resendotp, setresentotp] = useState(false);

  const [resenttime, setresenttime] = useState(120);

  const navigate = useNavigate();

  const [refid, setrefid] = useState("");


  const [showalert, setshowalert] = useState({
    show: false,
    msg: "",
    color: "",
  });

  const alertmessage = (msg, color) => {
    setshowalert({ ...showalert, show: true, msg: msg, type: color });

    setTimeout(() => {
      setshowalert({ ...showalert, show: false, msg: "", type: "" });
    }, 3000);
  };

  const [isModalOpen, setModalOpen] = useState(false);


  const openModal = () => {
    setModalOpen(true);
    
  
  };
  const closeModal = () => {
    setModalOpen(false);
    setresentotp(false)
  };

  const numberregex = /^[0-9]+$/;

  const modalref = useRef(null);

  const [validationErrors, setValidationErrors] = useState({
    aadharNo: "",
  });

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};


  const enablesendotp = (value, inputName) => {
    let text = value.replace(/-/g, "");
    if (numberregex.test(text) || text === "") {
      setaadhardata({ ...aadhardata, aadharNo: text });
    }

    if (text.length < 12) {
      let errorMessage = "Enter the 12 digit Aadhar number";
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        aadharNo: errorMessage,
      }));
    } else if (text.length === 12) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        aadharNo: "",
      }));
    }
  };

  const handleaadharotp = (value) => {
    if (value.length === 6) {
      handlsubmitotp(value); 
    }
  };
  const handlsubmitotp = async (otp) => {
    let query = {
      accId: localStorage.getItem("accId"),
      apiKey: localStorage.getItem("apiKey"),
      aadhaar: aadhardata.aadharNo.replace(/-/g, ""),
      otp: otp,
      refId: refid,
    };
 
 

    try {
      setotpedit(false)
      setupdateLoad(true)

      await fetch(`${Url}/business/updateAadhaar`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(query),
      })
        .then((res) => res.json())
        .then((resJson) => {
     
          if (resJson.Success) {
            alertmessage("OTP verified successfully", "success");
            setaadhardata({
              ...aadhardata,
              aadharNo: resJson.Success.aadhaarNum,
              name: resJson.Success.aadhaarName,
              dob: resJson.Success.aadhaarDOB,
              address: resJson.Success.aadhaarAddress,
            });

            clearInterval(timeInterval);
            setresenttime(120);
            closeModal();
            setaadhaarverify(true);
            setotpflag(false)

          } else {
            alertmessage(resJson.Error, "danger");
            setaadhaarverify(false);
            setresentotp(false)
          }
          setupdateLoad(false)
          setotpedit(true)
          setTimeout(() => {
            
            otpfocus()
          }, 100);

        })
        .catch((err)=>{
          console.log(err,"error")
          setotpedit(true)
          setTimeout(() => {
            otpfocus()
          }, 100);
          setupdateLoad(false)
        })
        .finally(()=>{
           Metrixfunction(metrixserver, "updateAadhaar", "UPDATE");
        })
    } catch (error) {
      console.log("Error :", error);
      setotpedit(true)
      setupdateLoad(false)
      setTimeout(() => {
            
        otpfocus()
      }, 100);


      // setMobileNoError('Invalid Mobile No');
    }
  };

  const handleresent = () => {

   
    let time = 120;
    const newinterval = setInterval(() => {
      time = time - 1;
      setresenttime(time);
      if (time === 0) {
        clearInterval(newinterval);
        setresenttime(120);
        setresentotp(true);
      }
    }, 1000);
    setTimeInterval(newinterval);
  };

  const handleverify = async () => {
    
    let aadharnumber = aadhardata.aadharNo.replace(/-/g, "");

    // setverify(true);
   
    if (aadharnumber.length < 12) {
      alertmessage("Enter the valid Aadhar number before verify", "danger");
    } else {
      setupdateLoad(true)
      let query = {
        accId: localStorage.getItem("accId"),
        apiKey: localStorage.getItem("apiKey"),
        aadhaar: aadharnumber,
      };

      try {
        setverifybtn(true);

        await fetch(`${Url}/business/generateAadhaarOTP`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(query),
        })
          .then((res) => res.json())
          .then((resJson) => {
             
            if (resJson.refId) {
              // setOtpError("Invalid OTP")
              alertmessage("OTP sent successfully", "success");
              setrefid(resJson.refId);
              setresentotp(false);
              handleresent()
              openModal();
              setotpflag(true)
            } else {
              alertmessage(resJson.Error, "danger");
            }
            setverifybtn(false);
            setupdateLoad(false)
          })
          .catch((err)=>{
            console.log("error",err)
            setupdateLoad(false)
            setverifybtn(false);

          })
          .finally(()=>{
        Metrixfunction(metrixserver,"generateAadhaarOTP", "UPDATE");

          })
      } catch (error) {
        console.log("Error :", error);
        setverifybtn(false);
        setupdateLoad(false)
        // setMobileNoError('Invalid Mobile No');
      }
    }
    // component && senddata(aadhardata);
  };

  const getaccountdetails = async () => {
    let query = {
      accId: localStorage.getItem("accId"),
      apiKey: localStorage.getItem("apiKey"),
      type: "kyc",
    };

 

    try {


      await fetch(`${Url}/business/getDetails`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(query),
      })
        .then((res) => res.json())
        .then((resJson) => {
        
          if (resJson.Success) {
            setaadhardata({
              ...aadhardata,
              aadharNo: resJson.Success.aadhaarNum,
              name: resJson.Success.aadhaarName,
              dob: resJson.Success.aadhaarDOB,
              address: resJson.Success.aadhaarAddress,
            });
            if (resJson.Success.aadhaarNum.length > 0) {
              setaadhaarverify(true);
            }
          } else {
            navigate("/dashboard");
          }
          setloading(false);
        })
        .catch((err)=>{
          console.log(err,"error")
          setloading(false);
        })
        .finally(()=>{
      Metrixfunction(metrixserver,"getDetails", "ONLOAD");

        })
    } catch (error) {
      console.log("Error :", error);
      setloading(false);
      // setMobileNoError('Invalid Mobile No');
    }
  };

  const otpfocus=()=>{
    if (isModalOpen && modalref.current) {
    const inputElement = modalref.current.querySelector(".otp-dummy-input");
    if (inputElement) {
       
      inputElement.focus();
    }
  }
  }

  useEffect(() => {
  
      otpfocus()
    
  }, [isModalOpen]);

  useEffect(() => {
    // getaadhaardetails();
    try {
      if (!component) {
        setloading(true);
        getaccountdetails();

      }
    } catch (err) {
      console.log(err, "error");
    }
  }, []);

  return loadingscreen ? (
    <Loading />
  ) : (
<>
    {updateLoad && <UpdateLoader/>}
    <div className="aadhardetails-container">
      <div
        className="aadhar-form-wrapper mt-0"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="d-flex justify-content-between align-items-center ">
          <div className="d-flex gap-4">
            <h4> Aadhaar Details </h4>{" "}
            {/* {!(component && componenttype === "preview") &&
              aadhaarverify &&
            
                <Edit
                  style={{ cursor: "pointer" }}
                  id="aadharEdit"
                  onClick={() => {
                    setaadhaarverify(false);
                    setaadhardata({
                      ...aadhardata,
                      aadharNo: "",
                      name: "",
                      dob: "",
                      address: "",
                    });
                  }}
                />
              } */}
          </div>
          {aadhaarverify && (
            <div className="d-flex align-items-center">
              <Check
                style={{ animation: "none" }}
                className="aadhar-verify"
                size={20}
              />{" "}
              <span className="aadhar-verify">Verified</span>
            </div>
          )}
        </div>
        {/* <hr /> */}

        <form
          className="inner-aadhar-wrapper row"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="col-md-6"> 
            <div className="d-flex justify-content-between align-items-center w-100">
            <label>Aadhar No</label>
            {otpflag  && <span className="haveotp-aadhaar" onClick={openModal}>
             I have an otp
             </span>}
             </div>
            <div className="mt-2"> 
              <InputField
                Id="Aadhar-Number"
                placeholder="Ex: 1234-5678-9012"
                maxLength={14}
                value={formatadhaarNumber(aadhardata.aadharNo)}
                onChangeText={(e, event) => {
                  enablesendotp(e, "aadharNo");
                }}
                editable={!aadhaarverify}
                leftIcon={<CreditCard style={{ animation: "none" }} />}
                inputType={"tel"}
                validationError={validationErrors.aadharNo}
              />
            </div>
          </div>

          {aadhaarverify ? (
            <>
              <div className="col-md-6">
                <label>Name</label>
                <div className="mt-2">
                  <InputField
                    Id="Aadhar-Name"
                    placeholder="Name"
                    maxLength={14}
                    value={aadhardata.name}
                    editable={false}
                    // leftIcon={<i className="fa-light fa-user"></i>}
                    leftIcon={
                      <Person size={23} style={{ animation: "none" }} />
                    }
                  />
                </div>
              </div>
              <div className="mt-4 col-md-6">
                <label>Date of Birth</label>
                <div className="mt-2">
                  <InputField
                    Id="Aadhar-Dob"
                    placeholder="Date No"
                    maxLength={14}
                    value={aadhardata.dob}
                    editable={false}
                    leftIcon={
                      <Calendar2Check size={20} style={{ animation: "none" }} />
                    }
                  />
                </div>
              </div>

              <div className="mt-4 col">
                <label>Address</label>
                <div className="mt-2">
                  <InputField
                    Id="Aadhar-Address"
                    placeholder="Address"
                    value={aadhardata.address}
                    editable={false}
                    leftIcon={<Home size={22} style={{ animation: "none" }} />}
                  />
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {!aadhaarverify && (
            <div className="aadhar-button-wrapper">
              <button
                id="Aadhar-Verify-ModalBtn"
                className="aadhar-veridybtn"
                onClick={() => {
                  timeInterval<120 &&  clearInterval(timeInterval);

                  handleverify();
                }}
                disabled={verifybtn}
              >
                Verify
              </button>
            </div>
          )}
        </form>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        submitButtonPlaceHolder={"Submit OTP"}
        modalref={modalref}
        modalwidth={"600px"}
        savebtn={false}
        onhide={true}
      >
        <div className="otp-title-wrapper">
          <div  className="otp-title">
            Please enter the One Time Password to verify your account
          </div>
          <div className="otp-body" style={{ fontSize: "13px" }}>
            A One-time Password has been sent to your registered Aadhar Phone
            Number
          </div>
        </div>
        <div
          className="otpcontainer"
          onSubmit={(event) => {
            event.preventDefault();
            
          }}
        >
          <div className="mb-2">
            <OtpInput sendotp={handleaadharotp} editable={otpedit} reset={!resendotp} />
          </div>
          <div className="resent-wrapper">
            {resendotp ? (
              <button
                id="Resend-Otp-Btn" 
                className="resend-otp"
                disabled={verifybtn}
                onClick={() => {
                  if (isModalOpen && modalref.current) {
                    const inputElement =
                      modalref.current.querySelector(".otp-dummy-input");
                    if (inputElement) {
                      inputElement.focus();
                    }
                  }
                  handleverify();
                }}
              >
                Resend OTP
              </button>
            ) : (
              <span className="resent-time">Resent OTP in {formatTime(resenttime)} </span>
            )}
          </div>
        </div>
      </Modal>
      {showalert.show && (
        <Alert
          show={true}
          message={showalert.msg}
          type={showalert.type}
          duration={3000}
          onClose={() => {
            
            setshowalert({ show: false, message: "", type: "" });
          }}
        />
      )}
    </div>
    </>
  );
}

export default Aadhardetails;
