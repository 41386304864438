import React, { useState } from "react";
import "../styles/componentStyles/InputField.css";

const InputField = ({
  autoComplete,
  placeholder,
  autoCap,
  onEditing,
  containerStyle,
  inputType,
  keyboardType,
  onEndEditing,
  leftIcon,
  rightIcon,
  maxLength,
  onChangeText=()=>{},
  editable = true,
  value,
  containerclassname,
  onKeytext=()=>{},
  onFocusText = () => {},
  onBlur = () => {},
  onCopy = () => {},
  onCut = () => {},
  onPaste = () => {},
  inputref,
  validationError,
  enableTab = true,
  Id=""
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div style={{position:"relative"}}>
      <div
        className={`input-field-container ${validationError !== "" || validationError === undefined ? validationError?"warninginputfield":"" : "" } ${containerclassname}`}
        style={{
          ...containerStyle,
         
        }}
      >
        {leftIcon && leftIcon}
        <input
          className={`input-field ${isFocused ? "focused" : ""}`}
          placeholder={placeholder}
          type={inputType}
          autoComplete={autoComplete || "off"}
          autoCapitalize={autoCap ? "characters" : "none"}
          maxLength={maxLength}
          value={value}
          onChange={(e) => onChangeText(e.target.value)}
          onKeyDown={(e)=>onKeytext(e)}
          onFocus={() => {
            setIsFocused(true);
            onFocusText();
            // onFocusText(true);
          }}
          tabIndex={enableTab ? "0" : "-1"}
          onBlur={() => {
            setIsFocused(false);
            onBlur();
          }}
          disabled={!editable}
          ref={inputref}
          onCopy={onCopy}
          onCut={onCut}
          onPaste={onPaste}
          id={Id}
          // ref={(el) => (inputref.current.userName = el)}
        />
        {rightIcon && rightIcon}
      </div>
      {validationError && <p className="validation-error">{validationError}</p>}
    </div>
  );
};

export default InputField;
