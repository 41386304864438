import React, { useEffect, useState, useRef, useContext, } from 'react'
import '../../styles/screenStyles/LoanCalculator.css';
import Slider from '../../components/Slider';
import { useLocation } from 'react-router-dom';
import { MyContext } from '../../components/GlobalValues';
import Metrixfunction from '../../components/MetrixFunction';


export default function LoanCalculatorapp({showcontent=true}) {

    const {  metrixserver} = useContext(MyContext);

    const [loanAmount, setLoanAmount] = useState(300000);
    const [interestRate, setInterestRate] = useState(18);
    const [tenure, setTenure] = useState(12);

    const [showpayments,setshowpayments]=useState(true)



    const [monthlyEMI, setMonthlyEMI] = useState(0)
    const [totalInterest, setTotalInterest] = useState(0)
    const [totalAmount, setTotalAmount] = useState(0)

    const handleLoanAmountChange = (newValue) => {
        setLoanAmount(newValue);
    };

    const handleinterestRateChange = (newValue) => {
        setInterestRate(newValue);
    }

    const handleTenureChange = (newValue) => {
        setTenure(newValue);
    };

    function formatLoanDuration(months) {
        const years = Math.floor(months / 12);
        const remainingMonths = months % 12;

        let durationString = '';

        if (years > 0) {

            durationString += `${years} year${years > 1 ? 's' : ''}`;
        }

        if (remainingMonths > 0) {
            if (years > 0) {
                durationString += ' ';
            }
            durationString += `${remainingMonths} month${remainingMonths > 1 ? 's' : ''}`;
        }

        return durationString || '0 months';
    }

    function calculateLoanDetails(loanAmount, interestRate, loanTenure) {
        if (loanAmount === 0 || interestRate === 0 || loanTenure === 0) {
            return { emi: 0, totalAmount: 0, totalInterest: 0 };
        }

        const monthlyInterestRate = interestRate / 12 / 100;

        const emi = (loanAmount * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, loanTenure)) / (Math.pow(1 + monthlyInterestRate, loanTenure) - 1);
        const roundedEmi = Math.round(emi);

        const totalAmount = emi * loanTenure;
        const roundedTotalAmount = Math.round(totalAmount);

        const totalInterest = roundedTotalAmount - loanAmount;
        const roundedTotalInterest = Math.round(totalInterest);

        return { emi: roundedEmi, totalAmount: roundedTotalAmount, totalInterest: roundedTotalInterest, };
    }

    const actualIntrest = (totalInterest, tenure, loanAmount) => {
        const actualIntrest = totalInterest / (tenure / 12)
        const onePercentLoan = loanAmount * 0.01
        const result = (actualIntrest / onePercentLoan).toFixed(2)
        return result

    }

    useEffect(() => {
        const { emi, totalAmount, totalInterest } = calculateLoanDetails(loanAmount, interestRate, tenure);

        setMonthlyEMI(emi);
        setTotalInterest(totalInterest);
        setTotalAmount(totalAmount);

    }, [loanAmount, interestRate, tenure]);


    const calculateEMI = (loanAmount, interestRate, tenure) => {
        // if (loanAmount === 0 || interestRate === 0 || tenure === 0) return [];
        const interestRatePerMonth = interestRate / 12 / 100;
        const emi = (loanAmount * interestRatePerMonth * Math.pow(1 + interestRatePerMonth, tenure)) / (Math.pow(1 + interestRatePerMonth, tenure) - 1);

        let remainingAmount = loanAmount;
        let emiBreakup = [];

        for (let i = 1; i <= tenure; i++) {
            const interest = remainingAmount * interestRatePerMonth;
            const principal = Math.min(emi - interest, remainingAmount);
            remainingAmount -= principal;
            const openingBalance = remainingAmount + principal;


            emiBreakup.push({
                month: i,
                emi: Math.round(emi),
                openingBalance: Math.round(openingBalance),
                interest: Math.round(interest),
                currentPrincipal: Math.round(principal),
                remainingAmount: Math.round(remainingAmount)
            });

            if (remainingAmount <= 0) break;
        }

        return emiBreakup;
    };

    const upcomingPayments = calculateEMI(loanAmount, interestRate, tenure)



    const radius = 115;
    const strokeWidth = 50;
    const circumference = 2 * Math.PI * radius;
    const totalInterestPercentage = (totalInterest / totalAmount) * 100;
    const dashOffset = circumference - (totalInterestPercentage / 100) * circumference;
    const dashArray = `${totalInterestPercentage} ${circumference - totalInterestPercentage}`;


    useEffect(()=>{
       
            !showcontent && (setshowpayments(showcontent))
        
    },[])

    useEffect(()=>{
        Metrixfunction(metrixserver,"static", "PAGE_ONLOAD");
    
      },[])
    return (

        <div className='loanCalculator'>

            <div className='loanCalculator-container'>
                <p className='LonCalculator-title'>Loan Calculator</p>
                <div className='loanCalculator-main'>
                    <div className='loanCalculator-left'>

                        <div className='loanCalculator-subHeading'>
                            <p className='loanCalculator-subHeading-title'>Loan Amount</p>
                            <span className='loanCalculator-input-container'>
                                <p>₹</p>
                                {/* <input className='loanCalculator-input' value={loanAmount} maxLength={8} pattern="[0-9]*" type='text' onInput={(e) => {
                                    const newValue = e.target.value.replace(/[^0-9]/g, '');
                                    setLoanAmount(newValue)
                                    if (newValue < 1) {
                                        setLoanAmount(50000)
                                    }
                                }} placeholder='0' /> */}
                                <p>{loanAmount}</p>
                            </span>
                        </div>
                        <div className='loanCalculator-slider'>
                            <Slider min={50000} max={3000000} step={10000} onChange={handleLoanAmountChange} />
                        </div>

                        <div className='loanCalculator-subHeading'>
                            <p className='loanCalculator-subHeading-title'>Rate of interest</p>
                            <span className='loanCalculator-input-container'>
                                {/* <input className='loanCalculator-input' onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\d*\.?\d*$/.test(value)) {
                                        setInterestRate(value);
                                    }
                                    if (value <= 7) {
                                        setInterestRate(7)
                                    }
                                    if (value > 50) {
                                        setInterestRate(50)
                                    }
                                }} value={interestRate} maxLength={3} pattern="[0-9]*" type='text' placeholder='0' /> */}
                                <p className='Actual-intrest-text'>{" "}Annual Percentage Report ({interestRate}%)</p>
                            </span>
                        </div>
                        <div className='loanCalculator-slider'>
                            <Slider min={7} max={50} step={0.1} initialValue={interestRate} onChange={handleinterestRateChange} />
                        </div>

                        <div className='loanCalculator-subHeading'>
                            <p className='loanCalculator-subHeading-title'>Loan Tenure</p>
                            <span className='loanCalculator-input-container'>
                                {/* <input className='loanCalculator-input' onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\d*$/.test(value)) {
                                        setTenure(value);
                                    }
                                    if (value <= 3) {
                                        setTenure(3)
                                    }
                                    if (value > 96) {
                                        setTenure(96)
                                    }
                                }} value={tenure} maxLength={2} pattern="[0-9]*" type='text' placeholder='0' /> */}
                                <p>{formatLoanDuration(tenure)}</p>
                            </span>
                        </div>
                        <div className='loanCalculator-slider m-0 '>
                            <Slider min={3} max={96} step={1} initialValue={tenure} onChange={handleTenureChange} />
                        </div>

                    </div>

                    <div className='loanCalculator-right'>

                        <div className='loanCalculator-result-container'>
                            <div className='loanCalculator-result-head '>
                                <p>Opening Balance</p>
                                <p>{loanAmount.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            </div>
                            <div className='loanCalculator-result'>
                                <p>Monthly EMI</p>
                                <p>{monthlyEMI.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            </div>

                            <div className='loanCalculator-result'>
                                <p>Total Interest</p>
                                <p>{totalInterest.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            </div>

                            <div className='loanCalculator-result'>
                                <p>Total Amount</p>
                                <p>{totalAmount.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                            </div>

                        </div>

                        <div className='loanCalculator-chart-container'>
                            <svg width="90%" height="90%" viewBox="-31.25 -31.25 312.5 312.5" version="1.1" xmlns="http://www.w3.org/2000/svg" className='loanCalculator-chart-svg'>
                                <circle r={radius} cx="125" cy="125" fill="transparent" stroke="#EAEAEA" strokeWidth={strokeWidth} strokeDasharray={`${circumference}px`} strokeDashoffset="0" />
                                <circle r={radius} cx="125" cy="125" fill="transparent" stroke="#E32f45" strokeWidth={strokeWidth} strokeLinecap="butt" strokeDashoffset={`${dashOffset}px`} strokeDasharray={`${circumference}px`} />
                            </svg>

                            <div className='loanCalculator-chart-detail'>

                                <div className='loanCalculator-chart-detail-value'>
                                    <p>Principal Amount</p>
                                    <span className='loanCalculator-span-inactive'></span>
                                </div>
                                <div className='loanCalculator-chart-detail-value'>
                                    <p>Interest Amount</p>
                                    <span className='loanCalculator-span-active'></span>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                {(showpayments ) &&
                    <>
                        <p className='LonCalculator-title  mt-4'>Amortization </p>
                        <div className='loanCalculator-table'>
                            <div className='loanCalculator-table-head'>
                                <span className='loanCalculator-table-head-data'>Month</span>
                                <span className='loanCalculator-table-head-data'>Opening Balance</span>
                                <span className='loanCalculator-table-head-data'>Interest Amount</span>
                                <span className='loanCalculator-table-head-data'>Principle Deducted</span>
                                <span className='loanCalculator-table-head-data'>Closing Balance</span>
                            </div>
                            {upcomingPayments.map((value, ind) => {
                                return (
                                    <div className='loanCalculator-table-body' key={ind}>
                                        <span className='loanCalculator-table-row'>{value.month}</span>
                                        <span className='loanCalculator-table-row'>{value.openingBalance.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</span>
                                        <span className='loanCalculator-table-row'>{value.interest.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</span>
                                        <span className='loanCalculator-table-row'>{value.currentPrincipal.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</span>
                                        <span className='loanCalculator-table-row'>{value.remainingAmount.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</span>
                                    </div>
                                )
                            })

                            }

                        </div>
                    </>
                }

            </div>

        </div>

    )
}