import React, { useContext, useEffect, useState } from "react";
import "../../styles/screenStyles/Loanapply.css";
import InputField from "../../components/InputField";
import { Phone, User } from "react-feather";
import { MyContext } from "../../components/GlobalValues";
import Dropdown from "../../components/DropDown";
import { validationRegex } from "../../utlis/Regex";
import Metrixfunction from "../../components/MetrixFunction";
import Loading from "../../components/Loading";

function Additionalinformation({ componenttype }) {

  // const {  Url, loandetails, setloandetails,userSignFile,setuserSignFile,userChequeFile,setuserChequeFile} = useContext(MyContext);
  const {
    Url,
    setpandata,
    aadhardata,
    setaadhardata,
    setaadhaarverify,
    bankDetails,
    setBankDetails,
    setReBankAccNo,
    initialbankDetails,
    setinitialbankdetails,
    setpanverify,
    setBankStatementPdf,
    metrixserver,
    loandetails, setloandetails
  } = useContext(MyContext);

  const [loadingscreen, setloading] = useState(true)


  const [componentedit,setcomponentedit]=useState(false)

  const [validationErrors, setValidationErrors] = useState({
    altcontact1: "",
    altcontact2: "",
    altname1: "",
    altname2: "",
  });


  const numberregex = /^[0-9]*$/;
  const nameregex = /^[a-zA-Z ]+$/;
  const userNameregex = validationRegex["userName"];


  const alt1relationarr=["Father","Mother","Sister","Spouse","Brother","GrandParent","Uncle","Cousin","Aunt"]
  const alt2relationarr=["Friend","Colleague"]

  const handlechange = (e, inputName) => {
    if (inputName === "altcontact1" || inputName === "altcontact2") {
      if (numberregex.test(e) || e === "") {
        setloandetails((prevState) => ({
          ...prevState,
          [inputName]: e, // Use computed property names to dynamically set the key
        }));
        if (e.length < 10) {
          let errorMessage = "Enter the 10 digit Phone number";
          setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [inputName]: errorMessage,
          }));
        } else if (e.length === 10) {
          setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [inputName]: "",
          }));
        }
      }

    } else if (inputName === "altname1" || inputName === "altname2") {
      if (e === "" || nameregex.test(e)) {
        setloandetails((prevState) => ({
          ...prevState,
          [inputName]: e, // Use computed property names to dynamically set the key
        }));

         if (e.length < 3 || !userNameregex.test(e) ) {
          let errorMessage = "Enter the correct name";
          setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [inputName]: errorMessage,
          }));
        } else if (e.length >2 ) {
          setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [inputName]: "",
          }));
        }
      }
    }
  };


  const getaccountdetails = async (step) => {
    let query = {
      accId: localStorage.getItem("accId"),
      apiKey: localStorage.getItem("apiKey"),
      type: "kyc",
    };

     

    try {
      // setloadingscreen(true)

      await fetch(`${Url}/business/getDetails`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(query),
      })
        .then((res) => res.json())
        .then((resJson) => {
          
          if (resJson.Success) {
           
            setpandata({ panno: resJson.Success.panNum,panName:resJson.Success.panName,panImg:resJson.Success.panImgUrl });
            resJson.Success.panName.length>0?setpanverify(true):setpanverify(false)
            setaadhardata({
              ...aadhardata,
              aadharNo: resJson.Success.aadhaarNum,
              name: resJson.Success.aadhaarName,
              dob: resJson.Success.aadhaarDOB,
              address: resJson.Success.aadhaarAddress,
            });
            if (resJson.Success.aadhaarNum.length > 0) {
              setaadhaarverify(true);
            }
          
        
          }
          setloading(false)

        })
        .finally(()=>{
      Metrixfunction(metrixserver, "getDetails", "ONLOAD");

        })
    } catch (error) {
      console.log("Error :", error);
      setloading(false)

      // setMobileNoError('Invalid Mobile No');
    }
  };


  useEffect(()=>{
    if(componenttype==="preview"){
      setcomponentedit(true)
      setloading(false)
    }
    else{
      getaccountdetails();

    }
  },[])

  return (
    loadingscreen ? <Loading /> : <div className="aadhardetails-container">
      <form
        className="aadhar-form-wrapper"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div>
          <h4> Additional information</h4>
          <p style={{fontSize:"14px",paddingLeft:"5px"}}> * Note: We call these contacts in case of an emergency.</p>
        </div>
        {/* <hr /> */}

        <div className="inner-aadhar-wrapper mb-3">
          <div className="row add-details-wrapper " >
          <div className="col-md-6 mt-2">
              <label>Family Member Relation</label>
              <div className="mt-2">
              <Dropdown
                    Id="Family-Member-Input"
                    options={alt1relationarr}
                    leftIcon={
                      <i className="fa fa-users" style={{ color: "grey" }} />
                    }
                    onSelect={(e) => setloandetails({...loandetails,altrelation1:e})}
                    drpdownWidth={"100%"}
                    drpdownHeight={"50px"}
                    placeHolder={"Select The Relation"}
                    value={loandetails.altrelation1}
                    editable={!componentedit}
                    
                    // editable={loandetails.altrelation2}
                    // validationError={validationErrors.userBloodGroup}
                  />
              </div>
            </div>
           {loandetails.altrelation1.length>0 &&  
           <>
            <div className="col-md-6 mt-2">
              <label>{loandetails.altrelation1}'s name</label>
              <div className="mt-2">
                <InputField
                  placeholder="Ex: John Doe"
                  maxLength={30}
                  Id="Family-Member-Name"
                  value={loandetails.altname1}
                  onChangeText={(e) => {
                    handlechange(e, "altname1");
                  }}
                  editable={!componentedit}
                  leftIcon={<User style={{ animation: "none" }} />}
                  validationError={validationErrors.altname1}

                />
              </div>
            </div>
            <div className="col-md-6 mt-2">
              <label>{loandetails.altrelation1}'s Phone Number</label>
              <div className="mt-2">
                <InputField
                  placeholder="Ex: 1234567890"
                  Id="Family-Member-PhoneNumber"
                  maxLength={10}
                  value={loandetails.altcontact1}
                  onChangeText={(e) => {
                    handlechange(e, "altcontact1");
                  }}
                  editable={!componentedit}
                  inputType={"tel"}
                  leftIcon={<Phone size={20} style={{ animation: "none" }} />}
                  validationError={validationErrors.altcontact1}

                />
              </div>
            </div>
            </>
          }
          </div>
          <div className="row mt-4 add-details-wrapper">
          <div className="col-md-6">
              <label>Others contact Relation</label>
              <div className="mt-2">
              <Dropdown
                    Id="Other-Member-Input"
                    options={alt2relationarr}
                    leftIcon={
                      <i className="fa fa-users" style={{ color: "grey" }} />
                    }
                    onSelect={(e) => setloandetails({...loandetails,altrelation2:e})}
                    drpdownWidth={"100%"}
                    drpdownHeight={"50px"}
                    placeHolder={"Select The Relation"}
                    value={loandetails.altrelation2}
                    editable={!componentedit}
                   
                    // editable={loandetails.altrelation2}
                    // validationError={validationErrors.userBloodGroup}
                  />
              </div>
            </div>
           { loandetails.altrelation2.length>1 && 
           <>
            <div className="col-md-6">
              <label>{loandetails.altrelation2}'s name</label>
              <div className="mt-2">
                <InputField
                  placeholder="Ex: Sheldon"
                  Id="Other-Member-Name"
                  maxLength={30}
                  value={loandetails.altname2}
                  onChangeText={(e) => {
                    handlechange(e, "altname2");
                  }}
                  editable={!componentedit}
                  leftIcon={<User style={{ animation: "none" }} />}
                  validationError={validationErrors.altname2}

                />
              </div>
            </div>

            <div className="col-md-6">
              <label>{loandetails.altrelation2}'s Phone Number</label>
              <div className="mt-2">
                <InputField
                  placeholder="Ex: 0987654321"
                  Id="Other-Member-PhoneNumber"
                  maxLength={10}
                  value={loandetails.altcontact2}
                  onChangeText={(e) => {
                    handlechange(e, "altcontact2");
                  }}
                  editable={!componentedit}
                  inputType={"tel"}
                  leftIcon={<Phone size={20} style={{ animation: "none" }} />}
                  validationError={validationErrors.altcontact2}

                />
              </div>
            </div>
            </>}
          </div>

          {/* <div className="row">
            <div className="col-md-6">
              <>
                <label>Upload Sign Image</label>

                <FileUpload
                  fileType="application/pdf,image/jpeg"
                  limitCount={1}
                  fileName={`${accountId}-userSign`}
                  fileId={"userSign"}
                />
              </>
            </div>

            <div className="col-md-6">
              <>
                <label>Upload Cheque Image</label>

              <FileUpload
                fileType="application/pdf,image/jpeg"
                limitCount={1}
                fileName={`${accountId}-userCheque`}
                fileId={"userCheque"}
                files={userChequeFile} 
                setFiles={setuserChequeFile} 
              />
            </>
          </div>
        </div> */}
        </div>
      </form>
      
    </div>
  );
}

export default Additionalinformation;
